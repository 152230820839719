(function () {
    var exportCitationAll = {
        downloadBtn: document.querySelector('.downloadBtn'),

        init: function () {
            exportCitationAll.control();
        },

        control: function () {
            if (localStorage.getItem('exportCitationDownload')) {
                exportCitationAll.downloadBtn.classList.add('disabled');
            }

            exportCitationAll.downloadBtn.addEventListener('click', function () {
                // Elements selectors
                const selectList = document.getElementById('citation-format');
                const iconClose = document.querySelector('#exportCitation .icon-close_thin');
                const modalBackdrop = document.querySelector('.modal-backdrop');

                // Selected option values
                const selectedFormat = selectList.value;
                const FormatValue = selectList.options[selectList.selectedIndex].getAttribute('data-format');

                // Get the query from JSON
                // Remove "/action/searchCitationExport?" from it
                // and encode it
                const citationExportQuery = encodeURI(
                    exportCitationAll.downloadBtn.getAttribute('data-href').split('?')[1]
                );
                const finalLink =
                    '/action/exportCiteProcCitation?' +
                    'format=' +
                    FormatValue +
                    '&targetFile=custom-' +
                    selectedFormat +
                    '&' +
                    citationExportQuery;

                // Disable the download button after clicking on it to make sure only one download in process.
                exportCitationAll.downloadBtn.classList.add('disabled');

                fetch(finalLink)
                    .then(response => response.json())
                    .then(data => {
                        localStorage.setItem('exportCitationDownload', data.id);
                        UX.exportCitationPopup.popupToggle(data.id);
                    });

                iconClose.click();
                modalBackdrop.classList.remove('modal-backdrop');
            });
        },
    };

    UX.exportCitationAll = exportCitationAll;
})();
