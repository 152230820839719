(function () {
    var $body = $('body');
    var editable = {
        $editableWrapper: $('.editable__wrapper'),
        $editable: $('.editable__text'),
        $toggle: $('.editable__toggle'),
        requestNeeded: false,
        isForm: false,
        link: null,
        maxChar: null,

        init: function () {
            editable.control();
        },

        control: function () {
            editable.$toggle.on('click', function () {
                var $form;
                editable.resetData();
                editable.$toggle = $(this);
                editable.$editableWrapper = editable.$toggle.closest('.editable__wrapper');
                editable.$editable = editable.$editableWrapper.find('.editable__text');
                editable.isForm = editable.$toggle.closest('.editable__form').length;

                $form = editable.buildForm();

                editable.$toggle.hide();
                editable.$editable.hide().after($form);
                editable.$editableWrapper.addClass('js--edit');
            });

            $body.on('click', '.editable__save, .editable__reset', function (e) {
                var $this = $(this);
                e.preventDefault();
                editable.$editableWrapper = $this.closest('.editable__wrapper');
                editable.requestNeeded = editable.$editableWrapper.is('.editable__request');
                editable.$editable = editable.$editableWrapper.find('.editable__text');
                editable.$toggle = editable.$editableWrapper.find('.editable__toggle');
                editable.resetData($this, editable.$editableWrapper.find('.editable__input').val());
            });

            $body.on('click', function (e) {
                if (typeof e !== 'undefined') {
                    if (
                        !$(e.target).hasClass('editable__wrapper') &&
                        !$(e.target).closest('.editable__wrapper').length
                    ) {
                        editable.resetData();
                    }
                }
            });
        },

        buildForm: function () {
            var $form = $(
                    '<form action="/" method="post"><div class="input-group input-group--gray-bg"><div class="input-group__field-container--right-icon"></div></div></form>'
                ),
                $input,
                $controlsWrapper = $('<span class="input-group-addon input-group-addon--right" ></span>'),
                $controls,
                $wrapper;

            if (editable.isForm) {
                $input = $(
                    '<textarea type="text" class="form-control" rows="5" cols="100" placeholder="Write a description"/>'
                )
                    .addClass('editable__input')
                    .val(editable.$editable.html());
            } else {
                $input = $('<input type="text" class="form-control"/>')
                    .addClass('editable__input')
                    .val(editable.$editable.html());
            }

            if (editable.isForm) {
                $controls = $(
                    '<div class="pull-right"><button type="reset" class="btn transparent big stretched editable__reset">Cancel</button><button type="submit" class="btn blue big stretched editable__save">Save</button></div>'
                );
            } else {
                $controls = $(
                    '<button type="submit" class="btn editable__save"><i class="icon-checkmark"></i></button><button type="reset" class="btn editable__reset"><i class="icon-close_thin"></i></button>'
                );
            }

            if (editable.isForm) {
                $form.find('.input-group__field-container--right-icon').append($input).append($controlsWrapper);
                $form.append($controls);
            } else {
                $controlsWrapper.append($controls);
                $form.find('.input-group__field-container--right-icon').append($input).append($controlsWrapper);
            }

            if (
                editable.$editable.is('.charCount__text') &&
                typeof editable.$editable.attr('data-maxChar') !== 'undefined'
            ) {
                editable.maxChar = editable.$editable.attr('data-maxChar');
                $input.addClass('charCount__text').attr({
                    maxlength: editable.maxChar,
                    'data-maxChar': editable.maxChar,
                });

                $form.find('.input-group-addon--right').prepend('<span class="charLimit"></span>');
                $wrapper = $input.closest('.input-group');
                UX.charCount.maxChar = editable.maxChar;
                UX.charCount.charLimitCount($input, $wrapper);
            }
            return $form;
        },
        resetData: function ($this, textVal) {
            var isDefault, resetText, binderType, $resetEditable;
            if (typeof $this !== 'undefined') {
                editable.$editableWrapper = $this.closest('.editable__wrapper');
                isDefault = editable.$editableWrapper.is('.editable--default');
                editable.$editable = editable.$editableWrapper.find('.editable__text');
                if ($this.is('.editable__save')) {
                    if (editable.requestNeeded) {
                        editable.link = editable.$editable.attr('data-editable-link'); // real link
                        // editable.link = '/specs/products/acm/widgets/binder-view/templates/demo/rename.json?bindeName='; // test bindername and desc link
                        // editable.link = '/specs/products/acm/widgets/binder-view/templates/demo/binder-item-desc.json?bindeName='; // test binder item desc link

                        binderType = editable.$editable.is('.binder__issues__name')
                            ? 'binderName'
                            : editable.$editable.is('.binder__issues__description')
                            ? 'binderDescription'
                            : null;

                        $.ajax({
                            url: editable.link + textVal,
                            type: 'GET',
                            success: function (data) {
                                if (data[binderType] !== '' && data[binderType] !== undefined) {
                                    textVal = data[binderType];
                                }
                                editable.addNewText(isDefault, $this, textVal);
                            },
                        });
                    } else {
                        editable.addNewText(isDefault, $this, textVal);
                    }
                } else if ($this.is('.editable__reset')) {
                    if (isDefault) {
                        // Hide the form and empty the input on cancel
                        $this.closest('.accordion__content').hide();
                        editable.$editableWrapper.find('.editable__input').val('');
                    }
                    resetText = $.Event('edit:reset');
                    $(document).trigger(resetText, [$this, isDefault]);
                }
            }

            $resetEditable = $('.editable__wrapper:not(.editable--default)');
            $resetEditable.removeClass('js--edit').find('form').remove();
            $resetEditable.find('.editable__toggle, .editable__text').show();
        },
        addNewText: function (isDefault, $this, textVal) {
            var addingText;
            editable.$editable.html(textVal);

            addingText = $.Event('edit:added');
            $(document).trigger(addingText, [$this, isDefault]);

            if (isDefault) {
                editable.$editableWrapper.removeClass('editable--default').find('form').remove();
                editable.$editableWrapper.find('.editable__toggle, .editable__text').css('display', 'inline');
            }
        },
    };
    UX.editable = editable; // add to global namespace
})();
