(function () {
    var $article = $('article'),
        $body = $('body'),
        pubAjaxContent = {
            target: null,
            citationInfoURl: null,
            proceedingInfoURl: null,
            dataLazyAjax: null,

            init: function () {
                pubAjaxContent.control();

                $(document).ready(function () {
                    // getting data for Citation information 'fundingSources and digitalEdition'
                    if ($article.is('[data-citationInfo]')) {
                        pubAjaxContent.citationInfoURl = $article.attr('data-citationInfo');
                        pubAjaxContent.getCitationInfo();
                    }

                    if ($article.is('[data-proceedingInfo]')) {
                        pubAjaxContent.proceedingInfoURl = $article.attr('data-proceedingInfo');
                        pubAjaxContent.getProceedingInfo();
                    }
                });
            },
            control: function () {
                $body.on('click', '.loadingLazyAjax', function () {
                    pubAjaxContent.target = '.pill-citations__content .citedBy';

                    if (UX.ajaxControl.check.visibility($(pubAjaxContent.target))) {
                        pubAjaxContent.attachScrollLoad($(pubAjaxContent.target));
                    } else {
                        UX.ajaxControl.check.visibility($(pubAjaxContent.target));
                    }
                });
            },
            getAjaxContent: function ($toggle, $target, ajaxURl) {
                $.ajax({
                    url: ajaxURl,
                    // url: '/specs/products/acm/widgets/publication-content/html/response.html', //for testing
                    type: 'GET',
                    success: function (data) {
                        if ($toggle.hasClass('loadLazyAjax')) {
                            if (!$toggle.hasClass('ajax-done')) {
                                pubAjaxContent.lazyAjax($toggle, $target, data);
                            }
                            $toggle.addClass('ajax-done');
                        }
                    },
                });
            },

            getAjaxContentSuccess: function ($toggle, response) {
                var $clonedMobilePill = $('.transplanted-clone');
                var $deskopPill = $('.pill-icons');
                var $mediaPillLi = $deskopPill.find('li[title="Media"]');
                var $tablePillLi = $deskopPill.find('li[title="Tables"]');
                if ($toggle.hasClass('w-slide__btn')) {
                    if (
                        response === '' ||
                        !$(response).text().trim().length ||
                        $(response).hasClass('NoContentMessage')
                    ) {
                        if ($toggle.hasClass('pill-media')) {
                            if (
                                UX.ajaxControl.check.empty($('#pill-media__content .pill-media__content')) &&
                                $('.flowPlayer').length === 0 &&
                                $('.cloudflare-stream-container').length === 0
                            ) {
                                $toggle
                                    .addClass('disable')
                                    .attr('title', 'Media disable')
                                    .closest('li')
                                    .addClass('disable');
                            } else {
                                setTimeout(() => {
                                    // TODO: clean this temporary solution for MR blocker
                                    $('#pill-fig__contentcon').closest('li').hide();
                                    $('#pill-media__contentcon').click();
                                }, 1000);
                            }
                        } else {
                            $toggle
                                .addClass('disable')
                                .attr('title', 'Tables disable')
                                .closest('li')
                                .addClass('disable');
                        }
                    } else {
                        if ($toggle.hasClass('pill-media')) {
                            if (
                                UX.ajaxControl.check.empty($('#pill-media__content  .pill-media__content')) &&
                                $('.flowPlayer').length === 0 &&
                                $('.cloudflare-stream-container').length === 0
                            ) {
                                $('#pill-media__contentcon').closest('li').hide();
                            }
                        }
                    }

                    if ($('.article__abstractView').length !== 0) {
                        //if Abstract view (with Access)
                        $('.pill-figures__content li').each(function () {
                            $(this).find('.extra-links:not(.go-to-video) > a').addClass('disabled');
                        });

                        $('.pill-tables__content li').each(function () {
                            $(this).find('.extra-links > a').addClass('disabled');
                        });
                    }

                    // copy the disabled pill items to the mobile cloned pill
                    if ($mediaPillLi.hasClass('disable')) {
                        $clonedMobilePill.find('li[title="Media"]').addClass('disable');
                        $clonedMobilePill.find('li[title="Media"] > a').addClass('disable');
                    }
                    if ($tablePillLi.hasClass('disable')) {
                        $clonedMobilePill.find('li[title="Tables"]').addClass('disable');
                        $clonedMobilePill.find('li[title="Tables"] > a').addClass('disable');
                    }
                }
            },
            getAjaxContentFailed: function ($toggle, response) {
                if ($toggle.hasClass('w-slide__btn')) {
                    if ($toggle.hasClass('pill-media')) {
                        if (
                            UX.ajaxControl.check.empty($('#pill-media__content  .pill-media__content')) &&
                            $('.flowPlayer').length === 0 &&
                            $('.cloudflare-stream-container').length === 0
                        ) {
                            $toggle
                                .addClass('disable')
                                .attr('title', 'Media disable')
                                .closest('li')
                                .addClass('disable');
                        } else {
                            $('#pill-fig__contentcon').closest('li').hide();
                            $('#pill-media__contentcon').click();
                        }
                    } else {
                        $toggle.addClass('disable').attr('title', 'Tables disable').closest('li').addClass('disable');
                    }
                }
            },

            lazyAjax: function ($toggle, $target, data) {
                pubAjaxContent.dataLazyAjax = data.split('</li>');
                let noOfItemsToLoad =
                        pubAjaxContent.dataLazyAjax.length > 100 ? 100 : pubAjaxContent.dataLazyAjax.length,
                    i;
                // get data using ajax request and lazy load it
                for (i = 0; i < noOfItemsToLoad; i++) {
                    $target.append(pubAjaxContent.dataLazyAjax.shift() + '</li>');
                    $target.find('.loader').remove();
                }

                $('[data-slide-target="#pill-metric"]').addClass('loadingLazyAjax');
                pubAjaxContent.attachScrollLoad($target);
            },
            attachScrollLoad: function ($target) {
                var noOfItemsToLoad, i;
                $('.pill-citations__content').on('scroll', function () {
                    if (
                        pubAjaxContent.dataLazyAjax.length &&
                        parseInt($(this).scrollTop() + $(this).innerHeight() + 1000) >=
                            parseInt($(this)[0].scrollHeight)
                    ) {
                        $target.append(
                            '<div class="loader"><img src="/specs/products/acm/releasedAssets/images/loader.gif" alt="loading"></div>'
                        );

                        noOfItemsToLoad =
                            pubAjaxContent.dataLazyAjax.length > 100 ? 100 : pubAjaxContent.dataLazyAjax.length;
                        for (i = 0; i < noOfItemsToLoad; i++) {
                            $target.append(pubAjaxContent.dataLazyAjax.shift() + '</li>');
                            $target.find('.loader').remove();
                        }
                    }
                });
            },
            getCitationInfo: function () {
                $.ajax({
                    url: pubAjaxContent.citationInfoURl,
                    type: 'GET',
                    success: function (data) {
                        if (data.citaionInfo !== '') pubAjaxContent.mapingCitaionInfo(data.citaionInfo);
                    },
                });
            },
            mapingCitaionInfo: function (citaionInfo) {
                var fundingData, $publicationHistorySection, publicationNotesContent;
                if (typeof citaionInfo.fundingSources !== 'undefined' && citaionInfo.fundingSources !== '') {
                    fundingData = citaionInfo.fundingSources;

                    if (typeof fundingData === 'string')
                        $('.funding-list').append('<li class="funding-list__item">' + fundingData + '</li>');
                    else
                        $(fundingData).each(function () {
                            $('.funding-list').append('<li class="funding-list__item">' + this + '</li>');
                        });

                    $('.funding-sources').show();
                }

                if (typeof citaionInfo.digitalEdition !== 'undefined' && citaionInfo.digitalEdition !== '') {
                    $('.de-format')
                        .show()
                        .find('.btn')
                        .attr('href', citaionInfo.digitalEdition.replace('refurl=http://', 'refurl=https://'));
                    $('.de-format').show().find('.btn').attr('referrerpolicy', 'no-referrer-when-downgrade');
                }
                if (typeof citaionInfo.publisherSiteLink !== 'undefined' && citaionInfo.publisherSiteLink !== '') {
                    $('.publisher-site')
                        .attr('style', 'display:inline-block !important')
                        .find('.btn')
                        .attr('href', citaionInfo.publisherSiteLink);
                }

                if (typeof citaionInfo.editorsNote !== 'undefined' && citaionInfo.editorsNote !== '') {
                    $publicationHistorySection = $(
                        '.pill-information__content h3:contains(Publication History)'
                    ).parent();
                    publicationNotesContent =
                        '<div class="section__separator editorsNote">\n' +
                        '    <h3 class="left-bordered-title">Publication Notes</h3>\n' +
                        '    <div class="section__content"><span>' +
                        citaionInfo.editorsNote +
                        '</span></div>\n' +
                        '</div>\n';

                    if (typeof $publicationHistorySection !== 'undefined') {
                        //append it after Publication History section
                        $(publicationNotesContent).insertAfter($publicationHistorySection);
                    } else {
                        $('.pill-information__content').append(publicationNotesContent);
                    }
                }
            },
            getProceedingInfo: function () {
                $.ajax({
                    url: pubAjaxContent.proceedingInfoURl,
                    type: 'GET',
                    success: function (data) {
                        if (data.proceedingInfo !== '') {
                            pubAjaxContent.mapingProceedingInfo(data.proceedingInfo, data.serialCode);
                        }
                    },
                });
            },
            mapingProceedingInfo: function (proceedingInfo, serialCode) {
                var confSpon = '',
                    sponsorsData,
                    title,
                    inCoopData,
                    conf,
                    location,
                    sponsorsText,
                    url,
                    inCoopItem;

                if (typeof proceedingInfo.sponsors !== 'undefined' && proceedingInfo.sponsors !== '') {
                    sponsorsData = proceedingInfo.sponsors;

                    $(sponsorsData).each(function () {
                        var title = '',
                            url = '',
                            sponsorItem = '';

                        if (this.abbrev && this.abbrev !== '') {
                            title = this.abbrev;
                            if (this.name) {
                                title += ': ' + this.name;
                            }

                            confSpon +=
                                '<li><a href="' +
                                this.sigUrl +
                                '" title="' +
                                this.abbrev +
                                '">' +
                                this.abbrev +
                                '</a></li>';
                        } else if (this.name && this.name !== '') {
                            title = this.name;
                        }

                        if (this.sigUrl && this.sigUrl !== '') {
                            url = this.sigUrl;
                        }

                        if (url !== '') {
                            sponsorItem =
                                '<li class="proceedingInfo-sponsors__list__item">' +
                                '<a href="' +
                                url +
                                '" title="' +
                                this.abbrev +
                                '">' +
                                title +
                                '</a>' +
                                '</li>';
                        } else {
                            sponsorItem =
                                '<li class="proceedingInfo-sponsors__list__item">' +
                                '<span>' +
                                title +
                                '</span>' +
                                '</li>';
                        }

                        $('.proceedingInfo-sponsors__list').append(sponsorItem);
                    });

                    $('.proceedingInfo-sponsors').show();
                }

                if (typeof proceedingInfo.inCoop !== 'undefined' && proceedingInfo.inCoop !== '') {
                    inCoopData = proceedingInfo.inCoop;

                    $(inCoopData).each(function () {
                        title = '';
                        url = '';
                        inCoopItem = '';

                        if (this.abbrev) {
                            title = this.abbrev;
                            if (this.name) {
                                title += ': ' + this.name;
                            }
                        } else if (this.name) {
                            title = this.name;
                        }

                        if (this.sigUrl && this.sigUrl !== '') {
                            url = this.sigUrl;
                        }

                        if (url !== '') {
                            inCoopItem =
                                '<li class="proceedingInfo-inCoop__list__item">' +
                                '<a href="' +
                                url +
                                '" title="' +
                                this.abbrev +
                                '">' +
                                title +
                                '</a>' +
                                '</li>';
                        } else {
                            inCoopItem =
                                '<li class="proceedingInfo-inCoop__list__item">' +
                                '<span>' +
                                title +
                                '</span>' +
                                '</li>';
                        }

                        $('.proceedingInfo-inCoop__list').append(inCoopItem);
                    });

                    $('.proceedingInfo-inCoop').show();
                }

                if (typeof proceedingInfo.conference !== 'undefined' && proceedingInfo.conference !== '') {
                    (conf = proceedingInfo.conference), (location = ''), (sponsorsText = '');

                    if (conf.state && conf.state !== '') {
                        location += conf.state;
                        if ((conf.city && conf.city !== '') || (conf.country && conf.country !== '')) {
                            location += ', ';
                        }
                    }
                    if (conf.city && conf.city !== '') {
                        location += conf.city;
                        if (conf.country && conf.country !== '') {
                            location += ', ';
                        }
                    }

                    if (conf.country && conf.country !== '') {
                        location += conf.country;
                    }

                    if (typeof conf.acronym !== 'undefined' && conf.acronym !== '') {
                        if (typeof confSpon !== 'undefined' && confSpon !== '') {
                            sponsorsText = 'Sponsor:';
                        }

                        $('.proceedingInfo-conference').append(
                            '<div class="section__content">\n' +
                                '    <div class="event event--inline-info row--event">\n' +
                                '        <div class="event__header clearfix">\n' +
                                '            <div class="left--block gutterless"><span class="event__code"><span\n' +
                                '                    class="event__code--text">' +
                                conf.acronym +
                                '</span></span>\n' +
                                '                <div href="#" class="event__sopnsers">\n' +
                                '                    <ul data-lines="3" class="rlist truncate-list" >\n' +
                                '                        <li class="label"><b>' +
                                sponsorsText +
                                '</b></li>\n' +
                                confSpon +
                                '                    </ul>\n' +
                                '                </div>\n' +
                                '            </div>\n' +
                                '            <div class="right--block"><a href=' +
                                conf.link +
                                ' class="event__title">' +
                                conf.confName +
                                '</a>\n' +
                                '                <div class="event__content">\n' +
                                '                    <div class="info calender"><a href="#" target="_blank" class="disabled"><i class="icon-calender3"></i></a><span>' +
                                conf.confDate +
                                '</span>\n' +
                                '                    </div>\n' +
                                '                    <div class="info map"><i class="icon-navigator"></i><span>' +
                                location +
                                '</span></div>\n' +
                                '                </div>\n' +
                                '            </div>\n' +
                                '        </div>\n' +
                                '    </div>\n' +
                                '</div>'
                        );

                        if (serialCode !== 'guideproceedings') $('.proceedingInfo-conference').show();
                    }
                }
            },
        };

    UX.pubAjaxContent = pubAjaxContent; // add to global namespace
})();
