(function () {
    var profileSubmission = {
        $mainWrapper: $('.submission-report--wrapper'),
        checkLink: null,

        init: function () {
            profileSubmission.control();
        },

        control: function () {
            //Check Pending Items every 5 seconds
            var checkPendingItemsInterval = setInterval(function () {
                if (profileSubmission.$mainWrapper.find('#checkLink').length) {
                    profileSubmission.checkLink = profileSubmission.$mainWrapper.find('#checkLink').val();
                    profileSubmission.checkPendingItems();
                } else {
                    clearInterval(checkPendingItemsInterval);
                }
            }, 5000);
        },

        checkPendingItems: function () {
            $.ajax({
                url: profileSubmission.checkLink,
                type: 'GET',
                success: function (data) {
                    var JsonData = data;
                    if (UX.ajaxControl.mustParseJson(JsonData)) {
                        JsonData = JSON.parse(JsonData);
                    }
                    if (typeof JsonData.reports !== 'undefined' && Object.keys(JsonData.reports).length) {
                        $.each(JsonData.reports, function (key, val) {
                            var itemId = '#pendingItem' + val.id;
                            var status = val.status;
                            var succeedContainer;
                            var failedContainer;
                            if ($(itemId).length) {
                                if (status === 'PROCESSED') {
                                    //in case of Succeed item
                                    $(itemId).find('b').html('Success!');
                                    $(itemId).removeClass('alert-msg--info').addClass('alert-msg--success');

                                    if (profileSubmission.$mainWrapper.find('.succeed').length === 0) {
                                        succeedContainer = profileSubmission.appendContainers('succeed');
                                    }
                                    profileSubmission.$mainWrapper.find('.sb-content').prepend(succeedContainer);
                                    $(itemId).prependTo(profileSubmission.$mainWrapper.find('.succeed ul'));
                                } else if (status === 'FAILURE') {
                                    //in case of Failed item
                                    $(itemId).find('b').html('Fail!');
                                    $(itemId).removeClass('alert-msg--info').addClass('alert-msg--error');

                                    if (profileSubmission.$mainWrapper.find('.failed').length === 0) {
                                        failedContainer = profileSubmission.appendContainers('failed');
                                    }
                                    profileSubmission.$mainWrapper.find('.sb-content').prepend(failedContainer);
                                    $(itemId).prependTo(profileSubmission.$mainWrapper.find('.failed ul'));
                                }
                                $(itemId).removeAttr('id');
                            }
                        });
                        profileSubmission.updateLengths();
                    }

                    //update checkLink input hidden field
                    if (typeof JsonData.checkLink !== 'undefined' && JsonData.checkLink.length) {
                        profileSubmission.$mainWrapper.find('#checkLink').val(JsonData.checkLink);
                    } else {
                        profileSubmission.$mainWrapper.find('#checkLink').remove();
                    }
                },
            });
        },

        appendContainers: function (status) {
            var className = 'succeed',
                iconClass = 'icon-checkmark',
                title = 'Succeed:';

            if (status === 'failed') {
                className = 'failed';
                iconClass = 'icon-notice';
                title = 'Failed:';
            }

            return (
                '<div class="' +
                className +
                ' section">' +
                '<h5 class="section-title">' +
                '<i aria-hidden="true" class="' +
                iconClass +
                '"></i>' +
                '<span class="separator-left">' +
                title +
                '<span class="length">' +
                '</span>' +
                '</span>' +
                '</h5>' +
                '<ul class="rlist">' +
                '</ul>' +
                '</div>'
            );
        },

        updateLengths: function () {
            var failedLength, succeedLength, pendingLength;
            if (profileSubmission.$mainWrapper.find('.failed').length) {
                failedLength = profileSubmission.$mainWrapper.find('.failed ul li').length;
                profileSubmission.$mainWrapper.find('.failed span.length').html(failedLength);
            }
            if (profileSubmission.$mainWrapper.find('.succeed').length) {
                succeedLength = profileSubmission.$mainWrapper.find('.succeed ul li').length;
                profileSubmission.$mainWrapper.find('.succeed span.length').html(succeedLength);
            }
            if (profileSubmission.$mainWrapper.find('.pending').length) {
                pendingLength = profileSubmission.$mainWrapper.find('.pending ul li').length;
                profileSubmission.$mainWrapper.find('.pending span.length').html(pendingLength);
            }
        },
    };

    UX.profileSubmission = profileSubmission; // add to global namespace
})();
