(function () {
    var $body = $('body');
    var meregProfile = {
        $wrapper: $('.merge-request-form, .split-request-form'),
        $mergeForm: $('.merge-request-form form'),
        $errorMsg: $('.error-submit-msg'),
        $toggle: $('.reproduce'),
        $target: null,
        doValidate: false,
        $removeMergeRequestModal: $('#removeMergeRequest'),

        init: function () {
            meregProfile.control();
        },

        control: function () {
            meregProfile.submitValidation();

            meregProfile.preventInputString();

            meregProfile.$toggle.on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                meregProfile.addItem();
            });

            meregProfile.$wrapper.on('focusout', 'input', function () {
                if (meregProfile.doValidate) {
                    meregProfile.validateId($(this));
                    meregProfile.doValidate = false;
                }
            });

            meregProfile.$wrapper.on('change', 'input', function () {
                if (meregProfile.$wrapper.is('.merge-request-form')) {
                    meregProfile.checkEmptyFields();
                }
                meregProfile.removeValidationMessage($(this));
            });

            $body.on('click', '.remove-confirmation', function (e) {
                var $this = $(this);
                e.preventDefault();

                UX.fieldsCtrl.defineVar($this);

                if (UX.fieldsCtrl.isSet && $this.closest('.reproducible:not(.repeated)').length) {
                    meregProfile.$removeMergeRequestModal.modal('show');
                    meregProfile.$removeMergeRequestModal.find('.removeConfirmed').data('toggle', $this);
                } else {
                    if (UX.fieldsCtrl.isSet && $this.closest('.reproducible:not(.repeated)').length) {
                        UX.fieldsCtrl.$terms = UX.fieldsCtrl.$wrapper.find('.reproducible-set');
                    }

                    if (UX.fieldsCtrl.$terms.length > 1) {
                        UX.fieldsCtrl.on.remove($this);
                    }
                }
            });

            $body.on('click', '.removeConfirmed', function (e) {
                var $this = $(this);
                var $toggle = $this.data('toggle');
                e.preventDefault();

                UX.fieldsCtrl.on.remove($toggle);

                meregProfile.$removeMergeRequestModal.modal('hide');
            });

            $body.on('click', '.merge-request-form__loi a', function (e) {
                var $this = $(this);
                e.preventDefault();
                window.open($this.attr('href'), '_blank');
            });
        },
        addItem: function () {
            var idNumber = meregProfile.$wrapper.find('.reproducible:not(.repeated)').length;

            meregProfile.$target =
                '<div class="input-group reproducible-set"><div class="reproducible">\n' +
                '    <div class="input-group__field-container">\n' +
                '        <div class="wrapper">' +
                '            <label class="is-accessible" for="originalId[' +
                idNumber +
                ']">Original Profile ID</label>\n' +
                '            <input class="form-control" type="number" name="originalId[' +
                idNumber +
                ']" id="originalId[' +
                idNumber +
                ']" />\n' +
                '        </div>\n' +
                '    </div>\n' +
                '    <div class="input-group__field-container exclude">\n' +
                '        <div class="wrapper">' +
                '            <label class="is-accessible" for="destinationId[' +
                idNumber +
                ']">Destination Profile ID</label>\n' +
                '            <input class="form-control" type="number" name="destinationId[' +
                idNumber +
                ']" id="destinationId[' +
                idNumber +
                ']" />\n' +
                '        </div>\n' +
                '    </div>\n' +
                '    <div class="input-group__actions"><a class="add-ctrl-field" href="#" title="Add search field"><i class="icon-circle-plus" aria-hidden="true"></i></a><a class="remove-confirmation" href="#" title="Remove search field"><i class="icon-delete" aria-hidden="true"></i></a></div>\n' +
                '</div></div>';

            meregProfile.$wrapper.find('.reproducible__wrapper').append(meregProfile.$target);
        },
        validateId: function ($elem) {
            var idVal, authorDoi, $container, action;
            if ($elem.val() !== '') {
                idVal = $elem.val();
                authorDoi = '';
                $container = $elem.closest('.wrapper');
                if ($('.split-request').length) {
                    action = 'split';
                } else if ($('.merge-request-form').length) {
                    action = 'merge';
                }
                $.ajax({
                    url: '/pb/widgets/profileMaintenance/validateId?profileId=' + idVal + '&action=' + action,
                    //url: '/specs/products/acm/widgets/profile-merge/templates/data/validation.json',
                    type: 'GET',
                    success: function (data) {
                        var response = data;
                        if (UX.ajaxControl.mustParseJson(response)) {
                            response = JSON.parse(response);
                        }

                        if (response.exist) {
                            const hasError = document.querySelector(
                                '.split-request-form .input-group__field-container--error'
                            );
                            if (typeof doisSplitAjax !== 'undefined') {/*eslint-disable-line*/
                                if (!doisSplitAjax.length && !hasError) {/*eslint-disable-line*/
                                    document
                                        .querySelector('.split-request-action button')
                                        ?.classList.remove('disabled');
                                }
                            } else if (!hasError) {
                                document.querySelector('.split-request-action button')?.classList.remove('disabled');
                            }
                            $container.addClass('input-group__field-container--success');
                            $container.append(
                                '<span class="input-group__right-icon field-success-icon"><i aria-hidden="true" class="icon-checkmark"></i></span>'
                            );

                            if (typeof response.content !== 'undefined') {
                                authorDoi = '<div class="merge-request-form__loi">' + response.content + '</div>';
                            }

                            $container.after(
                                '<div class="label success field-success-label">\n' +
                                    ' <label>' +
                                    response.message +
                                    '</label>\n' +
                                    authorDoi +
                                    ' </div>'
                            );
                        } else {
                            $container.addClass('input-group__field-container--error');
                            document.querySelector('.split-request-action button')?.classList.add('disabled');
                            $container.append(
                                '<span class="input-group__right-icon field-error-icon"><i aria-hidden="true" class="icon-notice"></i></span>'
                            );
                            $container.after(
                                '<div class="label error field-error-label">\n' +
                                    ' <label>' +
                                    response.message +
                                    '</label>\n' +
                                    ' </div>'
                            );
                        }
                    },
                });
            } else {
                meregProfile.emptyInputValidation();
            }
        },

        removeValidationMessage: function ($elem) {
            var $container = $elem.closest('.input-group__field-container');
            var $wrapper = $elem.closest('.wrapper');
            $wrapper.removeClass('input-group__field-container--error input-group__field-container--success');
            $container
                .find('.field-success-label, .field-success-icon, .field-error-label, .field-error-icon')
                .remove();
            meregProfile.doValidate = true;
        },

        submitValidation: function () {
            meregProfile.$mergeForm.on('submit', function () {
                var isCyclicRedirections = meregProfile.checkCyclicRedirections(),
                    $emptyInpts;

                //if there is validation errors don't submit
                if (meregProfile.$mergeForm.find('.input-group__field-container--error').length) {
                    meregProfile.$errorMsg.html('Please fix the errors before submitting').show();
                    return false;
                }
                //if there is cyclic redirections don't submit
                else if (isCyclicRedirections) {
                    return false;
                } else {
                    meregProfile.$errorMsg.hide();
                    $emptyInpts = $(this)
                        .find(':input')
                        .filter(function () {
                            return !this.value;
                        });
                    $emptyInpts.attr('disabled', 'disabled');

                    return true;
                }
            });
        },

        preventInputString: function () {
            //Allow only numbers on type=number inputs
            meregProfile.$wrapper.on('keypress keyup blur', 'input[type="number"]', function (e) {
                if ((e.which < 48 || e.which > 57) && e.which !== 8 && e.which !== 46) {
                    e.preventDefault();
                }
            });
        },

        checkEmptyFields: function () {
            var allInptsOrig = meregProfile.$mergeForm.find(':input[name^="originalId"]').length;
            var $emptyInptsOrig = meregProfile.$mergeForm.find(':input[name^="originalId"]').filter(function () {
                return !this.value;
            });

            var allInptsDest = meregProfile.$mergeForm.find(':input[name^="destinationId"]').length;
            var $emptyInptsDest = meregProfile.$mergeForm.find(':input[name^="destinationId"]').filter(function () {
                return !this.value;
            });

            var filledInptsOrig = allInptsOrig - $emptyInptsOrig.length;
            var filledInptsDest = allInptsDest - $emptyInptsDest.length;

            if (filledInptsOrig && filledInptsDest) {
                meregProfile.$mergeForm.find('.submit-merge').removeClass('disabled');
            } else {
                meregProfile.$mergeForm.find('.submit-merge').addClass('disabled');
            }
        },

        checkCyclicRedirections: function () {
            var isCyclicRedirections = false,
                allOrigInputs = '',
                destInput = '';

            meregProfile.$mergeForm.find('.reproducible-set').each(function () {
                var $this = $(this),
                    allOrigInputsArray = [];
                allOrigInputs = '';
                destInput = '';

                $this.find(':input[name^="originalId"]').each(function () {
                    if ($(this).val() !== '') {
                        allOrigInputsArray.push($(this).val());
                    }
                });

                if ($this.find(':input[name^="destinationId"]').val() !== '') {
                    destInput = $this.find(':input[name^="destinationId"]').val();
                }

                allOrigInputs = allOrigInputsArray.toString();

                if (allOrigInputs !== '' && destInput !== '') {
                    $.ajax({
                        url: '/pb/widgets/profileMaintenance/checkRedirect',
                        //url: '/specs/products/acm/widgets/profile-merge/templates/data/cyclic-redirections-validation.json',
                        data: {
                            source: allOrigInputs,
                            dest: destInput,
                        },
                        async: false,
                        success: function (data) {
                            var response;
                            if (data) {
                                response = data;
                                if (UX.ajaxControl.mustParseJson(response)) {
                                    response = JSON.parse(response);
                                }

                                if (response.cyclicRedirection) {
                                    isCyclicRedirections = true;
                                    $this
                                        .find('.input-group__field-container')
                                        .addClass('cyclic-redirections-detected');
                                    meregProfile.$errorMsg.html(response.message).show();
                                } else {
                                    $this
                                        .find('.input-group__field-container')
                                        .removeClass('cyclic-redirections-detected');
                                }
                            }
                        },
                    });
                }
            });

            return isCyclicRedirections;
        },
        emptyInputValidation: function () {
            const isValid = document.querySelector('.input-group__field-container--success');
            const hasError = document.querySelector('.input-group__field-container--error');
            if (!isValid || hasError) {
                document.querySelector('.split-request-action button')?.classList.add('disabled');
            } else {
                document.querySelector('.split-request-action button')?.classList.remove('disabled');
            }
        },
    };

    UX.meregProfile = meregProfile; // add to global namespace
})();
