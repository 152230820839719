(function () {
    var disqus = {
        commentCount: 0,
        countSet: false,
        init: function () {
            disqus.control();
        },
        control: function () {
            if ($('.disqus-count').text().length) {
                disqus.setCommentCount();
            } else {
                $('.disqus-count').each(function () {
                    var config;
                    this.observer = new MutationObserver(disqus.setCommentCount);
                    config = {attributes: false, childList: true, characterData: true, subtree: false};
                    this.observer.observe(this, config);
                });
            }
        },
        setCommentCount: function () {
            if (!disqus.countSet) {
                disqus.commentCount = $('.disqus-count').text().split(' ')[0];
                disqus.countSet = true;
            }
            $('.comment-count').html(disqus.commentCount);
        },
    };
    UX.disqus = disqus;
})();
