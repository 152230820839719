(function () {
    var showMore = {
        $container: null,
        $target: null,
        $targetItems: null,
        $toggle: null,
        numberOfLi: null,
        numberOfLiToShow: 3,
        vPort: 'screen-sm', // default responsive break point
        isMobile: false, // variable use to determine if responsive mode is on or off
        moreLabel: 'Show More Items',
        lessLabel: 'Show Fewer Items',

        init: function () {
            $('.show-more-items:not(.js--showMore)').each(function () {
                showMore.$container = $(this);

                if (typeof showMore.$container.attr('data-type-table') !== 'undefined') {
                    showMore.$target = showMore.$container.find('.table-showMore').first();
                    showMore.$targetItems = showMore.$target.find('tbody tr');
                } else if (showMore.$container.hasClass('conference__proceedings__container')) {
                    showMore.$target = showMore.$container;
                    showMore.$targetItems = showMore.$target.find('>li');
                } else {
                    showMore.$target = showMore.$container.find('ol, ul').first();
                    showMore.$targetItems = showMore.$target.find('>li');
                }

                showMore.numberOfLi = showMore.$targetItems.length;
                showMore.numberOfLiToShow = 3;
                if (typeof showMore.$container.attr('data-numberOfLiToShow') !== 'undefined')
                    showMore.numberOfLiToShow = showMore.$container.attr('data-numberOfLiToShow');

                showMore.$target.attr('data-maxHeight', Math.ceil($(this).height()));

                if (showMore.numberOfLi > showMore.numberOfLiToShow) showMore.on.build();
            });

            showMore.control();
        },
        control: function () {},
        toggle: function ($elem) {
            var $items, showLessLabel, showMoreLabel;

            showMore.$container = $elem.closest('.show-more-items');
            if (typeof showMore.$container.attr('data-type-table') !== 'undefined') {
                showMore.$target = showMore.$container.find('.table-showMore').first();
                $items = showMore.$container.find('tbody tr.js--toggle');
            } else {
                showMore.$target = showMore.$container.find('ol, ul').first();
                $items = showMore.$container.find('li.js--toggle');
            }

            showLessLabel = showMore.get.lessLabel(showMore.$container);
            if (typeof showMore.$container.attr('data-showLessLabel') !== 'undefined') {
                showLessLabel = showMore.$container.attr('data-showLessLabel');
            }

            showMoreLabel = showMore.get.moreLabel(showMore.$container);
            if (typeof showMore.$container.attr('data-showMoreLabel') !== 'undefined') {
                showMoreLabel = showMore.$container.attr('data-showMoreLabel');
            }

            UX.sidebarSections.scrollingToTarget = true;
            if ($items.hasClass('visibility-hidden')) {
                $elem.closest('.sticko__side-content').css('height', 'auto');
                $elem.html(showLessLabel);

                if (showMore.$target.attr('data-maxHeight') === '0') showMore.$target.attr('data-maxHeight', 'auto');

                showMore.$target.animate(
                    {
                        height: showMore.$target.attr('data-maxHeight'),
                    },
                    500,
                    function () {
                        showMore.$target.height('auto');
                        setTimeout(function () {
                            UX.sidebarSections.scrollingToTarget = false;
                        }, 100);
                    }
                );
                $items.removeClass('visibility-hidden');

                if (typeof showMore.$container.attr('data-type-table') !== 'undefined') {
                    $elem.parent().removeClass('more');
                }
            } else {
                $elem.html(showMoreLabel);
                if (showMore.$target.attr('data-minHeight') === '0') {
                    $items.addClass('visibility-hidden');
                    if (typeof showMore.$container.attr('data-type-table') !== 'undefined') {
                        $elem.parent().addClass('more');
                    }
                } else
                    showMore.$target.animate(
                        {
                            height: showMore.$target.attr('data-minHeight'),
                        },
                        400,
                        function () {
                            $items.addClass('visibility-hidden');
                            if (typeof showMore.$container.attr('data-type-table') !== 'undefined') {
                                $elem.parent().addClass('more');
                            }
                            showMore.$target.height('auto');
                            setTimeout(function () {
                                UX.sidebarSections.scrollingToTarget = false;
                            }, 100);
                        }
                    );
                $([document.documentElement, document.body]).animate(
                    {
                        scrollTop: showMore.$container.offset().top - 90,
                    },
                    400
                );
            }
        },
        on: {
            build: function () {
                var showMoreBtn = false,
                    showMoreStr,
                    tableWidth;
                showMore.$targetItems.each(function (index) {
                    if (index >= showMore.numberOfLiToShow) {
                        let $that = $(this);
                        setTimeout(function () {
                            $that.addClass('js--toggle visibility-hidden');
                        }, 500);
                        showMoreBtn = true;
                    }
                });
                if (showMoreBtn) {
                    showMoreStr = `<div class="show-more-items__btn-holder separated-block--dashed"><button class="btn btn--inverse" role="button" aria-label="${showMore.get.moreLabel(
                        showMore.$container
                    )}">${showMore.get.moreLabel(showMore.$container)}</button></div>`;

                    if (typeof showMore.$container.attr('data-type-table') !== 'undefined') {
                        tableWidth = '100%';
                        if (showMore.$container.find('table.article-section__table').length) {
                            tableWidth = showMore.$target.find('table.article-section__table').outerWidth(true) + 'px';
                        }
                        showMoreStr =
                            '<div style="width:' +
                            tableWidth +
                            '" class="show-more-items__btn-holder table-type more"><button class="btn btn--inverse">' +
                            showMore.get.moreLabel(showMore.$container) +
                            '</button></div>';
                    }
                    if (typeof showMore.$container.attr('data-showMoreStr') !== 'undefined') {
                        showMoreStr = showMore.$container.attr('data-showMoreStr');
                    }
                    if (showMore.$container.hasClass('media-item')) {
                        showMore.$container.addClass('js--showMore').find('.table-showMore').after(showMoreStr);
                    } else {
                        showMore.$container.addClass('js--showMore').append(showMoreStr);
                    }
                }
                showMore.$target.attr('data-minHeight', showMore.$target.height());
            },
        },
        get: {
            moreLabel: function ($elem) {
                var moreLabel = showMore.moreLabel;
                if (typeof $elem.attr('data-sectionname') !== 'undefined') {
                    moreLabel = 'Show All ' + $elem.attr('data-sectionname');
                }
                if (typeof showMore.$container.attr('data-type-table') !== 'undefined') {
                    moreLabel = '<i aria-hidden="true" class="icon-arrow_d_n"></i> Show More';
                    showMore.updateShowMoreWidth();
                }
                return moreLabel;
            },
            lessLabel: function ($elem) {
                var lessLabel = showMore.lessLabel;
                if (typeof $elem.attr('data-sectionname') !== 'undefined') {
                    lessLabel = 'Show Fewer ' + $elem.attr('data-sectionname');
                }
                if (typeof showMore.$container.attr('data-type-table') !== 'undefined') {
                    lessLabel = '<i aria-hidden="true" class="icon-arrow_u_p"></i> Show Less';
                    showMore.updateShowMoreWidth();
                }
                return lessLabel;
            },
        },

        updateShowMoreWidth: function () {
            setTimeout(function () {
                var tableWidth;
                if (
                    showMore.$container.find('table.article-section__table').length &&
                    showMore.$container.find('.show-more-items__btn-holder').length
                ) {
                    tableWidth = showMore.$target.find('table.article-section__table').width();
                    showMore.$container.find('.show-more-items__btn-holder').css('width', tableWidth);
                }
            }, 1000);
        },
    };

    UX.showMore = showMore; // add to global namespace
})();

$('body').on('click', '.show-more-items__btn-holder .btn', function () {
    UX.showMore.toggle($(this));
});
