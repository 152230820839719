(function () {
    var profileReport = {
        init: function () {
            $('.generate-report__form').on('submit', function () {
                var $this = $(this),
                    fromDateVal = null,
                    toDateVal = null;

                /*eslint-disable*/
                fromDateVal = moment($this.find('.dr-date-start').html()).format('YYYY-MM-DD');
                toDateVal = moment($this.find('.dr-date-end').html()).format('YYYY-MM-DD');
                /*eslint-enable*/
                $this.find('[name="fromDate"]').val(fromDateVal);
                $this.find('[name="toDate"]').val(toDateVal);

                return true; // make sure that the form is still submitted
            });
        },
    };

    UX.profileReport = profileReport; // add to global namespace
})();
