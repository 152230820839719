(function () {
    var $body = $('body');
    var charCount = {
        $charCount: $('.charCount__text'),
        maxChar: null,

        init: function () {
            $('.charCount__text').each(function () {
                var $this = $(this);
                charCount.maxChar = $this.attr('data-maxChar');
                $this
                    .attr('maxlength', charCount.maxChar)
                    .closest('.input-group')
                    .find('.charLimit')
                    .html(charCount.maxChar);
            });
            charCount.control();
        },

        control: function () {
            $body.on('input', '.charCount__text', function () {
                var $this = $(this),
                    $wrapper = $this.closest('.input-group');
                charCount.maxChar = $this.attr('data-maxChar');

                charCount.charLimitCount($this, $wrapper);
            });
        },

        charLimitCount: function ($input, $wrapper) {
            var charRemainingLimit = charCount.maxChar - $input.val().length;

            if (charRemainingLimit <= 50) {
                $wrapper.find('.charLimit').removeClass('red').addClass('yellow');

                if (charRemainingLimit <= 10) {
                    $wrapper.find('.charLimit').removeClass('yellow').addClass('red');
                }
            } else {
                $wrapper.find('.charLimit').removeClass('yellow').removeClass('red');
            }

            $wrapper.find('.charLimit').html(charRemainingLimit);
        },
    };
    UX.charCount = charCount; // add to global namespace
})();
