(function () {
    var verticalScroller = {
        vScrollerContentWrapper: null,
        vScrollerContent: null,
        vScrollerArrow: null,
        vScrollerItem: null,

        init: function () {
            verticalScroller.vScrollerContentWrapper = $('.v-scroller .v-scroller__content-wrapper');
            verticalScroller.vScrollerContent = $('.v-scroller .v-scroller__content');
            verticalScroller.vScrollerArrow = $('.v-scroller .v-scroller__arrows');
            verticalScroller.vScrollerItem = $('.v-scroller .v-scroller__item');

            verticalScroller.initVerticalScroller();
            verticalScroller.control();
        },

        initVerticalScroller: function () {
            //init vertical scroller wrapper height
            var numberOfItems = parseInt(verticalScroller.vScrollerContentWrapper.data('items'));
            var WrapperHeight = 0;
            var index = 1;

            verticalScroller.vScrollerItem.each(function () {
                WrapperHeight += $(this).outerHeight(true);
                if (index === numberOfItems) {
                    return false;
                }
                index++;
            });

            if (WrapperHeight !== 0) {
                verticalScroller.vScrollerContentWrapper.css('max-height', WrapperHeight);
            }

            //disable the scroller if all of the items is visible
            if (
                verticalScroller.vScrollerContent.innerHeight() <=
                verticalScroller.vScrollerContentWrapper.innerHeight()
            ) {
                verticalScroller.vScrollerArrow.find('.bottom-arrow').addClass('disabled');
            }
        },

        control: function () {
            //Handle clicking on the arrows
            verticalScroller.vScrollerArrow.find('i').on('click', function () {
                var $this = $(this);
                var scrolledValue = verticalScroller.vScrollerContentWrapper.scrollTop();
                var scrollingFactor = verticalScroller.vScrollerContentWrapper.innerHeight();

                if ($this.hasClass('top-arrow')) {
                    scrolledValue -= scrollingFactor;
                } else if ($this.hasClass('bottom-arrow')) {
                    scrolledValue += scrollingFactor;
                }

                verticalScroller.vScrollerContentWrapper.animate({scrollTop: scrolledValue}, 'slow');
            });

            //Handle disabling the arrows when reach the top or reach the bottom
            verticalScroller.vScrollerContentWrapper.on('scroll', function () {
                var $this = $(this);
                var scrolledValue = $this.scrollTop();

                //reach the top check
                if (scrolledValue !== 0) {
                    verticalScroller.vScrollerArrow.find('.top-arrow').removeClass('disabled');
                } else if (scrolledValue === 0) {
                    verticalScroller.vScrollerArrow.find('.top-arrow').addClass('disabled');
                }

                //reach the bottom check
                if ($this.scrollTop() + Math.ceil($this.innerHeight()) >= $this[0].scrollHeight) {
                    verticalScroller.vScrollerArrow.find('.bottom-arrow').addClass('disabled');
                } else {
                    verticalScroller.vScrollerArrow.find('.bottom-arrow').removeClass('disabled');
                }
            });
        },
    };

    UX.verticalScroller = verticalScroller; // add to global namespace
})();
