(function () {
    var contribList = {
        $form: $('#findByNameForm'),
        $findBy: $('.find_by li a'),

        init: function () {
            contribList.control();
        },

        control: function () {
            contribList.updateSearchHiddenField();
            contribList.customeDropBlock();
        },

        updateSearchHiddenField: function () {
            contribList.$form.submit(function () {
                var searchVal;
                if (contribList.$form.find('#AllNames').length) {
                    searchVal = contribList.$form.find('input#find-input').val();
                    contribList.$form.find('#AllNames').val(searchVal);
                    contribList.$form.find('input#find-input').attr('disabled', 'disabled');
                }
                $('.findBy').attr('value', $('#find-input').val());
            });
        },

        customeDropBlock: function () {
            contribList.$findBy.click(function (e) {
                var $find_by_dropDown = $('.find-by'),
                    selected = $find_by_dropDown.text(),
                    $find_by_hidden = $('.findBy'),
                    prevSelectedPlaceHolder,
                    newlySelectedDropdownPlaceHolder,
                    prevSelectedSearchField,
                    newlySelectedDropdownSearchField;
                e.preventDefault();

                // Each element from the dropdown is associated with a specific placeholder that will bet set in the input field
                // This line is to switch between the element's text in the dropdown
                $find_by_dropDown.text(contribList.$findBy.text());

                //This part captures the previous placeholder and the new placeholder in order to switch between them
                prevSelectedPlaceHolder = $find_by_dropDown.attr('data-placeholder');
                newlySelectedDropdownPlaceHolder = contribList.$findBy.attr('data-placeholder');

                //This part captures the (data-searchfield) attributes that are used to update the hidden field
                prevSelectedSearchField = $find_by_dropDown.attr('data-searchfield');
                newlySelectedDropdownSearchField = contribList.$findBy.attr('data-searchfield');

                //This part actually sets the placeholder and the (data-searchfield) to the new elements that got selected
                $find_by_dropDown.attr('data-placeholder', newlySelectedDropdownPlaceHolder);
                $find_by_dropDown.attr('data-searchfield', newlySelectedDropdownSearchField);

                //This part actually sets the place holder and the (data-searchfield) to the elements that got unselected
                contribList.$findBy.attr('data-placeholder', prevSelectedPlaceHolder);
                contribList.$findBy.attr('data-searchfield', prevSelectedSearchField);

                // This part sets the place holder to the input field
                $('#find-input').attr('placeholder', $find_by_dropDown.attr('data-placeholder'));
                if ($find_by_hidden.length < 1) {
                    $('#find-input').attr('name', $find_by_dropDown.attr('data-searchfield'));
                }

                // This part updates the hidden fields with the new values based on the selected item
                $find_by_hidden.attr('name', $find_by_dropDown.attr('data-searchfield'));
                $find_by_hidden.attr('id', $find_by_dropDown.attr('data-searchfield'));

                contribList.$findBy.text(selected);
                $('body').trigger('click');

                if (newlySelectedDropdownSearchField === 'AllField') {
                    $('#target').attr('value', 'people-page-by-research');
                } else {
                    $('#target').attr('value', 'people-page');
                }
            });
        },
    };

    UX.contribList = contribList;
})();
