(function () {
    var reddit = {
        getAjaxContentSuccess: function ($toggle) {
            if ($toggle.hasClass('reddit-widget')) {
                UX.verticalScroller.init();
            }
        },
    };

    UX.reddit = reddit; // add to global namespace
})();
