import bindersList from '../templates/save-to-binder/binders-list-client.jade';

(function () {
    var saveBinder = {
        modal: '#saveToBinders-popup',
        notificationInterval: 0,
        init: function () {
            saveBinder.control();
        },
        control: function () {
            $(document).on('change', '.saveToBinders-list input[type="checkbox"]', function () {
                var $el = $(this),
                    $binderList = $el.closest('.saveToBinders-list'),
                    $alertElem = $binderList.find('.binder-list-alerts'),
                    isAdd = false,
                    link = '/action/removeBinderItem',
                    // link = '/specs/products/acm/components/save-to-binder/templates/demo/save-binders.json'; // test link
                    multiDois = false, // if modal then it could be multidois, 'data-single-doi' distinguish between multi or single dois
                    dois = '',
                    code,
                    isAjaxTocsAndSelectedAll = false,
                    isAjaxTocsAndNotSelectedAll = false,
                    view = 'list';

                $el.attr('disabled', 'disabled');
                $el.next('.label-txt').addClass('disabled');

                if ($el.prop('checked')) {
                    isAdd = true;
                    link = '/action/addBinderItem'; // real link
                }

                if ($el.closest(saveBinder.modal).length) {
                    multiDois = true;
                }

                if (
                    $('.section-checkbox .section--dois').length &&
                    $('.item-results__checkbox input[name="markall"]').prop('checked')
                ) {
                    isAjaxTocsAndSelectedAll = true;
                }

                if (
                    $('.section-checkbox .section--dois').length &&
                    $('.item-results__checkbox input[name="markall"]').prop('checked') === false
                ) {
                    isAjaxTocsAndNotSelectedAll = true;
                }

                if (multiDois) {
                    if (isAjaxTocsAndSelectedAll) {
                        $('.section-checkbox .section--dois').each(function () {
                            dois += $(this).val() + ',';
                        });
                    } else if (
                        isAjaxTocsAndNotSelectedAll &&
                        typeof $(saveBinder.modal).attr('data-single-doi') === 'undefined'
                    ) {
                        $('.section-checkbox .section--dois').each(function () {
                            var $tocSection = $(this).closest('.toc__section'),
                                $insideSectionsCheckBoxes;

                            if ($tocSection.find('.section-checkbox .issue-Item__checkbox').prop('checked') === true) {
                                dois += $(this).val() + ',';
                            } else {
                                $insideSectionsCheckBoxes = $tocSection.find(
                                    '.issue-item-container .issue-Item__checkbox:checked'
                                );
                                $insideSectionsCheckBoxes.each(function () {
                                    dois += $(this).attr('name') + ',';
                                });
                            }
                        });
                    } else {
                        if (typeof $(saveBinder.modal).attr('data-single-doi') !== 'undefined') {
                            dois += $(saveBinder.modal).attr('data-single-doi') + ',';
                        } else {
                            $('.issue-item-container .issue-Item__checkbox:checked').each(function () {
                                dois += $(this).attr('name') + ',';
                            });
                        }
                    }
                } else {
                    $binderList.find('[name="doiVal"]').each(function () {
                        dois += $(this).val() + ',';
                    });
                }
                code = $el.val();
                if (multiDois) {
                    view = 'modal';
                }
                $.ajax({
                    url: link,
                    data: {
                        dois: dois,
                        binderCode: code,
                    },
                    type: 'POST',
                    success: function (data) {
                        var icon, msgType, $message;
                        $el.removeAttr('disabled');
                        $el.next('.label-txt').removeClass('disabled');
                        if (data.message) {
                            icon = 'icon-checkmark';
                            msgType = 'success';
                            if (!isAdd) {
                                icon = 'icon-warning-triangle';
                                msgType = 'warning';
                            }
                            $message = $(
                                '<p class="alert-msg--' +
                                    msgType +
                                    '"><i class="' +
                                    icon +
                                    '"></i>' +
                                    data.message +
                                    '</p>'
                            );
                            saveBinder.showNotification($message, view, $alertElem);
                        }
                    },
                    error: function () {
                        var $message;
                        $el.removeAttr('disabled');
                        $el.next('.label-txt').removeClass('disabled');
                        $message = $(
                            '<p class="alert-msg--error"><i class="icon-notice"></i>The action does not complete successfully, please try again later</p>'
                        );
                        saveBinder.showNotification($message, view, $alertElem);
                    },
                });
            });
            $(document).on('click', '.toggle-binder-form', function (e) {
                var action = $(this).attr('data-action'),
                    $parent = $(this).parents('.binder__create');
                e.preventDefault();
                if (action === 'show') {
                    $parent.find('.show-binder-form').fadeOut('fast', function () {
                        setTimeout(function () {
                            $parent.find('.binder__create-mini-form').fadeIn('fast');
                        }, 200);
                    });
                } else {
                    $parent.find('.binder__create-mini-form').hide();
                    $parent.find('.show-binder-form').show();
                }
            });
            $('body').on('click', '.saveToBinders', function (event) {
                var $el = $(this);
                var view = 'list';

                saveBinder.searchtBinderSave($el);
                var link = $el.attr('data-link'); // real link
                // var link = '/specs/products/acm/components/save-to-binder/templates/demo/binders.json'; // test link
                var $binderList = $el.parent().find('.saveToBinders-list');
                var requestNewContent = false;
                if ($binderList.find('.rlist.saveToBinders-list__items').children(':not(.loader)').length === 0) {
                    requestNewContent = true;
                }

                if ($el.attr('data-modal-toggle') === 'modal') {
                    event.preventDefault();
                    $binderList = $(saveBinder.modal).find('.saveToBinders-list');
                    view = 'modal';
                    requestNewContent = true;
                }

                if (requestNewContent) {
                    if (view === 'modal') {
                        $(saveBinder.modal).removeAttr('data-single-doi');
                        if (typeof $el.attr('data-doi') !== 'undefined') {
                            $(saveBinder.modal).attr('data-single-doi', $el.attr('data-doi'));
                        }
                        $(saveBinder.modal).modal();
                    }

                    $.ajax({
                        url: link,
                        type: 'GET',
                        success: function (data) {
                            if (data) {
                                $binderList.find('.saveToBinders-list__container .loader').remove();
                                if (view === 'modal') {
                                    $binderList.find('.saveToBinders-list__container').empty();
                                    $binderList
                                        .find('.saveToBinders-list__container')
                                        .append('<ul class="rlist saveToBinders-list__items"></ul>');
                                }
                                data.forEach(function (element) {
                                    if (typeof element.binderName !== 'undefined' && element.binderName !== '') {
                                        $binderList
                                            .find('.rlist.saveToBinders-list__items')
                                            .append(
                                                '<li class="input-group"><label class="checkbox--primary" ' +
                                                    'tabindex="0"><input type="checkbox" name="binderCode" ' +
                                                    'value="' +
                                                    element.binderCode +
                                                    '" ' +
                                                    (element.added ? 'checked' : '') +
                                                    '><span class="label-txt"><div class="saveToBinders-list__binder-name">' +
                                                    element.binderName +
                                                    '</div></span></label></li>'
                                            );
                                    }
                                });
                                $binderList.find('.rlist.saveToBinders-list__items').scrollBox();
                            }
                        },
                        error: function (data) {
                            if (data.status === 401 && data.responseJSON.url) {
                                location.href = data.responseJSON.url;
                                const searchSaveBinderBtn = document.querySelector('li .saveToBinders');
                                if (!searchSaveBinderBtn) $(saveBinder.modal).modal('toggle');
                            }
                        },
                    });
                }
            });
        },
        showNotification: function ($message, view, $target) {
            if (view === 'list') {
                $message.addClass('alert-msg--fixed');
            }
            if (saveBinder.notificationInterval) {
                clearTimeout(saveBinder.notificationInterval);
            }
            $target.html($message.get(0).outerHTML);
            saveBinder.notificationInterval = setTimeout(function () {
                $target.find('[class*=alert-msg]').fadeOut();
            }, 5000);
        },
        searchtBinderSave: function ($el) {
            if (!$el.data('modal-toggle') && !$el.parent().find('.saveBinder').length) {
                const binderValue = $el.attr('data-link').split('=')[1];
                const dropBlockID = $el.attr('data-db-target-for');
                const binderListData = {
                    dropBlockID,
                    binderValue,
                };
                const saveTobinderPopup = bindersList(binderListData);

                $el.parent().append(saveTobinderPopup);
                $el.parent()
                    .find('.close')
                    .click(function () {
                        if ($el.hasClass('js--open')) {
                            $el.click();
                        }
                    });
                UX.dropBlock.init();
            }
        },
    };
    UX.saveBinder = saveBinder;
})();
