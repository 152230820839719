import moment from 'moment';
import Calendar from './Calendar';

(function () {
    /*
    source:
        -https://github.com/Baremetrics/calendar

    it is used in 2 widgets
    - widgets/advanced-search/templates/advanced-search/alt-advanced-search-for-events-tmpl.jade
    - widgets/claim-request-list/templates/claim-request-list/claim-request-list-tmpl.jade

     */
    var dateRangePicker = {
        startDate: '2000-01-1',
        endDate: moment(),
        earliestDate: '2000-01-1',
        latestDate: moment(),
        $dateRangeDouble: $('.daterange--double'),
        $dateRangeSingle: $('.daterange--single'),

        init: function () {
            var startDateVal, endDateVal;

            if (dateRangePicker.$dateRangeDouble.length) {
                if (typeof dateRangePicker.$dateRangeDouble.attr('data-earliset') !== 'undefined') {
                    dateRangePicker.earliestDate = dateRangePicker.$dateRangeDouble.attr('data-earliset');
                }
                if (typeof dateRangePicker.$dateRangeDouble.attr('data-latest') !== 'undefined') {
                    dateRangePicker.latestDate = dateRangePicker.$dateRangeDouble.attr('data-latest');
                }

                if (typeof dateRangePicker.$dateRangeDouble.attr('data-start') !== 'undefined') {
                    dateRangePicker.startDate = dateRangePicker.$dateRangeDouble.attr('data-start');
                } else {
                    dateRangePicker.startDate = dateRangePicker.earliestDate;
                }
                if (typeof dateRangePicker.$dateRangeDouble.attr('data-end') !== 'undefined') {
                    dateRangePicker.endDate = dateRangePicker.$dateRangeDouble.attr('data-end');
                } else {
                    dateRangePicker.endDate = dateRangePicker.latestDate;
                }

                startDateVal = dateRangePicker.startDate;
                endDateVal = dateRangePicker.endDate;

                new Calendar({
                    element: dateRangePicker.$dateRangeDouble,
                    earliest_date: dateRangePicker.earliestDate,
                    latest_date: dateRangePicker.latestDate,
                    current__moment_date: moment(),
                    current_date: moment(),
                    format: {input: 'MMM D, YYYY'},

                    start_date: startDateVal,
                    end_date: endDateVal,

                    presets: [
                        {
                            label: 'Last 30 days',
                            start: moment(),
                            end: moment().subtract(30, 'days'),
                        },
                        {
                            label: 'Next 30 days',
                            start: moment(),
                            end: moment().add(30, 'days'),
                        },
                        {
                            label: 'This month',
                            start: moment().startOf('month'),
                            end: moment().endOf('month'),
                        },
                        {
                            label: 'Last 3 months',
                            start: moment().subtract(3, 'month').startOf('month'),
                            end: moment().subtract(1, 'month').endOf('month'),
                        },
                        {
                            label: 'Last 6 months',
                            start: moment().subtract(6, 'month').startOf('month'),
                            end: moment().subtract(1, 'month').endOf('month'),
                        },
                        {
                            label: 'Next 6 months',
                            start: moment().subtract(-1, 'month').startOf('month'),
                            end: moment().subtract(-6, 'month').endOf('month'),
                        },
                        {
                            label: 'Last year',
                            start: moment().subtract(1, 'year').startOf('year'),
                            end: moment().subtract(1, 'year').endOf('year'),
                        },
                        {
                            label: 'All time',
                            start: moment(dateRangePicker.earliestDate),
                            end: moment(dateRangePicker.latestDate),
                        },
                    ],
                });
            }

            if (dateRangePicker.$dateRangeSingle.length) {
                new Calendar({
                    element: dateRangePicker.$dateRangeSingle,
                    current_date: dateRangePicker.$dateRangeSingle.data('start'),
                    format: {input: 'M/D/YYYY'},
                    presets: true,
                    required: false,
                    callback: function () {
                        var date = moment(this.current_date).format('YYYY-MM-DD');
                        var form = this.element.closest('form');
                        form.find('[data-from=datepicker]').val(date);
                        if (this.element.data('on-change') === 'submit') {
                            form.submit();
                        }
                    },
                });
            }
        },
    };
    UX.dateRangePicker = dateRangePicker; // add to global namespace
})();
