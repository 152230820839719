(function () {
    var acmBrowse = {
        init: function () {
            acmBrowse.control();
        },

        control: function () {
            this.addLinkToCard();
        },
        addLinkToCard: function () {
            // Select the container element
            const collectionList = document.querySelectorAll('.collection-carousel .collection-item');

            collectionList.forEach(collection => {
                const firstAnchor = collection.querySelector('a');

                collection.addEventListener('click', () => {
                    window.location.href = firstAnchor;
                });
            });
        },
    };

    UX.acmBrowse = acmBrowse;
})();
