var d3 = require('d3-latest');
var barChart = {
    chart: '',
    data: '',
    startYear: '',
    endYear: '',
    el: '.d3-bar-chart',
    init: function () {
        barChart.control();
    },
    control: function () {
        var data;
        barChart.chartInit();
        data = barChart.getData();
        barChart.build(data);
    },
    chartInit: function () {
        var $parent = $(barChart.el).parent();
        $(barChart.el).attr('width', $parent.width());
        $(barChart.el).attr('viewBox', '0 0 ' + $parent.width() + ' 405');
        (barChart.chart = d3.select(barChart.el)),
            (window.margin = {
                top: 20,
                right: 20,
                bottom: 20,
                left: 30,
            }),
            (window.width = +barChart.chart.attr('width') - window.margin.left - window.margin.right),
            (window.height = +barChart.chart.attr('height') - window.margin.top - window.margin.bottom),
            (window.g = barChart.chart
                .append('g')
                .attr('transform', 'translate(' + window.margin.left + ',' + window.margin.top + ')'));
    },
    getData: function () {
        var data = barChart.chart.attr('data-chart-data');
        data = JSON.parse(data);
        return data;
    },
    build: function (data) {
        var makeYLines, yearsLength, maxYear, step, xAxis;

        var x = d3
            .scaleBand()
            .domain(
                data.map(function (d) {
                    return Number(d.year);
                })
            )
            .range([0, window.width]);

        var y = d3.scaleLinear().range([window.height - 5, 0]);

        y.domain([
            d3.min(data, function (d) {
                return Number(d.count) - 1;
            }),
            d3.max(data, function (d) {
                return Number(d.count);
            }),
        ]);
        makeYLines = d3.axisLeft().scale(y);
        yearsLength = data.length;
        maxYear = d3.max(data, function (d) {
            return Number(d.year);
        });
        step = Math.floor(yearsLength / 4);
        xAxis = d3.axisBottom(x).tickValues(
            x.domain().filter(function (d, i) {
                var ticks = false;
                if (data.length < 8 || i === data.length - 1) {
                    ticks = true;
                }
                if (i % step === 0 && maxYear - d > step) {
                    ticks = true;
                }

                return ticks;
            })
        );
        window.g
            .append('g')
            .attr('transform', 'translate(0,' + (window.height - 5) + ')')
            .attr('opacity', '0.5')
            .call(xAxis);
        window.g
            .append('g')
            .attr('class', 'stroke')
            .attr('opacity', '0.2')
            .call(makeYLines.tickSize(-window.width, 0, 0).tickFormat(''));
        window.g
            .append('g')
            .call(
                d3
                    .axisLeft(y)
                    .ticks(10)
                    .tickFormat(function (d) {
                        if (d === parseInt(d, 10)) {
                            return d;
                        }
                    })
            )
            .attr('opacity', '0.5')
            .append('text')
            .attr('fill', '#000')
            .attr('transform', 'rotate(-90)')
            .attr('y', 6)
            .attr('dy', '0.71em')
            .attr('text-anchor', 'end');
        window.g
            .selectAll('.bar')
            .data(data)
            .enter()
            .append('rect')
            .attr('class', 'bar')
            .attr('fill', '#651FFF')
            .attr('x', function (d) {
                return x(d.year);
            })
            .attr('width', x.bandwidth() - 2)
            .attr('y', function () {
                return y(0) - 5;
            })
            .attr('height', 0)
            .transition()
            .duration(2000)

            .attr('y', function (d) {
                return y(d.count) - 5;
            })
            .attr('height', function (d) {
                return window.height - y(d.count);
            });
    },
};
UX.barChart = barChart; // add to global namespace
