(function () {
    var simpleTooltip = {
        $toolTipText: null,
        $mobileTooltip: $('.simple-tooltip__mobile'),

        init: function () {
            simpleTooltip.control();
        },

        control: function () {
            var windowWidth = $(window).width();
            var tooltipVisible = false;
            var screenMd = 992;

            if (windowWidth >= screenMd) {
                $('[class*=simple-tooltip__]:not(.js--open):not(.simple-tooltip__mobile)').hover(function () {
                    simpleTooltip.showTooltipText($(this));
                });

                $('[class*=simple-tooltip__]:not(.simple-tooltip__mobile)').on('mouseleave click', function () {
                    simpleTooltip.$toolTipText.remove();
                });
            }

            $(document).on('click mouseenter', '.simple-tooltip__mobile', function () {
                if (tooltipVisible === false) {
                    simpleTooltip.showTooltipText($(this));
                    tooltipVisible = true;
                }
            });

            $(document).on('mouseleave', '.simple-tooltip__mobile', function () {
                if (tooltipVisible) {
                    simpleTooltip.$toolTipText.remove();
                    tooltipVisible = false;
                }
            });

            $('[class*=simple-tooltip__].disable').on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
            });

            if (windowWidth <= UX.grid.screenXs) {
                $('.advanced_search_container').on('click', function (e) {
                    var $mobileTooltip = simpleTooltip.$mobileTooltip;
                    if (!$mobileTooltip.is(e.target) && $mobileTooltip.has(e.target).length === 0) {
                        if (simpleTooltip.$toolTipText !== null) {
                            simpleTooltip.$toolTipText.remove();
                            tooltipVisible = false;
                        }
                    }
                });
            }

            //remove tooltip when scroll
            if ($('.recommended--lazyLoad').length) {
                $(window).on('scroll', function () {
                    if (simpleTooltip.$toolTipText !== null) {
                        simpleTooltip.$toolTipText.remove();
                        tooltipVisible = false;
                    }
                });
            }
        },

        showTooltipText: function ($el) {
            var $this = $el;
            var tooltipText = '';
            var classes = 'simple-tooltip-text';

            if (!$this.hasClass('js--open')) {
                if (typeof $this.data('title') !== 'undefined') {
                    tooltipText = $this.data('title');
                } else if (typeof $this.attr('alt') !== 'undefined') {
                    tooltipText = $this.attr('alt');
                } else if (typeof $this.attr('title') !== 'undefined') {
                    tooltipText = $this.attr('title');
                }

                // special case for title LIT-274714
                if (
                    tooltipText === 'View this article in HTML format' &&
                    simpleTooltip.isUrlValid($this.attr('href'))
                ) {
                    let url = new URL($this.attr('href')),
                        locationHost = window.location.host,
                        urlHost = url.hostname;

                    if (typeof $this.attr('href') !== 'undefined' && locationHost !== urlHost) {
                        tooltipText = 'View this article on the magazine site (external)';
                    }
                }

                if (typeof $this.attr('data-customClass') !== 'undefined') {
                    classes = classes + ' ' + $this.attr('data-customClass');
                }
                $('body').append(
                    '<span class="' +
                        classes +
                        '"><span class="simple-tooltip-text-top-arrow"></span>' +
                        tooltipText +
                        '</span>'
                );
                simpleTooltip.$toolTipText = $('.simple-tooltip-text');
                simpleTooltip.setDimention($this);
            }
        },

        setDimention: function ($el) {
            var left = $el.offset().left;
            var top = $el.offset().top;
            var width = $el.outerWidth();
            var height = $el.outerHeight();

            var widthText = simpleTooltip.$toolTipText.outerWidth();
            var heightText = simpleTooltip.$toolTipText.outerHeight();

            if ($el.hasClass('simple-tooltip__block--b')) {
                simpleTooltip.setBlockDimention(widthText, width, left, top + height + 10, 'bottom', $el);
            }

            if ($el.hasClass('simple-tooltip__block--t')) {
                simpleTooltip.setBlockDimention(widthText, width, left, top - heightText - 10, 'top', $el);
            }

            if ($el.hasClass('simple-tooltip__inline--r')) {
                simpleTooltip.setinlineDimention(heightText, height, top, left + width + 10, 'right', $el);
            }

            if ($el.hasClass('simple-tooltip__inline--l')) {
                simpleTooltip.setinlineDimention(heightText, height, top, left - widthText - 10, 'left', $el);
            }
        },
        setBlockDimention: function (widthText, width, left, top, className) {
            var leftValue;
            if (widthText > width) {
                leftValue = left - (widthText - width) / 2;
            } else {
                leftValue = left + (width - widthText) / 2;
            }

            if (leftValue < 0) {
                simpleTooltip.$toolTipText.find('.simple-tooltip-text-top-arrow').css({left: width / 2 + left - 9});
                leftValue = 0;
            } else if (leftValue + simpleTooltip.$toolTipText.outerWidth() > $('body').outerWidth()) {
                simpleTooltip.$toolTipText
                    .find('.simple-tooltip-text-top-arrow')
                    .css({left: widthText - width - ($('body').outerWidth() - width - left)});
                leftValue = $('body').outerWidth() - simpleTooltip.$toolTipText.outerWidth();
            }

            simpleTooltip.$toolTipText.addClass(className).css({
                left: leftValue,
                top: top,
            });
        },
        setinlineDimention: function (heightText, height, top, left, className) {
            var topValue;
            if (heightText > height) {
                topValue = top - (heightText - height) / 2;
            } else {
                topValue = top + (height - heightText) / 2;
            }

            simpleTooltip.$toolTipText.addClass(className).css({
                left: left,
                top: topValue,
            });
        },

        isUrlValid: function (url) {
            return /^(https?|s?ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(
                url
            );
        },
    };
    UX.simpleTooltip = simpleTooltip; // add to global namespace
})();
