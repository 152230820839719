import './io-polyfill'; // intersectionObserver polyfill

(function () {
    const io = {
        observe: function(targetSelector, inViewportCallback, outOfViewportCallback, fireOnce, observerConfig) {
            const targetNode = document.querySelector(targetSelector);
            const isFnc = {
                inViewportCallback: typeof inViewportCallback === 'function',
                outOfViewportCallback: typeof outOfViewportCallback === 'function'
            }

            // Exit early
            if (!targetNode || (!isFnc.inViewportCallback && !isFnc.outOfViewportCallback)) return;

            // Observer
            const observer = new IntersectionObserver(function(entries) {
                const entry = entries[0];

                // Are we in viewport?
                if (entry.isIntersecting || entry.intersectionRatio > 0) {
                    if (isFnc.inViewportCallback) inViewportCallback();
                    if (fireOnce) {
                        observer.unobserve(entry.target);
                    }
                } else {
                    if (isFnc.outOfViewportCallback) outOfViewportCallback();
                }
            }, observerConfig);

            // Observe
            observer.observe(targetNode);
        }
    };

    UX.intersectionObserver = io; // add to global namespace
    UX.observer = io.observer;
})();
