const beautify = require('js-beautify').html;

(function () {
    var authorizer = {
        $modal: $('#modal-authorizer'),
        $form: null,
        ajaxUrl: '',
        $authorizerResponseText: $('#authorizer-response-text'),
        $authorizerResponseHTML: $('#authorizer-response-html'),
        $checkboxControls: $('.checkbox-controls'),
        $contribId: $('#contribId').val(),
        $analyticsInput: $('#analytics-input'),
        $enableCheck: $('#enable-check'),
        mainModalContent: $('.authorizer'),
        noEligiblePublications: $('.no-eligible-publications'),
        $mainPanel: $('.authorizer .panel'),
        $panelLoader: $('.authorizer .authorizer-panel-loader'),
        $urlsContainer: $('.authorizer .urls-container'),
        isAuthorized: false,
        widgetId: $('#authorizer-widgetId').val(),
        pbContext: encodeURI($('meta[name=pbContext]').attr('content')),
        renderContentUrl: '/pb/widgets/ux3/Authorizer/renderAuthorizerContent',
        fetchUrlsUrl: '/action/fetchAuthorizerUrls',
        $doisHiddenInputs: $('#doiAuthorizer, #multiDoisAuthorizer, #batchedAuthorization'),

        init: function () {
            authorizer.$form = authorizer.$modal.find('.re-authorize-form');
            authorizer.control();
        },

        control: function () {
            authorizer.enableSharingControl(authorizer.$enableCheck);
            //on Change
            authorizer.$enableCheck.on('change', function () {
                var $this = $(this);
                authorizer.enableSharingControl($this);
            });

            authorizer.authorizeItem();
            authorizer.authorizeMultiItems();
            authorizer.authorizeBatch();

            authorizer.checkboxesControls();
            authorizer.reAuthorize();
            authorizer.urlValidation();
        },

        authorizeItem: function () {
            // Handle authorize by item
            $(document).on('click', '.authorizerBtn', function (event) {
                var $el = $(this),
                    dois = $el.data('authorizer-doi');
                authorizer.prepareAuthUrlAjax($el, 'single', dois);
            });
        },

        authorizeMultiItems: function () {
            // Handle multi items authorize
            $(document).on('click', '.authorizerBtnMulti', function (event) {
                var $el = $(this),
                    dois = '',
                    addComma = false,
                    $checkedItems = $('.issue-item-container .issue-Item__checkbox:checked');

                $checkedItems.each(function () {
                    var $checkedItemParent = $(this).closest('li.issue-item-container');
                    if (typeof $checkedItemParent.find('.authorizerBtn').attr('data-authorizer-doi') !== 'undefined') {
                        if (addComma) {
                            dois += ',' + $checkedItemParent.find('.authorizerBtn').attr('data-authorizer-doi');
                        } else {
                            dois += $checkedItemParent.find('.authorizerBtn').attr('data-authorizer-doi');
                            addComma = true;
                        }
                    }
                });

                if (dois !== '') {
                    authorizer.prepareAuthUrlAjax($el, 'multi', dois);
                } else {
                    authorizer.mainModalContent.hide();
                    authorizer.noEligiblePublications.show();
                }
            });
        },

        authorizeBatch: function () {
            // Handle batch authorize
            $(document).on('click', '.authorizerBtnBatch', function (event) {
                var $el = $(this);
                authorizer.prepareAuthUrlAjax($el, 'batch');
            });
        },
        prepareAuthUrlAjax: function ($el, type, dois) {
            var contribId,
                // demo = true,
                demo,
                authorizeContentUrl,
                authorizeFetchUrlsUrl;

            authorizer.noEligiblePublications.hide();
            authorizer.mainModalContent.show();

            //prepare the popup as one item authorizer form
            $('.checkbox-controls:not([name="css"])').prop('checked', false);
            authorizer.$doisHiddenInputs.remove();
            if (type === 'single') {
                authorizer.$analyticsInput.show();
                $('.enable-sharing-text').html('Enable the ACM Author-Izer service for:');
                authorizer.$form.prepend('<input type="hidden" id="doiAuthorizer" name="doi" value="' + dois + '">');
            } else {
                authorizer.$analyticsInput.hide();
                if (type === 'multi') {
                    $('.enable-sharing-text').html(
                        'Enable the ACM Author-Izer service for all the selected ACM publications'
                    );
                    authorizer.$form.prepend(
                        '<input type="hidden" id="multiDoisAuthorizer" name="multiDoisAuthorizer" value="' + dois + '">'
                    );
                }
                if (type === 'batch') {
                    $('.enable-sharing-text').html(
                        'Enable the ACM Author-Izer service for all of your ACM publications'
                    );
                    authorizer.$form.prepend(
                        '<input type="hidden" id="batchedAuthorization" name="batchedAuthorization" value="true">'
                    );
                }
            }

            contribId = authorizer.$contribId;
            demo = false;

            if (demo) {
                authorizeContentUrl =
                    '/specs/products/acm/widgets/authorizer/templates/ajax/authorizer-response.html&css=true';
                authorizeFetchUrlsUrl = '/specs/products/acm/widgets/authorizer/templates/data/authorizer-urls.json';
            } else if (type === 'batch') {
                authorizeContentUrl =
                    authorizer.renderContentUrl +
                    '?batchedAuthorization=true&cid=' +
                    contribId +
                    '&widgetId=' +
                    authorizer.widgetId +
                    '&pbContext=' +
                    authorizer.pbContext +
                    '&css=true';
                authorizeFetchUrlsUrl = authorizer.fetchUrlsUrl + '?batchedAuthorization=true&cid=' + contribId;
            } else {
                authorizeContentUrl =
                    authorizer.renderContentUrl +
                    '?dois=' +
                    dois +
                    '&cid=' +
                    contribId +
                    '&widgetId=' +
                    authorizer.widgetId +
                    '&pbContext=' +
                    authorizer.pbContext +
                    '&css=true';
                authorizeFetchUrlsUrl = authorizer.fetchUrlsUrl + '?dois=' + dois + '&cid=' + contribId;
            }

            authorizer.authorizerRenderContentAjax(authorizeContentUrl, type === 'single');
            authorizer.authorizerFetchUrlsAjax(authorizeFetchUrlsUrl);
        },

        checkboxesControls: function () {
            //handle the checkboxes requests

            var checkboxName = '',
                authorizerUrl = '';

            authorizer.$checkboxControls.change(function () {
                checkboxName = '&' + $(this).attr('name') + '=true';
                authorizerUrl = authorizer.ajaxUrl;

                if (this.checked) {
                    authorizerUrl = authorizerUrl + checkboxName;
                } else {
                    authorizerUrl = authorizerUrl.replace(checkboxName, '');
                }

                authorizer.authorizerRenderContentAjax(authorizerUrl, false);
            });
        },

        authorizerRenderContentAjax(ajaxUrl, isItem) {
            // Render authorizer content
            if (authorizer.ajaxUrl !== ajaxUrl) {
                authorizer.$authorizerResponseText.text('');
                authorizer.$authorizerResponseHTML.html('');
                authorizer.$mainPanel.hide();
                authorizer.$panelLoader.fadeIn(250);

                $.ajax({
                    method: 'GET',
                    url: ajaxUrl,
                    success: function (data) {
                        var articleTitle;
                        if (data && data.length) {
                            authorizer.$authorizerResponseText.text(
                                beautify(data, {
                                    'max-preserve-newlines': 1,
                                    'wrap-line-length': 80,
                                })
                            );
                            authorizer.$authorizerResponseHTML.html(data);

                            if (isItem) {
                                // update the title of the article on the analytics input placeholder
                                articleTitle = authorizer.$authorizerResponseHTML.find('.issue-item__title a').html();
                                authorizer.$analyticsInput.val(articleTitle).attr('title', articleTitle);
                            }

                            authorizer.$panelLoader.fadeOut(250);
                            authorizer.$mainPanel.fadeIn(300);
                            authorizer.ajaxUrl = ajaxUrl;
                        }
                    },
                    error: function (err) {},
                });
            }
        },

        reAuthorize: function () {
            // reAuthorize button (submit)

            $(document).on('click', '.re-authorize', function (event) {
                var reAuthorizeUrl = '',
                    cid = '',
                    enable = '',
                    analytics = '',
                    batchedAuthorization,
                    dois,
                    doi,
                    websites = '';

                if (authorizer.$contribId !== '') {
                    cid = '&cid=' + authorizer.$contribId;
                }
                if (authorizer.$enableCheck.val() !== 'false') {
                    enable = '&enable=' + authorizer.$enableCheck.val();
                }
                if (authorizer.$analyticsInput.val() !== '') {
                    analytics = '&analytics=' + authorizer.$analyticsInput.val();
                }
                if (authorizer.$urlsContainer.find('.form-control').length) {
                    authorizer.$urlsContainer.find('.form-control').each(function () {
                        if ($(this).val() !== '') {
                            websites += '&website=' + $(this).val();
                        }
                    });
                }
                if ($('#batchedAuthorization').length) {
                    batchedAuthorization = $('#batchedAuthorization').val();
                    reAuthorizeUrl =
                        '/action/reAuthorize?batchedAuthorization=' +
                        batchedAuthorization +
                        cid +
                        enable +
                        analytics +
                        websites;
                } else if ($('#multiDoisAuthorizer').length) {
                    dois = $('#multiDoisAuthorizer').val();
                    reAuthorizeUrl = '/action/reAuthorize?dois=' + dois + cid + enable + analytics + websites;
                } else if ($('#doiAuthorizer').length) {
                    doi = $('#doiAuthorizer').val();
                    reAuthorizeUrl = '/action/reAuthorize?dois=' + doi + cid + enable + analytics + websites;
                }

                $.ajax({
                    method: 'GET',
                    url: reAuthorizeUrl,
                    success: function (data) {
                        $('.re-authorize-message').html(
                            '<i aria-hidden="true" class="icon-success"></i>' + data.message
                        );
                        $('.re-authorize-message').fadeIn(300);
                        setTimeout(function () {
                            $('.re-authorize-message').fadeOut(300);
                        }, 4000);
                    },
                    error: function (err) {},
                });
            });
        },

        authorizerFetchUrlsAjax(ajaxUrl) {
            //get authorizer URLs
            var defaultInput;

            authorizer.isAuthorized = false;
            authorizer.$urlsContainer.html('');

            defaultInput =
                '<div class="col-sm-6"><div class="input-group input-group--gray-bg">' +
                '<label class="input-group__field-container input-group__field-container--left-icon">' +
                '<span class="input-group-addon">' +
                '<i aria-hidden="true" class="icon-earth">' +
                '</i>' +
                '</span>' +
                '<input type="text" name="website" placeholder="https://example.com" value="" class="form-control">' +
                '</label>' +
                '<span class="error-url">Please enter a valid URL' +
                '</span>' +
                '</div>' +
                '</div>';

            $.ajax({
                method: 'GET',
                url: ajaxUrl,
                success: function (data) {
                    if (data) {
                        if (data.length) {
                            $.each(data, function (i, url) {
                                authorizer.$urlsContainer.append(
                                    defaultInput.replace('value=""', 'value="' + url + '"')
                                );
                            });

                            authorizer.$enableCheck.prop('checked', true).val('true');
                            authorizer.isAuthorized = true;
                        }
                        if (data.length === 0) {
                            authorizer.$urlsContainer.append(defaultInput + defaultInput);
                            authorizer.$enableCheck.prop('checked', false).val('false');
                        } else if (data.length === 1) {
                            authorizer.$urlsContainer.append(defaultInput);
                        }
                    } else {
                        authorizer.$urlsContainer.append(defaultInput + defaultInput);
                    }

                    authorizer.enableSharingControl(authorizer.$enableCheck);
                },
                error: function (err) {
                    authorizer.$urlsContainer.append(defaultInput + defaultInput);
                    authorizer.$enableCheck.prop('checked', false).val('false');
                },
            });
        },

        urlValidation: function () {
            // validate URL's
            authorizer.$urlsContainer.delegate('.form-control', 'keyup keypress blur change', function () {
                var $this = $(this);

                if ($this.val() !== '') {
                    if (authorizer.validateURL($this.val()) === false) {
                        $this.closest('label').next('span.error-url').fadeIn(250);
                        $this.closest('label').addClass('input-group__field-container--error');
                    } else {
                        $this.closest('label').next('span.error-url').fadeOut(250);
                        $this.closest('label').removeClass('input-group__field-container--error');
                    }
                } else {
                    $this.closest('label').next('span.error-url').fadeOut(250);
                    $this.closest('label').removeClass('input-group__field-container--error');
                }

                // disable reAuthorize button if there is a validation error
                if (authorizer.$urlsContainer.find('.input-group__field-container--error').length) {
                    $('.re-authorize').addClass('disabled');
                } else {
                    $('.re-authorize').removeClass('disabled');
                }
            });
        },

        validateURL: function (URLval) {
            var urlregex = new RegExp(
                '^(http://www.|https://www.|http://|https://)+[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$'
            );
            return urlregex.test(URLval);
        },

        enableSharingControl: function ($el) {
            //Control enable checkbox

            var $reAuthorizeBtn = $('.re-authorize');

            if ($el.prop('checked') === true) {
                $el.val('true');

                if (authorizer.isAuthorized) {
                    $reAuthorizeBtn.html('re-Authorize');
                    authorizer.$urlsContainer.removeClass('disabled');
                }
            } else {
                $el.val('false');

                if (authorizer.isAuthorized) {
                    $reAuthorizeBtn.html('Deactivate');
                    authorizer.$urlsContainer.addClass('disabled');
                }
            }
        },
    };

    UX.authorizer = authorizer;
})();
