import 'ui-core/plugins/slide/js/slide.js';

UX.slide.init = function () {
    UX.slide.vPort = 'screen-md';
    UX.slide.$slide =
        '<div class="w-slide" id="w-slideElement" role="dialog" aria-labelledby="w-slide__title"><div class="w-slide_head" tabindex="0"><a href="#" class="w-slide__back"><i class=" icon-arrow_l" aria-hidden="true"></i>' +
        UX.slide.backLabel +
        '</a><span class="w-slide__title" id="w-slide__title"></span></span></div><div class="w-slide__content"></div></div>';

    UX.slide.$elements = $(UX.slide.$slide);
    UX.slide.displaySlideHtmlToPage();

    UX.slide.$contents = UX.slide.$elements.find('.w-slide__content');
    UX.slide.$slideinfo = UX.slide.$elements.find('.w-slide__title');
    UX.slide.$back = UX.slide.$elements.find('.w-slide__back');
    UX.slide.$toggle = $('.w-slide__btn');

    UX.slide.elementvPort = UX.slide.vPort;
    UX.slide.vPort = [];

    UX.slide.customViewPorts();

    UX.slide.$toggle.each(function (index) {
        UX.slide.check.viewPort($(this), index);
        UX.slide.responsive(index);
    });

    UX.slide.additionalInitialization();
    UX.slide.control();
    UX.slide.additionalControls();
    if (typeof facetDateChart != 'undefined') {
        facetDateChart.control(); /*eslint-disable-line*/
    }

    if (UX.utils && UX.utils.isRTL()) {
        UX.slide.isRTL = true;
    }
};
UX.slide.control = function () {
    const $body = $('body');
    let slide = UX.slide,
        $slideElement = $('.w-slide__content');

    $body.on('click', '.w-slide__btn', function (e) {
        if (slide.isQueue && slide.isMobile) {
            e.stopImmediatePropagation();
            $(this).off('click');
            return false;
        }
        slide.isQueue = true;

        if ($(this).hasClass('disable')) {
            e.stopPropagation();
            e.preventDefault();
            return;
        }

        if ($(this).hasClass('slide-active')) {
            e.stopPropagation();
            e.preventDefault();
            slide.addTransition();
            slide.$toggle = $(this);
            slide.$target = $(slide.$toggle.data('slide-target'));
            slide.back = false;

            if ($(this).is('[id*=pane-pcw]') && UX.tab.check.empty($(this))) {
                setTimeout(function () {
                    slide.on.show(e);
                }, 3000);
            } else {
                slide.on.show(e);
            }
        }
    });

    $slideElement.on('keydown', function (e) {
        let $pillContent = $('#w-slideElement .tab__content > .tab__pane.active:visible'),
            $items = $pillContent.find('a, button, input'),
            lastItem;
        $items.each(function (index) {
            if (index === $items.length - 1) {
                lastItem = $(this);
                lastItem.addClass('lastElement');
            }
        });

        if (e.keyCode === 9 && $(e.target).hasClass('lastElement')) {
            $('.w-slide_head').focus();
        }
    });

    $body.on('click', '.w-slide__back, .w-slide__hide', function (e) {
        if ($(this).hasClass('w-slide__back')) {
            e.preventDefault();
        }

        slide.back = true;
        slide.on.hide(e);
    });
};
