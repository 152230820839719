(function () {
    var genericTruncate = {
        init: function () {
            genericTruncate.truncateAll();
            genericTruncate.control();
        },
        control: function () {
            $('body').on('click', '.tab__nav a', function () {
                var $pane, $trunText, $trunShadow, $trunList;
                if ($(this).attr('href').includes('#')) {
                    $pane = $($(this).attr('href'));
                    $trunText = $pane.find($(".truncate-text:not('.trunc-done')"));
                    $trunShadow = $pane.find($(".truncate-with-shadow:not('.trunc-done')"));
                    $trunList = $pane.find($(".truncate-list:not('.trunc-done')"));

                    setTimeout(function () {
                        genericTruncate.truncate.text($trunText);
                        genericTruncate.truncate.shadow($trunShadow);
                        genericTruncate.truncate.list($trunList);
                    }, 50);
                }
            });

            $(document).on('keydown', '.read-less', function (e) {
                var code = e.keyCode ? e.keyCode : e.which, //to support both methods
                    $this = $(this),
                    $parent = null,
                    $focusElements =
                        'button,  a:not(.disabled), input, select, textarea , [tabindex]:not([tabindex="-1"])';

                e.stopPropagation();
                if (code === 9) {
                    //the Enter keycode
                    $parent = $this.parent();
                    if ($parent.is('li')) {
                        $parent = $parent.parent();
                    }

                    if ($parent.next($focusElements).length) {
                        $parent.next($focusElements).focus();
                    } else if ($parent.next().find($focusElements).length) {
                        $parent.next().find($focusElements).first().focus();
                    }
                }
            });
            $('.modal').on('shown.bs.modal', function () {
                setTimeout(function () {
                    // async and delay
                    UX.genericTruncate.init();
                }, 1);
            });
        },
        truncate: {
            text: function ($ele) {
                $ele.each(function () {
                    var $elem = $(this),
                        seeMoreLink = typeof $elem.data('more') === 'undefined' ? true : $elem.data('more');
                    $elem.css('height', 'auto');
                    $elem.truncate({
                        lines: $elem.data('lines'),
                        type: 'text',
                        seeMoreLink: seeMoreLink,
                        seeMoreText: '(More)',
                        seeLessText: '(Less)',
                        addClass: 'trunc-done',
                        animation: true,
                        readMoreWithSpacebar: true,
                    });
                });
            },

            shadow: function ($ele) {
                $ele.each(function () {
                    var $elem = $(this),
                        seeMoreLink = typeof $elem.data('more') === 'undefined' ? true : $elem.data('more');
                    $elem.css('height', 'auto');
                    $elem.truncate({
                        lines: $elem.data('lines'),
                        type: 'text',
                        seeMoreLink: seeMoreLink,
                        ellipsisChar: '',
                        seeMoreText: 'Read more',
                        seeLessText: 'Less',
                        addClass: 'trunc-done',
                        animation: true,
                        readMoreWithSpacebar: true,
                    });
                });
            },

            list: function ($ele) {
                var $elem, showRemovedCount, position;

                $ele.each(function () {
                    if ($(this).parents('.disable-truncate').length) {
                        return;
                    }
                    $elem = $(this);
                    showRemovedCount =
                        typeof $elem.data('showremovedcount') === 'undefined' ? true : $elem.data('showremovedcount');
                    position = $elem.data('position');
                    $elem.css('height', 'auto');
                    $elem.truncate({
                        lines: $elem.data('lines'),
                        type: 'list',
                        position: position,
                        ellipsisChar: '...',
                        showRemovedCount: showRemovedCount,
                        seeLessText: '(Less)',
                        addClass: 'trunc-done',
                        animation: true,
                        ignoreCountList: '.label',
                        readMoreWithSpacebar: true,
                    });
                });
            },
        },
        truncateAll: function () {
            genericTruncate.truncate.text($(".truncate-text:not('.trunc-done')"));
            genericTruncate.truncate.shadow($(".truncate-with-shadow:not('.trunc-done')"));
            genericTruncate.truncate.list($(".truncate-list:not('.trunc-done')"));
        },
    };

    UX.genericTruncate = genericTruncate; // add to global namespace
})();
