(function () {
    var orgChartRes = {
        $hasNodes: $('.organizational-chart ol.hasNodes'),
        wrapped: false,
        ItemsInRow: 1,
        ItemsToBeAdded: 0,
        ItemsToBeRemoved: 0,

        init: function () {
            orgChartRes.getBGColor();
            orgChartRes.control();
            orgChartRes.on.rebuild();
        },
        control: function () {
            $(window).on('load', function () {
                setTimeout(function () {
                    orgChartRes.on.rebuild();
                }, 400);
            });
            $(window).on('resize', function () {
                orgChartRes.on.rebuild();
            });
        },
        on: {
            rebuild: function () {
                $('.organizational-chart ol.hasNodes').each(function () {
                    var $olItem = $(this),
                        $liItem = $olItem.find(' > li:not(:empty)'),
                        $divItem = $liItem.find(' > div'),
                        rowsNumber,
                        i;
                    orgChartRes.calcTop($liItem);

                    rowsNumber = parseInt($liItem.length / orgChartRes.ItemsInRow);
                    orgChartRes.ItemsToBeAdded = $liItem.length - orgChartRes.ItemsInRow * rowsNumber;
                    orgChartRes.ItemsToBeRemoved = $olItem.find('li:empty').length - orgChartRes.ItemsToBeAdded;

                    if (!orgChartRes.wrapped) {
                        if (!$olItem.hasClass('wrapped')) {
                            $olItem.addClass('wrapped');
                            if (!$divItem.find('.line').length) {
                                $divItem.append('<span class="line"></span>');
                            }

                            if ($olItem.parent('li').parent('ol').hasClass('organizational-chart')) {
                                $('.organizational-chart > li > div').css('width', '100%');
                            }

                            if (orgChartRes.ItemsToBeAdded > 0) {
                                for (i = 0; i < orgChartRes.ItemsToBeAdded; i++) {
                                    $olItem.append('<li></li>');
                                }
                            }
                        }
                        if (orgChartRes.ItemsToBeRemoved > 0) {
                            for (i = 0; i < orgChartRes.ItemsToBeRemoved; i++) {
                                $olItem.find('li:empty:last-child').remove();
                            }
                        }
                    } else {
                        $olItem.removeClass('wrapped');
                        $olItem.find('> li > div .line').remove();
                        $olItem.find('li:empty').remove();

                        if ($olItem.parent('li').parent('ol').hasClass('organizational-chart')) {
                            $('.organizational-chart > li > div:not("#organizational-chart__title")').css(
                                'width',
                                '235px'
                            );
                        }
                    }
                });

                orgChartRes.set.lineHeight();
            },
        },
        calcTop: function ($liItem) {
            var $this, topVal, topSibilingVal;
            orgChartRes.ItemsInRow = 1;
            $liItem.each(function () {
                $this = $(this);
                topVal = $this.offset().top;

                if ($this.next('li').length) {
                    topSibilingVal = $this.next('li').offset().top;
                } else {
                    orgChartRes.wrapped = true;
                    return true;
                }

                if (topVal !== topSibilingVal) {
                    orgChartRes.wrapped = false;
                    return false;
                } else {
                    orgChartRes.ItemsInRow++;
                }
            });
        },
        set: {
            lineHeight: function () {
                $('.line').each(function () {
                    var $line = $(this),
                        olTop,
                        liTop,
                        divTop;
                    if ($line.closest('.wrapped').length) {
                        olTop = $line.closest('.wrapped').offset().top;
                        liTop = $line.offset().top;
                        divTop = $line.closest('div').offset().top;
                        if (liTop - olTop !== 0) {
                            $line.css('height', divTop - (olTop - 20));
                        }
                    }
                });
            },
        },
        getBGColor: function () {
            $('[data-background]').each(function () {
                $(this).css('color', orgChartRes.setCorrectTextColor($(this).attr('data-background')));
            });
        },
        setCorrectTextColor: function (hex) {
            var threshold = 130 /* about half of 256. Lower threshold equals more dark text on dark background  */,
                hRed,
                hGreen,
                cBrightness,
                hBlue;

            function cutHex(h) {
                return h.charAt(0) === '#' ? h.substring(1, 7) : h;
            }

            function hexToR(h) {
                return parseInt(cutHex(h).substring(0, 2), 16);
            }

            function hexToG(h) {
                return parseInt(cutHex(h).substring(2, 4), 16);
            }

            function hexToB(h) {
                return parseInt(cutHex(h).substring(4, 6), 16);
            }

            hRed = hexToR(hex);
            hGreen = hexToG(hex);
            hBlue = hexToB(hex);
            cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;
            if (cBrightness > threshold) {
                return '#000000';
            } else {
                return '#ffffff';
            }
        },
    };
    UX.orgChartRes = orgChartRes;
})();
