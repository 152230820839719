export default class A11y {
    // sortable table variables
    private sortable_table = document.querySelectorAll('.table--sortable');
    private sortableEl = document.querySelectorAll('.sort-default');

    // tab navigation variables
    private tabContainer = document.querySelectorAll('.tab');
    private tabEl = document.querySelectorAll('.tab__nav__item');


    public initialize = (): void => {
        // needs clean up. create dynamic listeners makers
        this.clickListeners();
        this.keyListeners();
    };

    private addAttr = ($this): void => {
        this.removeAttr();

        if ($this.classList.contains('sorted-asc')) {
            $this.setAttribute('aria-sort', 'ascending');
        }

        else if ($this.classList.contains('sorted-desc')) {
            $this.setAttribute('aria-sort', 'descending');
        }
    }

    private removeAttr = (): void => {
        this.sortableEl.forEach(th => {
            th.removeAttribute('aria-sort');
        })
    }

    private tabFocus = (event, El): void => {
        switch(event.key){
            case 'ArrowRight':
            case 'Home':
                // if the current tab element is last, focus on the first one
                if(El.nextSibling === null) {
                    El.parentElement.firstChild.lastChild.focus();
                }
                // if not, focus on the next one
                else {
                    El.nextSibling.firstChild.focus();
                }

                break;
            case 'ArrowLeft':
            case 'End':
                // if the current tab element is first, focus on the last one
                if(El.previousSibling === null) {
                    El.parentElement.lastChild.firstChild.focus();
                }
                // if not, focus on the previous one
                else {
                    El.previousSibling.firstChild.focus();
                }

                break;
        }
    }

    // event listeners
    // todo: use the ui-utils (domUtils) once we upgrade to 1.48
    private clickListeners = () => {
        this.sortableEl.forEach(element => {
            element.addEventListener('click', () => {
                if (this.sortable_table.length > 0) {
                    this.addAttr(element);
                }
            });
        })
    };

    private keyListeners = () => {
        this.tabEl.forEach(element => {
            element.addEventListener('keyup', (e) => {
                if (this.tabContainer.length > 0) {
                    this.tabFocus(e, element);
                }
            })
        })
    }
}
