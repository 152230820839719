(function () {
    var $body = $('body'),
        binders = {
            $removeEl: null,
            removeLink: null,
            isItem: false,
            init: function () {
                binders._share.init();
                binders._create.init();
                if ($('#sortable').length) {
                    binders._sort.init();
                }
                binders.control();
            },

            control: function () {
                $body.on('click', '.edit-binder--remove, .remove-binder-item', function () {
                    binders.removeConfirmation($(this));
                });

                $body.on('click', '.confirmRemove', function (e) {
                    e.preventDefault();
                    binders.remove();
                });

                $body.on('click', '.reject-binder, .accept-binder', function (e) {
                    var isAccept = true;

                    e.preventDefault();
                    if ($(this).is('.reject-binder')) {
                        isAccept = false;
                    }

                    // Disable the functionality on RO mode
                    if (!$(this).hasClass('disable')) {
                        binders.responseInvitation($(this), isAccept);
                    }
                });

                binders.clickableRow();

                // change the description state when adding new description for binder item
                $(document).on('edit:added', function (e, toggle, isDefault) {
                    var $accordionToggle;
                    if (isDefault) {
                        $accordionToggle = $(
                            '[data-content-target=#' + $(toggle).closest('.accordion__content').attr('id') + ']'
                        );

                        $accordionToggle.find('.btn').remove();
                        $accordionToggle.append(
                            '<i aria-hidden="true" class="icon-Icon_About-Article"></i><span>Description</span><i aria-hidden="true" class="icon-section_arrow_d"></i>'
                        );
                    }
                });

                $(document).ready(function () {
                    $('.more-formats a, a[data-title|="Digital Edition"]').each(function () {
                        this.href = this.href.replace('http://', 'https://'); // todo: workaround for a P1 (clean it up)
                        this.href = this.href.replace('refurl=http://', 'refurl=https://'); // todo: workaround for a P1 (clean it up)
                    });
                    $('.more-formats .icon-open-book, a[data-title|="Digital Edition"]')
                        .parent()
                        .attr('referrerpolicy', 'no-referrer-when-downgrade'); // todo: workaround for a P1 (clean it up)
                    $('a[data-title|="Digital Edition"]').attr('referrerpolicy', 'no-referrer-when-downgrade'); // todo: workaround for a P1 (clean it up)
                });

                $(document).on('edit:reset', function (toggle, isDefault) {
                    var $accordionToggle;
                    if (isDefault) {
                        $accordionToggle = $(
                            '[data-content-target=#' + $(toggle).closest('.accordion__content').attr('id') + ']'
                        );

                        $accordionToggle.click();
                    }
                });

                function confirmExit() {
                    var $binderItemDesc = $('.binder--added-description .editable__input');

                    var inEditMode = $binderItemDesc.filter(function () {
                        return this.value;
                    });

                    if (inEditMode.length) {
                        return 'You have attempted to leave this page. Are you sure?';
                    }
                }
                binders.scrollToTop();
                binders.titleValidation();

                //Disable closing window browser while adding new description
                window.onbeforeunload = confirmExit;
            },

            removeConfirmation: function ($el) {
                binders.$removeEl = $el;
                binders.removeLink = binders.$removeEl.attr('data-remove-link'); // real link
                // binders.removeLink = '/specs/products/acm/widgets/binder-list/templates/demo/remove.json'; // test link

                if (binders.$removeEl.is('.remove-binder-item')) {
                    binders.isItem = true;
                } else {
                    binders.isItem = false;
                }

                $('#removeBinder')
                    .find('.type')
                    .html(binders.isItem ? 'item' : 'binder');
            },
            remove: function () {
                $.ajax({
                    url: binders.removeLink,
                    type: 'GET',
                    success: function (data) {
                        if (data.message) {
                            $('.binder__alerts__msg').html(
                                '<p class="alert-msg--success"><i class="icon-checkmark"></i>' + data.message + '</p>'
                            );

                            if (binders.isItem) {
                                binders.$removeEl.closest('.issue-item').remove();

                                let binderElement = $('.binder__issues__list .issue-item--binder'),
                                    pdfCreate = $('.pdf--create');
                                if (binderElement.length < 1) {
                                    pdfCreate.addClass('disable');
                                }
                            } else {
                                binders.$removeEl.closest('tr').remove();
                            }
                        }

                        if (typeof binders.$removeEl.attr('data-redirectLink') !== 'undefined') {
                            location.href = binders.$removeEl.attr('data-redirectLink');
                        }

                        if ($('.modal-backdrop.in').length) {
                            $('.modal-backdrop.in').remove();
                        }
                    },
                    error: function (data) {
                        if (data.responseJSON.message) {
                            $('.binder__alerts__msg').html(
                                '<p class="alert-msg--warning"><i class="icon-warning-triangle"></i>' +
                                    data.responseJSON.message +
                                    '</p>'
                            );
                        }
                        if ($('.modal-backdrop.in').length) {
                            $('.modal-backdrop.in').remove();
                        }
                    },
                });

                $('#removeBinder').modal('toggle');
                setTimeout(function () {
                    $('.binder__alerts__msg').html('');
                }, 3000);
            },

            responseInvitation: function ($el, isAccept) {
                var link = $el.attr('href'),
                    binderCode = $el.closest('tr').data('code');

                // if (isAccept) {
                //     link = '/specs/products/acm/widgets/binder-list/templates/demo/accept.json'; // test link
                // } else {
                //     link = '/specs/products/acm/widgets/binder-list/templates/demo/reject.json'; // test link
                // }

                $.ajax({
                    url: link,
                    type: 'GET',
                    success: function (data) {
                        if (data) {
                            if (isAccept) {
                                binders.generateBinder(data, true);
                            } else {
                                if (data.message) {
                                    $('.binder__alerts__msg').html(
                                        '<p class="alert-msg--success"><i class="icon-checkmark"></i>' +
                                            data.message +
                                            '</p>'
                                    );
                                }
                            }

                            if ($('.alert-msg--error').length) {
                                $('.alert-msg--error').each(function () {
                                    var $this = $(this),
                                        errorMessageCode = $this.data('binder-code');

                                    if (binderCode === errorMessageCode) {
                                        $this.remove();
                                    }
                                });
                            }

                            $el.closest('tr').remove();
                            if ($('.binder__share-invitation').find('tr').length <= 1) {
                                $('.binder__share-invitation').remove();
                            }
                        }
                    },
                    error: function (data) {
                        if (data.responseJSON && data.responseJSON.message) {
                            $('.binder__alerts__msg').html(
                                '<p class="alert-msg--warning"><i class="icon-warning-triangle"></i>' +
                                    data.responseJSON.message +
                                    '</p>'
                            );
                        }
                    },
                });
            },

            _sort: {
                init: function () {
                    var $sortable = $('#sortable'),
                        sortOptions = {
                            cursor: 'move',
                            handle: '.sortable__toggle',
                        };
                    if ($sortable.is('.disable-sort')) {
                        sortOptions.disabled = true;
                    }
                    $sortable.sortable(sortOptions);
                    $sortable.on('sortstart', function (event, ui) {
                        $(ui.item).find('.sortable__toggle i').toggleClass('icon-moving icon-movable');
                    });

                    $sortable.on('sortstop', function (event, ui) {
                        $(ui.item).find('.sortable__toggle i').toggleClass('icon-moving icon-movable');
                        binders._sort.edit($sortable);
                    });
                },

                edit: function ($sortable) {
                    var code = $sortable.closest('.binder__issues').attr('data-binder-code'),
                        dois = '',
                        $pageNumber = $sortable.closest('.binder__issues').find('.pageNumber'),
                        link,
                        $pageSize = $sortable.closest('.binder__issues').find('.pageSize');

                    $sortable.children('li').each(function (index) {
                        dois += '&item' + index + '=' + $(this).attr('data-doi');
                    });

                    link = '/action/reorderBinderItems?binderCode=' + code + dois; // real link
                    // var link = '/specs/products/acm/widgets/binder-list/templates/demo/order.json'; // test link

                    if ($pageNumber.length) {
                        link += '&pageNumber=' + $pageNumber.val();
                    }
                    if ($pageSize.length) {
                        link += '&pageSize=' + $pageSize.val();
                    }

                    $.ajax({
                        url: link,
                        type: 'GET',
                        success: function (data) {
                            var href, newParamVal;
                            if (data.message) {
                                $('.binder__issues__list .binder__issues__header').prepend(
                                    '<p class="alert-msg--success"><i class="icon-checkmark"></i>' +
                                        data.message +
                                        '</p>'
                                );
                            }

                            href = window.location.href;
                            newParamVal = 'customOrder';
                            if (!UX.pageBody.urlParam.test(href, 'sortBy', newParamVal)) {
                                window.location.href = UX.pageBody.urlParam.replace(href, 'sortBy', newParamVal);
                            }
                        },
                        error: function (data) {
                            if (data.responseJSON.message) {
                                $('.binder__issues__list .binder__issues__header').prepend(
                                    '<p class="alert-msg--warning"><i class="icon-warning-triangle"></i>' +
                                        data.responseJSON.message +
                                        '</p>'
                                );
                            }
                        },
                    });

                    setTimeout(function () {
                        $('.binder__issues__list .binder__issues__header')
                            .find('.alert-msg--success, .alert-msg--warning')
                            .remove();
                    }, 3000);
                },
            },
            clickableRow: function () {
                $(document).on('click', '.clickable-row', function (e) {
                    if (!$(e.target).parents('.tools').length && !$(e.target).hasClass('sr-only-focusable')) {
                        window.location.href = $(this).attr('data-link');
                    }
                });
            },
            scrollToTop: function () {
                const isBinder = document.querySelector('.binder__list');
                const pagination = document.querySelector('.pagination__list');
                const paginationArrows = document.querySelectorAll('.pagination a');

                if (!isBinder) return;

                pagination?.addEventListener('click', () => {
                    window.scrollTo({top: 0, behavior: 'smooth'});
                });
                paginationArrows?.forEach(arrow => {
                    arrow.addEventListener('click', () => {
                        if (!arrow.classList.contains('disabled')) {
                            window.scrollTo({top: 0, behavior: 'smooth'});
                        }
                    });
                });
            },
            // Change Binder Title validation
            titleValidation: function () {
                const editableNameIcon = document.querySelector('.editable__wrapper__name .icon-Icon_Edit');

                editableNameIcon?.addEventListener('click', function () {
                    const editableNameInput = document.querySelector('.editable__wrapper__name .editable__input');
                    const saveButton = document.querySelector('.editable__wrapper__name .editable__save');

                    editableNameInput.addEventListener('keyup', function (e) {
                        const isInputEmpty = e.target.value === '';
                        saveButton.classList.toggle('disabled', isInputEmpty);
                    });
                });
            },
        };

    UX.binders = binders; // add to global namespace
})();
