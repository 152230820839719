(function () {
    var loginInformation = {
        $searchInput: $('.search-input'),
        $searchDropDown: $('.institutions .dropBlock__link'),
        $selectedTabInput: $('#selectedTab'),

        init: function () {
            loginInformation.emptySearchField();

            if (loginInformation.$selectedTabInput.length) {
                loginInformation.selectActiveTab();
            }
        },

        emptySearchField: function () {
            $('body').delegate(loginInformation.$searchDropDown, 'click', function () {
                loginInformation.$searchInput.val('');
                UX.dropdownSearch.Search();
            });
        },

        selectActiveTab: function () {
            var selectedTabName = loginInformation.$selectedTabInput.val();

            if (selectedTabName === 'corporate') {
                UX.tab.setPostbackActiveTab('corporate-login');
            } else if (selectedTabName === 'institutional') {
                UX.tab.setPostbackActiveTab('institutional-login');
            }
        },
    };

    UX.loginInformation = loginInformation;
})();
