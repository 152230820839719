(function () {
    var recommendationForm = {
        init: function () {
            $('body').on('change', '#other', function () {
                if ($(this).prop('checked')) {
                    $('[name="reasonsFreeText"]').prop('required', true).focus();
                } else {
                    $('[name="reasonsFreeText"]').prop('required', false);
                }
            });
        },
    };

    UX.recommendationForm = recommendationForm; // add to global namespace
})();
