(function () {
    var Datamap = require('datamaps');
    var googleMap = {
        googleMapObject: null,
        googleMapTarget: document.getElementById('googleAnalyticsMap'),

        init: function () {
            googleMap.control();
        },

        control: function () {
            googleMap.initMapData();
        },

        initMapData: function () {
            let allCountriesData,
                allCountriesDataArray = [],
                mapDataRows,
                mapDataRowsArray = [];

            if (typeof Datamap.prototype.worldTopo.objects.world.geometries !== 'undefined') {
                allCountriesData = Datamap.prototype.worldTopo.objects.world.geometries;
            }

            if (typeof window.mapDataRowsJs !== 'undefined') {
                mapDataRows = window.mapDataRowsJs;
            }

            $.each(allCountriesData, function (i, country) {
                let countryData = {
                    code: country.id,
                    name: country.properties.name,
                };

                if (typeof countryData !== 'undefined') {
                    allCountriesDataArray.push(countryData);
                }
            });

            $.each(mapDataRows, function (i, countryRow) {
                let countryRowData = {
                    name: countryRow[0],
                    long: countryRow[1],
                    lat: countryRow[2],
                    city: countryRow[3],
                    activeUsers: countryRow[4],
                };

                if (typeof countryRowData !== 'undefined') {
                    mapDataRowsArray.push(countryRowData);
                }
            });

            let googleMapData = {},
                minValue = Number.POSITIVE_INFINITY,
                maxValue = Number.NEGATIVE_INFINITY,
                tmpValue,
                activityScale,
                countryMinActivity,
                countryMaxActivity;

            if ($('#countryMinActivityJs').length) {
                countryMinActivity = $('#countryMinActivityJs').val();
            }

            if ($('#countryMaxActivityJs').length) {
                countryMaxActivity = $('#countryMaxActivityJs').val();
            }

            mapDataRowsArray.forEach(item => {
                const activeUsers = parseInt(item.activeUsers),
                    country = allCountriesDataArray.find(country => country.name.indexOf(item.name) > -1);

                if (!country) {
                    return;
                }

                if (googleMapData[country.code]) {
                    googleMapData[country.code].activeUsers += activeUsers;

                    tmpValue = googleMapData[country.code].activeUsers;
                    if (tmpValue < minValue) minValue = tmpValue;
                    if (tmpValue > maxValue) maxValue = tmpValue;
                } else if (isNaN(country.code)) {
                    googleMapData[country.code] = {
                        name: country.name,
                        code: country.code,
                        activeUsers: activeUsers,
                        fillKey: '',
                        cities: [],
                    };
                }
                if (isNaN(country.code)) {
                    googleMapData[country.code].cities.push({
                        name: item.city,
                        activeUsers: activeUsers,
                        long: item.long,
                        lat: item.lat,
                    });
                }
            });

            activityScale = window.d3.scale
                .linear()
                .domain([minValue, maxValue])
                .range([countryMinActivity, countryMaxActivity]);

            for (let key in googleMapData) {
                googleMapData[key].fillKey = activityScale(googleMapData[key].activeUsers);
            }

            googleMap.renderGoogleMap(googleMapData);
        },

        renderGoogleMap: function (googleMapData) {
            let mapDisplay, hoverCountryBorderColor, countryBorderColor, inactiveCounties;

            if ($('#mapDisplayJs').length) {
                mapDisplay = $('#mapDisplayJs').val();
            }

            if ($('#hoverCountryBorderColorJs').length) {
                hoverCountryBorderColor = $('#hoverCountryBorderColorJs').val();
            }

            if ($('#countryBorderColorJs').length) {
                countryBorderColor = $('#countryBorderColorJs').val();
            }

            if ($('#inactiveCountiesJs').length) {
                inactiveCounties = $('#inactiveCountiesJs').val();
            }

            if (mapDisplay === 'choropleth') {
                googleMap.googleMapObject = new Datamap({
                    element: googleMap.googleMapTarget,
                    scope: 'world',
                    responsive: true,
                    fills: {
                        defaultFill: inactiveCounties,
                    },
                    dataType: 'json',
                    data: googleMapData,
                    geographyConfig: {
                        popupTemplate: function (geo, data) {
                            if (data) {
                                return [
                                    '<div class="hoverinfo"><strong>',
                                    geo.properties.name,
                                    '</strong><div>',
                                    'Active Users: ' + '<strong>' + data.activeUsers + '</strong>',
                                    '</div></div>',
                                ].join('');
                            } else {
                                return [
                                    '<div class="hoverinfo"><strong>',
                                    geo.properties.name,
                                    '</strong><div>',
                                    'No Active Users',
                                    '</div></div>',
                                ].join('');
                            }
                        },
                        highlightFillColor: function (data) {
                            if (data.fillKey) {
                                return data.fillKey;
                            } else {
                                return inactiveCounties;
                            }
                        },
                        borderColor: countryBorderColor,
                        highlightBorderColor: hoverCountryBorderColor,
                        highlightBorderWidth: 1,
                    },
                });
            }

            let googleColorMapData = {};
            for (let key in googleMapData) {
                googleColorMapData[googleMapData[key].code] = googleMapData[key].fillKey;
            }
            googleMap.googleMapObject.updateChoropleth(googleColorMapData);

            $(window).on('resize', function () {
                googleMap.googleMapObject.resize();
            });
        },
    };
    UX.googleMap = googleMap;
})();
