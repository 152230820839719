(function () {
    var authorPopup = {
        $alertPopup: null,

        init: function () {
            authorPopup.$alertPopup = $('.alert-popup');
            authorPopup.control();
        },

        control: function () {
            //Close Popup
            authorPopup.$alertPopup.find('.alert-popup__close').on('click', function () {
                authorPopup.$alertPopup.css('cssText', 'display:  none !important;');
            });
        },
    };

    UX.authorPopup = authorPopup;
})();
