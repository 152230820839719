import 'ui-core/components/side-bar-sections/js/side-bar-sections.js';

UX.sidebarSections.initSections = function () {
    var appendTarget;
    var $sectionsContainer = $('.sections-block.scroll-to-target');
    var isArticle = $('.article__sections').length;
    var navigatorClasses = 'rlist sections-navigator';

    if (isArticle) navigatorClasses = navigatorClasses + ' custom-scrollbar';

    $sectionsContainer.append('<ul class="' + navigatorClasses + '"></ul>');
    //TODO cleanup
    UX.sidebarSections.$scrollPoints.each(function () {
        appendTarget = '.sections-navigator';

        // this condition is for the grandparent of all nested elements
        if (
            $(this).siblings().is('section') &&
            !$(this).parent().siblings().hasClass('section__title') &&
            $(this).siblings().children().hasClass('section__title')
        ) {
            UX.sidebarSections.$block.find(appendTarget).append(UX.sidebarSections.get.htmlParentSection($(this)));
        }

        // this condition is for elements that are parents and children
        else if (
            $(this).siblings().is('section') &&
            $(this).siblings('section').find('.section__title').length > 0 &&
            $(this).parent().siblings().hasClass('section__title')
        ) {
            if (UX.sidebarSections.$block.find('.sections-navigator li').last().find('.accordion__content') > 0) {
                UX.sidebarSections.$block
                    .find('.sections-navigator li')
                    .last()
                    .find('.accordion__content')
                    .append(UX.sidebarSections.get.htmlParentSection($(this)));
            } else {
                $('a[href="#' + $(this).parent().parent().find('.section__title').first().attr('id') + '"]')
                    .siblings('.accordion__content')
                    .append(UX.sidebarSections.get.htmlParentSection($(this)));
            }
        }

        // this condition is for element that are children but not parents
        else if (
            (!$(this).siblings().is('section') && $(this).parent().siblings().hasClass('section__title')) ||
            ($(this).siblings().is('section') &&
                $(this).siblings('section').find('.section__title').length === 0 &&
                $(this).parent().siblings().hasClass('section__title'))
        ) {
            $('a[href="#' + $(this).parent().parent().find('.section__title').first().attr('id') + '"]')
                .siblings('.accordion__content')
                .append(UX.sidebarSections.get.htmlSection($(this)));
        }

        // this condition is for element who are not parents nor children
        else {
            UX.sidebarSections.$block.find(appendTarget).append(UX.sidebarSections.get.htmlSection($(this))); // this condition is for elements that are nether parents not children
        }
    });
    if ($('.parentAccordion').length) {
        UX.accordion.init();
    }

    $('.sections-navigator li').each(function () {
        if ($(this).find('i.parentAccordion').length !== 0) {
            $(this).addClass('hasNestedMenu');
        }
    });
    if ($('.custom-scrollbar').length) {
        $('.custom-scrollbar').scrollBox();
    }
};

UX.sidebarSections.get.htmlParentSection = function ($this) {
    return (
        '<li><a title="' +
        $this.text() +
        '" href="#' +
        $this.attr('id') +
        '">' +
        $this.html() +
        '</a><i aria-hidden="true" aria-expanded="false"  class="accordion__control icon-section_arrow_d pull-right parentAccordion"></i><ul class="accordion__content"></ul></li>'
    );
};

UX.sidebarSections.controller = function () {
    var $window = $(window),
        $body = $('body'),
        isMobile; /*eslint-disable-line*/
    $body.on('click', '.sections-block a', function () {
        UX.sidebarSections.$block = $('.sections-block');
        UX.progressBar.lock = false;
        UX.sidebarSections.scrollingToTarget = true;
        UX.sidebarSections.scrollToTarget($($(this).attr('href')));
        UX.sidebarSections.$block.find('a').removeClass('active');
        $(this).addClass('active');
        $(this)
            .parents('.hasNestedMenu')
            .each(function () {
                $(this).children(':first').addClass('active');
            });
    });

    $(document).on(UX.sidebarSections.vPort + '-on', function () {
        isMobile = true;
    });

    $(document).on(UX.sidebarSections.vPort + '-off', function () {
        isMobile = false;
    });

    $window.on('scroll', function () {
        var scrollingPoints = UX.sidebarSections.get.scrollingSectionPoints(),
            id = scrollingPoints.last().attr('id');
        UX.sidebarSections.$block = $('.sections-block');

        //if it on the top of the page
        if ($(window).scrollTop() === 0) {
            UX.sidebarSections.$block.find('a').removeClass('active');
        }
        //if on the bottom of the bage
        else if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
            UX.sidebarSections.$block.find('a').removeClass('active');
            UX.sidebarSections.$block.find('a[href="#' + id + '"]').addClass('active');
        } else {
            if (!UX.sidebarSections.scrollingToTarget) {
                scrollingPoints.each(function () {
                    if (
                        $(window).scrollTop() >=
                        $(this).offset().top -
                            UX.sidebarSections.get.fixedpageElementsHeight() -
                            $(this).outerHeight() -
                            15
                    ) {
                        id = $(this).attr('id');
                        UX.sidebarSections.$block.find('a').removeClass('active');
                        UX.sidebarSections.$block.find('a[href="#' + id + '"]').addClass('active');
                    }
                    $(UX.sidebarSections.$block.find('a[href="#' + id + '"]'))
                        .parents('.hasNestedMenu')
                        .each(function () {
                            $(this).children(':first').addClass('active');
                        });
                });
            }
        }
    });
};
