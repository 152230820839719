(function () {
    var activeLink = {
        init: function () {
            activeLink.addActiveClass();
            activeLink.highlightSubInProfile();
        },
        addActiveClass: function () {
            $('.sub-nav a, .add-active-link a').each(function () {
                if (
                    typeof $(this).attr('href') !== 'undefined' &&
                    $(this).attr('href').split('?')[0] === location.pathname
                ) {
                    $(this).addClass('active-item');
                    if ($(this).hasClass('sub-nav__item')) {
                        $(this).closest('.menu-parent').addClass('active-parent');
                    }
                }
            });
        },
        highlightSubInProfile: function () {
            var $profileMenu = $('.profile-menu'),
                firstItemTarget;
            if ($profileMenu.length && $profileMenu.find('.active').length) {
                if (typeof $profileMenu.find('a').attr('href') !== 'undefined') {
                    firstItemTarget = $profileMenu.find('a').attr('href').split('?')[0];
                    $('.sub-nav a[href^="' + firstItemTarget + '"]').addClass('active-item');
                }
            }
        },
    };
    UX.activeLink = activeLink; // add to global namespace
})();
