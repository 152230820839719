(function (UX) {
    UX.alerts = UX.alerts || {
        init: function () {
            UX.alerts.autoHide();
        },
        settings: {
            autoHide: {
                selector: '[data-auto-hide="alert-popup"]',
                classVisible: 'fadeInUp',
                timeout: 3000,
            },
        },
        autoHide: function () {
            var elements = $(UX.alerts.settings.autoHide.selector);
            elements.each(function () {
                var $this = $(this);

                /// Hide function/animation
                var hide = function () {
                    $this.slideUp('fast', function () {
                        $this.removeClass(UX.alerts.settings.autoHide.classVisible);
                    });
                };

                /// Save timeout for each window
                var timeout = setTimeout(hide, UX.alerts.settings.autoHide.timeout);

                /// Restart on mouse over - to stay visible if user has a pointer over the window
                $this
                    .on('mouseenter', function () {
                        clearTimeout(timeout);
                    })
                    .on('mouseleave', function () {
                        /// add new timeout if the window is still visible
                        if ($this.hasClass(UX.alerts.settings.autoHide.classVisible))
                            timeout = setTimeout(hide, UX.alerts.settings.autoHide.timeout);
                    });

                /// Close on click X
                $this.find('[data-close]').click(function () {
                    clearTimeout(timeout);
                    hide();
                });
            });
        },
    };
})(UX);
