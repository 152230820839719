(function () {
    var awardWinners = {
        init: function () {
            if ($('.award-winners__list').length && typeof dataAwardAjax !== 'undefined') {/*eslint-disable-line*/
                UX.loadLazyScroll.init($('.award-winners'), dataAwardAjax, $('.award-winners__wrapper-list'));/*eslint-disable-line*/
            }
        },
    };
    UX.awardWinners = awardWinners;
})();
