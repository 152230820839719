(function () {
    var truncLongBreadcrumbs = {
        truncLength: 170,
        $breadcrumbsElem: $('.article__breadcrumbs'),

        init: function () {
            truncLongBreadcrumbs.CheckTruncation();
        },

        CheckTruncation: function () {
            //if the breadcrumbs is more than two lines
            if (
                parseInt(truncLongBreadcrumbs.$breadcrumbsElem.height()) /
                    parseInt(truncLongBreadcrumbs.$breadcrumbsElem.css('line-height')) >=
                2
            ) {
                truncLongBreadcrumbs.truncBreadcrumb();
            }
        },

        truncBreadcrumb: function () {
            //trunc the longest title from the breadcrumbs
            $('.article__breadcrumbs > a').each(function () {
                var $this = $(this);
                if ($this.html().length > truncLongBreadcrumbs.truncLength) {
                    $this
                        .attr('title', $this.html())
                        .addClass('truncate-text')
                        .attr('data-lines', '1')
                        .attr('data-more', 'false');
                    UX.genericTruncate.truncate.text($this);
                    //to break the loop
                    return false;
                }
            });
        },
    };

    UX.truncLongBreadcrumbs = truncLongBreadcrumbs;
})();
