(function () {
    var recentIssuesProceedings = {
        $loadMoreBtn: $('.loadMoreBtn'),
        $mainContainer: $('.recent-issues-proceedings__carousel'),

        init: function () {
            var windowWidth = $(window).width();

            recentIssuesProceedings.loadMore();
            if (windowWidth <= 531) {
                $('.recent-issues-proceedings__title').removeClass('truncate-text');
            }
        },

        loadMore: function () {
            $('body').delegate(recentIssuesProceedings.$loadMoreBtn, 'click', function () {
                var loadedItems = recentIssuesProceedings.$mainContainer.find(
                        '.recent-issues-proceedings__item-group.loaded'
                    ).length,
                    totalItems = recentIssuesProceedings.$mainContainer.find(
                        '.recent-issues-proceedings__item-group'
                    ).length;

                recentIssuesProceedings.$mainContainer
                    .find('.recent-issues-proceedings__item-group.loaded')
                    .next()
                    .addClass('loaded');
                if (loadedItems === totalItems) {
                    recentIssuesProceedings.$loadMoreBtn.hide();
                }
            });
        },
    };

    UX.recentIssuesProceedings = recentIssuesProceedings;
})();
