(function () {
    var people = {
        $quickViewSlideDownTrigger: $('.people-list-container .people-button-group .quick-view'),
        $people_list: $('.people__people-list'),
        $closeSlideUp: '.quick-view-slide-down__close-btn',
        widgetId: $('#widgetID').val(),
        pbContext: $('meta[name=pbContext]').attr('content'),
        $allSlideDowns: '.quick-view-slide-down',
        currentActiveSlideBtn: null,

        init: function () {
            people.control();
        },

        control: function () {
            people.renderQuickViewContainers();
            people.renderQuickViewSlideDown();
            people.handleKeyDown();
        },

        renderQuickViewContainers: function () {
            var windowWidth = $(window).width();
            var BreakNumber = 3;
            var peopleLength = people.$people_list.length;
            var QuickViewContainer = '<li class="quick-view-slide-down"><div class="slide-down-content"></div></li>';

            if ($('.quick-view-slide-down').length > 0) {
                $('.quick-view-slide-down').remove();
            }

            //Append the quick view containers
            if (windowWidth < UX.grid.screenLg && windowWidth >= UX.grid.screenMd) {
                BreakNumber = 2;
                $('.people__people-list:nth-child(2n)').after(QuickViewContainer);
            } else if (windowWidth < UX.grid.screenMd) {
                BreakNumber = 1;
                $('.people__people-list').after(QuickViewContainer);
            } else {
                $('.people__people-list:nth-child(3n)').after(QuickViewContainer);
            }

            if (
                !(
                    Math.floor(peopleLength / BreakNumber) === peopleLength / BreakNumber &&
                    $.isNumeric(peopleLength / BreakNumber)
                )
            ) {
                $('.people__people-list:last-child').after(QuickViewContainer);
            }
        },

        renderQuickViewSlideDown: function () {
            //detect window resize in width only
            var windowWidth = $(window).width();
            //Handle the Quick View button
            var slidedCount = 1;

            $(window).resize(function () {
                if ($(this).width() !== windowWidth) {
                    people.renderQuickViewContainers();
                    windowWidth = $(this).width();
                }
            });

            // Handle click on Quick View button
            people.$quickViewSlideDownTrigger.click(function () {
                var $this = $(this);
                people.currentActiveSlideBtn = $this;
                var $slideDown = $this.closest('li').nextAll('.quick-view-slide-down').first();
                var peopleDoi = $this.data('doi');
                var quickViewUrl = '/pb/widgets/ux3/People/ContribDetails';
                //var quickViewUrl = '/specs/products/acm/widgets/people/templates/data/quick-view.html';

                $this.addClass('loading-data');

                // if the slideDown is not slided yet slide it
                if (!$this.hasClass('opened')) {
                    $.ajax({
                        method: 'GET',
                        url: quickViewUrl,
                        data: {
                            pbContext: people.pbContext,
                            widgetId: people.widgetId,
                            doi: peopleDoi,
                        },
                        success: function (data) {
                            var dataSlided;
                            if (data && data.length) {
                                dataSlided = $slideDown.attr('data-slided');
                                people.$quickViewSlideDownTrigger.removeClass('opened');
                                $slideDown
                                    .find('.slide-down-content')
                                    .html(
                                        data +
                                            '<button class="quick-view-slide-down__close-btn" aria-label="close"><i aria-hidden="true" class="icon-close_thin"></i></button>'
                                    );

                                if (typeof dataSlided !== typeof undefined && dataSlided !== false) {
                                    //Open Quick View on the same row

                                    slidedCount = parseInt($slideDown.attr('data-slided'));
                                    slidedCount++;
                                    $slideDown.attr('data-slided', slidedCount);

                                    $(people.$allSlideDowns).fadeOut(250);
                                    $slideDown.fadeIn(250);
                                } else {
                                    //Open Quick View on the another row

                                    $(people.$allSlideDowns).slideUp(350);
                                    $(people.$allSlideDowns).removeAttr('data-slided');
                                    slidedCount = 1;
                                    $slideDown.attr('data-slided', slidedCount);
                                    $slideDown.slideDown(350);
                                }

                                $this.addClass('opened');
                                $this.removeClass('loading-data');

                                //scroll to the slided Quick View
                                setTimeout(function () {
                                    $('html, body').animate(
                                        {
                                            scrollTop: $this.offset().top - 125,
                                        },
                                        500
                                    );
                                    people.focusinQuickView($slideDown);
                                }, 400);
                            }
                        },
                        error: function (err) {},
                    });
                } else {
                    // if it's already slided close it
                    $slideDown.slideUp(350);
                    $(people.$allSlideDowns).removeAttr('data-slided');
                    $this.removeClass('opened');
                    $this.removeClass('loading-data');
                    people.$quickViewSlideDownTrigger.removeClass('opened');
                }
            });

            //click on close icon x
            $('body').delegate(people.$closeSlideUp, 'click', function () {
                let $this = $(this);
                $this.parent().parent().slideUp(350);
                $(people.$allSlideDowns).removeAttr('data-slided');
                people.$quickViewSlideDownTrigger.removeClass('opened');
                people.focusOutQuickView();
            });
        },

        handleKeyDown: function () {
            $('.slide-down-content').on('keydown', function (event) {
                if ((event.key === 'Tab' || event.keyCode === 9) && !event.shiftKey) {
                    const focusedElement = document.activeElement;
                    if (focusedElement.classList.contains('quick-view-slide-down__close-btn')) {
                        focusedElement.click();
                    }
                }
            });
        },

        focusinQuickView: function (container) {
            const focusableElements = container?.find(
                'button, a[href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
            );
            focusableElements?.first().focus();
        },

        focusOutQuickView: function () {
            people.currentActiveSlideBtn.focus();
        },
    };

    UX.people = people;
})();
