(function () {
    var contribProfile = {
        $form: $('#InstitutionAdmin_AdminManager'),
        $verifyEmail: $('#verifyEmail'),
        $associatedUserEnable: $('.associatedUser .associatedUser__edit'),
        $associatedUserForm: $('.associatedUser .associatedUser__form'),
        $associatedUserSearch: $('.associatedUser .search-user'),
        $associatedUserInfo: $('.associatedUser .associatedUser__info'),
        $associatedUserInfoData: $('.associatedUser .associatedUser__info .user-info'),
        $associatedUserUpdateBtn: $('.associatedUser .associatedUser__info .changeAssociated'),
        $associatedUserCancelBtn: $('.associatedUser .associatedUser__info .cancel'),
        $associatedUserCheckboxesContainer: $('.associatedUser .associatedUser__info .update-check'),
        $associatedUserCheckboxes: $('.associatedUser .associatedUser__info .update-check input'),
        $associatedUserValidation: $('.associatedUser .associatedUser__info .messages .icon-close_thin'),
        $enableFormBtn: $('#InstitutionAdmin_AdminManager .enable-form-btn'),
        $deleteFileBtn: $('#InstitutionAdmin_AdminManager .deletePhoto'),
        $deleteFileBtnContainer: $('#InstitutionAdmin_AdminManager .deletePhotoBtn'),

        init: function () {
            contribProfile.control();
        },

        control: function () {
            contribProfile.submissionProcess();
            contribProfile.addOtherUserOption();
            contribProfile.addOtherUserOptions();
            contribProfile.associatedUserActions();
            contribProfile.deleteBtnControl();
            contribProfile.deletePhotoAction();
            contribProfile.confirmPopup();
            contribProfile.closePopup();
        },

        addOtherUserOption: function () {
            // add option "Other" to the author's name select
            var $authorNameSelect;
            var otherAuthorNameText;

            $('select[name="DISPLAY_AS"]').addClass('authorNameSelect');
            $authorNameSelect = $('.authorNameSelect');
            $authorNameSelect.append('<option value="other">Other</option>');

            // apend the author's name text field if "other" is selected.
            $authorNameSelect.on('change', function () {
                var $this = $(this);

                if ($this.val() === 'other') {
                    if ($('.authorNameText').length === 0) {
                        otherAuthorNameText =
                            '<div class="input-group__field-container authorNameText" style="margin-top: 8px">' +
                            '<input id="authorName" type="text" name="DISPLAY_AS" placeholder="Please type author’s name" class="form-control" value="" data-validation="text" _vkenabled="true">' +
                            '<span class="input-group__right-icon field-error-icon">' +
                            '<i aria-hidden="true" class="icon-notice"></i>' +
                            '</span>' +
                            '</div><div class="label error field-error-label"><label for="DISPLAY_AS">You can\'t leave this field empty</label></div>';

                        $this.parent().after(otherAuthorNameText);
                        $authorNameSelect.attr('name', 'DISPLAY_AS_OLD');
                        UX.fieldsCtrl.additionalControls();
                        $('#authorName').focus();
                    }
                } else {
                    $('.authorNameText').remove();
                    $authorNameSelect.attr('name', 'DISPLAY_AS');
                }
            });
        },
        addOtherUserOptions: function () {
            // add option "Other" to the author's name select
            var $orcidSelect;
            var otherOrcidText;

            $('select[name="ORCID"]').addClass('orcidSelect');
            $orcidSelect = $('.orcidSelect');
            $orcidSelect.append('<option value="other">Other</option>');

            // apend the author's name text field if "other" is selected.
            $orcidSelect.on('change', function () {
                var $this = $(this);

                if ($this.val() === 'other') {
                    if ($('.orcidText').length === 0) {
                        otherOrcidText =
                            '<div class="input-group__field-container orcidText" style="margin-top: 8px">' +
                            '<input id="authorOrcid" type="text" name="ORCID" placeholder="XXXX-XXXX-XXXX-XXXX" class="form-control" value="" data-validation="text" _vkenabled="true">' +
                            '<span class="input-group__right-icon field-error-icon">' +
                            '<i aria-hidden="true" class="icon-notice"></i>' +
                            '</span>' +
                            '</div><div class="label error field-error-label"><label for="ORCID">You can\'t leave this field empty</label></div>';

                        $this.parent().after(otherOrcidText);
                        $orcidSelect.attr('name', 'ORCID_OLD');
                        UX.fieldsCtrl.additionalControls();
                        $('#authorOrcid').focus();
                    }
                } else {
                    $('.orcidText').remove();
                    $orcidSelect.attr('name', 'ORCID');
                }
            });
        },
        associatedUserActions: function () {
            //Enable associated User form - "Change User" button
            contribProfile.$associatedUserEnable.on('click', function () {
                contribProfile.$associatedUserForm.removeClass('disabled');
                contribProfile.$form.find('.InstitutionAdminForm').addClass('disabled');
                contribProfile.$form.find('button[type="submit"]').addClass('disabled');
                contribProfile.$associatedUserEnable.fadeOut(250);
            });

            //Search for an email address Verify Email
            contribProfile.$associatedUserSearch.on('click', function (e) {
                var emailAddress, verifyEmailUrl;
                e.preventDefault();
                emailAddress = contribProfile.$verifyEmail.val();

                if ($.trim(emailAddress) !== '') {
                    contribProfile.$associatedUserForm
                        .find('.input-group')
                        .removeClass('input-group__field-container--error');

                    verifyEmailUrl = '/pb/widgets/ux3/ContributorProfile/verifyEmail/' + emailAddress + '/';
                    // var verifyEmailUrl = '/specs/products/acm/widgets/contrib-profile/templates/data/verify-valid.txt';
                    verifyEmailUrl = encodeURI(verifyEmailUrl);

                    $.ajax({
                        method: 'GET',
                        url: verifyEmailUrl,
                        success: function (data) {
                            var jsonData = JSON.parse(data);

                            if (jsonData.exist) {
                                contribProfile.$associatedUserForm.find('.invalid').fadeOut(250);
                                contribProfile.$associatedUserInfo.find('.alert-msg--error').fadeOut(250);

                                contribProfile.$associatedUserForm.find('.valid').fadeIn(250);
                                contribProfile.$associatedUserInfo.find('.alert-msg--success').fadeIn(250);

                                contribProfile.$associatedUserUpdateBtn.removeClass('disabled');
                                contribProfile.$associatedUserCheckboxesContainer.removeClass('disabled');

                                contribProfile.$associatedUserCheckboxesContainer
                                    .find('#updateNotification')
                                    .prop('checked', true);

                                contribProfile.$associatedUserInfo.fadeIn(250);
                                contribProfile.$associatedUserInfoData.find('.username').html(jsonData.name);
                                contribProfile.$associatedUserInfoData.find('.email').html(jsonData.email);
                            } else {
                                contribProfile.$associatedUserForm.find('.valid').fadeOut(250);
                                contribProfile.$associatedUserInfo.find('.alert-msg--success').fadeOut(250);

                                contribProfile.$associatedUserForm.find('.invalid').fadeIn(250);
                                contribProfile.$associatedUserInfo.find('.alert-msg--error').fadeIn(250);

                                contribProfile.$associatedUserCheckboxes.prop('checked', false);

                                contribProfile.$associatedUserUpdateBtn.addClass('disabled');
                                contribProfile.$associatedUserCheckboxesContainer.addClass('disabled');

                                contribProfile.$associatedUserInfo.fadeIn(250);
                                contribProfile.$associatedUserInfoData.find('.username').html('N/A');
                                contribProfile.$associatedUserInfoData.find('.email').html('N/A');

                                contribProfile.$form.find('.InstitutionAdminForm').addClass('disabled');
                                contribProfile.$form.find('button[type="submit"]').addClass('disabled');
                            }
                        },
                        error: function (err) {},
                    });
                } else {
                    contribProfile.$associatedUserForm
                        .find('.input-group')
                        .addClass('input-group__field-container--error');
                    contribProfile.$associatedUserInfo.fadeOut(250);
                    contribProfile.$verifyEmail.val('');
                }
            });

            //"Change associated User" button
            contribProfile.$associatedUserUpdateBtn.on('click', function () {
                var verifiedEmail = contribProfile.$verifyEmail.val();

                if (
                    contribProfile.$associatedUserCheckboxesContainer.find('#updateNotification').prop('checked') ===
                    true
                ) {
                    $('#InstitutionAdmin_AdminManager input[name="notificationEmail"]').val(verifiedEmail);
                }
                if (
                    contribProfile.$associatedUserCheckboxesContainer.find('#updateMainEmail').prop('checked') === true
                ) {
                    $('#InstitutionAdmin_AdminManager input[name="EMAIL"]').val(verifiedEmail);
                }

                $('#ownerEmail').remove();
                $('.current-user__email').text(' ' + verifiedEmail);
                contribProfile.$form.prepend(
                    '<input type="hidden" id="ownerEmail" name="ownerEmail" value="' + verifiedEmail + '">'
                );

                contribProfile.$form.find('.InstitutionAdminForm').removeClass('disabled');
                contribProfile.$form.find('button[type="submit"]').removeClass('disabled');
            });

            //"Cancel" button
            contribProfile.$associatedUserCancelBtn.on('click', function () {
                contribProfile.$verifyEmail.val('');
                contribProfile.$associatedUserForm.find('.invalid').hide();
                contribProfile.$associatedUserInfo.find('.alert-msg--error').hide();
                contribProfile.$associatedUserForm.find('.valid').hide();
                contribProfile.$associatedUserInfo.find('.alert-msg--success').hide();
                contribProfile.$associatedUserInfo.hide();
                contribProfile.$associatedUserForm
                    .find('.input-group')
                    .removeClass('input-group__field-container--error');
                contribProfile.$associatedUserEnable.show();
                contribProfile.$associatedUserForm.addClass('disabled');
                contribProfile.$form.find('.InstitutionAdminForm').removeClass('disabled');
                contribProfile.$form.find('button[type="submit"]').removeClass('disabled');
                $('#ownerEmail').remove();
            });

            // disable "Change associated User" button when no checkboxes is checked
            contribProfile.$associatedUserCheckboxes.on('change', function () {
                if (contribProfile.$associatedUserCheckboxes.is(':checked')) {
                    contribProfile.$associatedUserUpdateBtn.removeClass('disabled');
                } else {
                    contribProfile.$associatedUserUpdateBtn.addClass('disabled');
                }
            });

            // remove validation message
            contribProfile.$associatedUserValidation.on('click', function () {
                $(this).parent().fadeOut(250);
            });
        },

        submissionProcess: function () {
            contribProfile.$form.on('submit', function () {
                var $formProcessing, actionValue;
                if (
                    $(this).find('input[name="photo_uploaded"]').val() !== '' &&
                    $(this).find('.input-group__field-container--error').length === 0 &&
                    $(this).find('.photoError').length === 0
                ) {
                    $formProcessing = $('#form-processing');
                    actionValue = $(this).find('button[name="action"]').val();

                    contribProfile.$form.find('.form__actions').addClass('disabled');

                    if (actionValue === 'claim') {
                        $formProcessing.find('h2').html('Processing your claim request...');
                    }
                    $formProcessing.modal({
                        backdrop: 'static',
                        keyboard: false,
                    });

                    $(document).on('keypress keydown keyup', function (e) {
                        if (e.keyCode === 9 || e.keyCode === 27) {
                            e.preventDefault();
                        }
                    });
                }
                return true;
            });
        },

        deleteBtnControl: function () {
            var photoName;
            if ($('#photoUrl').length) {
                contribProfile.$deleteFileBtnContainer.css('display', 'inline-block');
                photoName = contribProfile.$form.find('input[name="photo"]').val();
                contribProfile.$form.find('.jcf-file .jcf-fake-input').html(photoName);
            }

            contribProfile.$form.find('input[name="photo_uploaded"]').on('change', function () {
                if ($(this).val()) {
                    contribProfile.$deleteFileBtnContainer.css('display', 'inline-block');
                    contribProfile.validateFile($(this));
                }
            });
        },

        deletePhotoAction: function () {
            contribProfile.$deleteFileBtn.on('click', function () {
                var $errorLabel;
                contribProfile.$form.find('input[name="photo_uploaded"]').val('');
                contribProfile.$form.find('input[name="photo"]').val('');
                contribProfile.$form
                    .find('.previewPhoto > img')
                    .attr('src', '/specs/products/acm/releasedAssets/images/Author-56.svg');
                contribProfile.$form.find('.jcf-file .jcf-fake-input').html('No file chosen');
                contribProfile.$form.find('.previewPhoto').parent().removeClass('input-group__field-container--error');
                $errorLabel = contribProfile.$form.find('.previewPhoto').parent().next();
                if ($errorLabel.hasClass('field-error-label')) {
                    $errorLabel.remove();
                }
                contribProfile.$deleteFileBtnContainer.hide();
                contribProfile.$form.find('.form__actions').removeClass('disabled');
                $('.photoError').remove();
            });
        },

        validateFile: function (fileElement) {
            let fileValue = fileElement.val(),
                errorAppendPosition = fileElement.closest('.input-group');

            switch (fileValue.substring(fileValue.lastIndexOf('.') + 1).toLowerCase()) {
                case 'gif':
                case 'jpg':
                case 'jpeg':
                case 'png':
                    $('.photoError').remove();
                    contribProfile.$form.find('.form__actions').removeClass('disabled');
                    break;
                default:
                    $(this).val('');
                    if (!$('.photoError').length) {
                        errorAppendPosition.append(
                            '<div class="label error field-error-label photoError"><label for="photo">Only gif, jpg, png file formats are allowed.</label></div>'
                        );
                    }
                    contribProfile.$form.find('.form__actions').addClass('disabled');
                    break;
            }
        },
        confirmPopup: function () {
            const rejectBtn = document.querySelector('.submit-reject');
            const contribPopup = document.querySelector('.contrib-profile__popup');
            const doiList = rejectBtn?.getAttribute('data-dois');
            rejectBtn?.addEventListener('click', () => {
                if (doiList !== '[]') {
                    contribPopup?.classList.add('fadeInUp');
                } else {
                    const $formConfirm = $('#form-confirm');
                    $formConfirm.modal({
                        backdrop: 'static',
                        keyboard: false,
                    });
                }
            });
        },

        closePopup: function () {
            const contribPopup = document.querySelector('.contrib-profile__popup');
            const cancelPopup = document.querySelectorAll(
                '.contrib-profile__popup .icon-cancel-bold, .contrib-profile__popup .btn'
            );
            cancelPopup.forEach(elm => {
                elm.addEventListener('click', () => {
                    contribPopup.classList.remove('fadeInUp');
                });
            });
        },
    };

    UX.contribProfile = contribProfile;
})();
