(function () {
    var $document = $(document),
        $body = $('body');

    var citation = {
        citationBox: null,

        init: function () {
            citation.control();
        },

        control: function () {
            var windowWidth = $(window).width();

            if (windowWidth >= UX.grid.screenSm) {
                citation.infoAuthorPopupInit();
            }

            if (windowWidth < UX.grid.screenSm) {
                citation.goToAuthorsPill();
                citation.hideSubNavWhenScroll();
            }
        },

        goToAuthorsPill: function () {
            $document.on('click', '.loa__item', function () {
                $('.loa__link.w-slide__btn').trigger('click');
            });
        },

        infoAuthorPopupInit: function () {
            $document.on('click', '.loa__item', citation.infoAuthorUsingClick);
            $document.on('keydown', '.loa__item', citation.infoAuthorUsingEscape);

            $document.on('focus', '*', function (e) {
                if (!$(e.target).is('.author-show-info-focus *')) {
                    citation.removeFocusAuthor();
                }
            });

            $body.on('click', function (event) {
                if (!$(event.target).hasClass('author-show-info-focus')) {
                    citation.removeFocusAuthor();
                }
            });
        },

        infoAuthorUsingClick: function () {
            let $this = $(this);
            var authorData = $this.find('.loa__author-info .author-data').html(),
                $targetPopup = $this.find('.author-info .author-info__header');
            citation.removeFocusAuthor();
            if ($targetPopup.html() === '') {
                $targetPopup.append(authorData);
            }
            $this.addClass('author-show-info-focus');
            $this.find('> a:first-child').attr('aria-expanded', 'true');
        },
        infoAuthorUsingEscape: function (event) {
            if (event.which === 27) {
                event.preventDefault();
                citation.removeFocusAuthor();
            }
        },
        removeFocusAuthor: function () {
            let activeFocusAuthor = $('.author-show-info-focus');
            activeFocusAuthor.find('a:first-child').attr('aria-expanded', 'false');
            activeFocusAuthor.removeClass('author-show-info-focus');
        },

        hideSubNavWhenScroll: function () {
            var $subNav = $('.sub-nav'),
                $coolBar = $('.coolBar'),
                coolBarTop = $coolBar.offset().top - 50;

            if ($subNav.length && $coolBar.length) {
                $(window).scroll(function () {
                    if ($(this).scrollTop() >= coolBarTop) {
                        $subNav.fadeOut(150);
                    } else {
                        $subNav.fadeIn(150);
                    }
                });
            }
        },
    };

    UX.citation = citation; // add to global namespace
})();
