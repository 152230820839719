import 'ui-core/components/tabs/js/tabs.js';

(function (UX) {
    const tab = UX.tab;

    tab.preselectTab = function () {
        // The preselectTab function should be cleanup
        // Its better to use query string in url instead session storage for select active tab
        // We should depend on data-target always instead of href because href may contain url not tab-pane selector
        // selection of active tab should tested on nested tabs and multi tabs on page

        $('.tab').each(function () {
            //the rest of this code doesnt seem to support multiple tabs on the page.. but this does
            let _this = $(this),
                $activeTab = _this.find('.tab__nav').find('li.active a, li.active button'),
                activeTab = tab.getPaneTarget($activeTab),
                sessionActiveTab = null,
                hash,
                triggerTarget,
                reExp,
                activeParameter;

            if (_this.data('js-preselect') === false)
                //If you don't need js to select the active tab
                return true;

            if (!activeTab && sessionStorage.activeTab) {
                // check "sessionStorage.activeTab" is solution for LIT-189292
                activeTab = sessionStorage.getItem('activeTab');
                sessionActiveTab = activeTab;
            }

            // Checking if activeTab contains hash, if not it is not 'tab'
            hash = activeTab ? activeTab.match(/#/) : null;
            if (activeTab && (!hash || hash.index !== 0)) return;

            if (_this.find(activeTab).length === 0) {
                activeTab = null;
            } else if (sessionActiveTab) {
                sessionStorage.setItem('activeTab', '');
            }

            if (location.search !== '') {
                reExp = /activeTab=\b[\w-]+$/g;

                if (reExp.test(location.search)) {
                    activeParameter = location.search.match(reExp)[0];
                    activeTab = '#' + activeParameter.split('=')[1];
                }
            }

            let $firstItem = _this.find('.tab__nav').find('li:first-of-type .tab__nav__item__link');
            if (!activeTab) activeTab = tab.getPaneTarget($firstItem);

            if ((activeTab && activeTab.split('#').length < 2) || activeTab === '') return true;

            triggerTarget = $("[data-target='" + activeTab + "'], [href='" + activeTab + "']");

            if (!triggerTarget.closest('.w-slide__btn').length) {
                // @TODO add condition to apply on while on mobile only, think: is the closest() method really best for the job?
                triggerTarget.click();
                _this.find(activeTab).addClass('active'); // in some cases to display tab content the .active class has to tiggered. Known case: alerts management in profile, when directed to a specific tab.
            }
        });
    };

    tab.on.select.external = function ($elem) {
        let target = $elem.attr('href'), // get target id
            temp,
            parentTab,
            animateScroll;
        target = target.split(' ')[0]; //LIT-162636

        tab.$toggle = '#' + $elem.data('tab'); //get target pane
        tab.$li = $('.tab__nav [href="' + tab.$toggle + '"]'); // get target tabs nav
        temp = target.split('#')[1];
        parentTab = $(tab.$toggle).closest('.tab');

        animateScroll = function () {
            tab.$content = $(tab.$toggle).parents('.tab__content');
            if (!tab.$content.length && $elem.is('[data-slide-target]') && $elem.hasClass('slide-active'))
                tab.$content = $('.w-slide__content');

            if (parentTab.hasClass('tab--res')) {
                tab.isSlide = parentTab.hasClass('tab--slide');
                if (tab.isSlide) {
                    tab.set.target($('.w-slide__content'), target, temp);
                    if (tab.$target.length) {
                        tab.animate.scroll($('.w-slide__content'), tab.$target);
                        tab.$target.attr('tab-index', '1');
                        tab.$target.focus();
                    }
                }
                if (target === '#') {
                    setTimeout(function () {
                        $('.article-chapter-history-list')[0].scrollIntoView({behavior: 'smooth', block: 'center'});
                    }, 500);
                }
            } else {
                if (target !== '#') {
                    tab.set.target(tab.$content, target, temp);
                    tab.animate.scroll(tab.$content, tab.$target);
                    tab.accessibility.externalLink($elem, tab);
                } else {
                    setTimeout(function () {
                        $('.article-chapter-history-list')[0].scrollIntoView({behavior: 'smooth', block: 'center'});
                    }, 500);
                }
            }
        };

        if ($(tab.$toggle).is(':visible')) {
            tab.$li.click(); // open target tab
            animateScroll();
        } else {
            if ($(tab.$toggle).hasClass('empty')) {
                $(tab.$toggle).on('content-loaded', function () {
                    animateScroll();
                });
                tab.$li.click(); // open target tab
            } else {
                tab.$li.click(); // open target tab
                animateScroll();
            }
        }
    };

    tab.on.calculate = function (elem) {
        let elemParent = elem.parent(),
            tabNavWidth = tab.extraMargin,
            elemParentWidth = elemParent.width();
        elem.find('li').each(function () {
            tabNavWidth += $(this).innerWidth();
        });
        if (elemParentWidth < tabNavWidth) {
            if (!elem.parent().is('.scroll')) {
                tab.on.swipeBuild(elem, tabNavWidth);
            }
        } else {
            tab.on.swipeDestroy(elem);
        }
    };

    tab.updateBreadCrumb = function () {
        if ($('.tabs-breadcrumb-refresh').length) {
            $('body').on('click focus', '.tabs-breadcrumb-refresh .tab__nav .tab__nav__item__link', function () {
                let tabTitle = $(this).text(),
                    search = $('.article__breadcrumbs li:last-child').children().search; /*eslint-disable-line*/
                if ($('.article__breadcrumbs').length) {
                    const search = new URLSearchParams(search); /*eslint-disable-line*/
                    search.set('tabActivePane', $(this).attr('data-simple-tab-id'));
                    $('.article__breadcrumbs').find('a').last().text(tabTitle);
                    $('.article__breadcrumbs li:last-child').children().search = search;
                }
            });
        }
    };

    tab.additionalControl = function () {
        tab.accessibility.swapWithArrowsKeys();
        tab.updateBreadCrumb();
        $('.tabs-breadcrumb-refresh .active .tab__nav__item__link').click();
        let activeTab = $('.tab__nav.rlist').data('active-tab');
        $(`.${activeTab} a`).click();

        $(document).on('tabs:changeActiveTab', function (evt, data) {
            setTimeout(function () {
                let $truncateText = $(data.tabId)
                    .find('.truncate-text, .truncate-list, .truncate-with-shadow')
                    .removeClass('trunc-done');
                $truncateText.truncate('destroy');
                UX.genericTruncate.truncateAll();
            }, 200);
        });
        $('.tab__nav a').each(function () {
            // go through all tab nav items
            tab.check.empty($(this));
        });
    };

    $('body').on('click', '.tab__nav a', function () {
        const _this = $(this);
        const tabId = tab.getPaneTarget(_this);
        $(document).trigger('tabs:changeActiveTab', {tabId: tabId});
        const $parent = _this.parents('.tab');
        if ($parent.hasClass('tab--lazy-load')) {
            if ($(tabId).attr('data-ajaxloaded') !== 'true') {
                tab.getContent($(tabId).find('.tab-lazy'));
            }
        }
        _this.removeClass('active');
    });

    tab.additionalAjaxSuccess = function () {
        UX.genericTruncate.truncateAll();
    };

    tab.setPostbackActiveTab = function (tabID) {
        if (tabID !== '') {
            const $activeTabs = $("a[data-simple-tab-id='" + tabID + "'], button[data-simple-tab-id='" + tabID + "']");

            if ($activeTabs.length > 0) {
                $activeTabs.each(function () {
                    $activeTabs.trigger('click');
                });
            }
        }
    };

    tab.accessibility.swapWithArrowsKeys = function () {
        const tabsWidgets = document.querySelectorAll('.tab .tab__nav');
        tabsWidgets.forEach(tabWidget => {
            const tabs = tabWidget.querySelectorAll('.tab__nav__item .tab__nav__item__link');
            tabs.forEach(elm => {
                elm.addEventListener('focus', () => {
                    elm.click();
                });
                elm.addEventListener('keydown', event => {
                    // Enter or Space key will focus the selected panel
                    if (event.keyCode === 13 || event.keyCode === 32) {
                        let selectedPanelID = elm.getAttribute('aria-controls');
                        let selectedPanel = document.getElementById(selectedPanelID);
                        selectedPanel.focus();
                    } else if (event.keyCode === 36 || event.keyCode === 35) {
                        //prevent the default behavior of browser to scroll to top or end of the page
                        event.preventDefault();
                    }
                });
                elm.addEventListener('click', () => {
                    tabs.forEach(tab => {
                        tab.setAttribute('tabindex', '-1');
                        tab.setAttribute('aria-selected', 'false');
                    });
                    elm.removeAttribute('tabindex');
                    elm.removeAttribute('aria-selected', 'true');
                });
            });
        });
    };
})(UX);
