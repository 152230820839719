import 'ui-core/basic/inputs/js/inputsV2.js';

(function () {
    UX.fieldsCtrl.additionalControls = function () {
        $('body').on('click', '.password-toggle', function () {
            var $this = $(this);
            var $passwordElem = $this.parents('.input-group').find('.form-control');
            var type = $passwordElem.attr('type');
            if (type === 'password') {
                $passwordElem.attr('type', 'text');
                $this.removeClass('icon-eye');
                $this.addClass('icon-eye-blocked');
            } else {
                $passwordElem.attr('type', 'password');
                $this.removeClass('icon-eye-blocked');
                $this.addClass('icon-eye');
            }
        });

        $('.current-location').on('click', function (e) {
            var $this = $(this);
            e.preventDefault();
            $.get(
                'https://ipinfo.io',
                function (response) {
                    $this
                        .addClass('js-active')
                        .closest('.input-group')
                        .find('.location--field')
                        .val(response.city + ', ' + response.region);
                },
                'jsonp'
            );
        });

        $('.validate-form input').focusout(function () {
            var validationType = '';
            var value = $(this).val().trim();
            var isValid = true;
            var $elem = $(this);
            var emptyValidation;
            var regexp;

            if (typeof $elem.attr('data-validation') !== 'undefined' && value !== '') {
                validationType = $elem.attr('data-validation');

                if (validationType === 'custom') {
                    return;
                }

                if (validationType === 'url') {
                    regexp =
                        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/; //regex is vulnerable for ReDos attack
                    isValid = regexp.test(value);
                }
                if (validationType === 'email') {
                    isValid = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value);
                }
                //ORCID field validation https://support.orcid.org/hc/en-us/articles/360006897674-Structure-of-the-ORCID-Identifier
                if (validationType === 'orcid') {
                    isValid = /^\d{4}-\d{4}-\d{4}-\d{3}[0-9,X]$/.test(value);
                }
            }

            emptyValidation = $elem.attr('data-validation');
            if (value === '' && emptyValidation === 'text') {
                isValid = false;
                $elem.addClass('invalid');
            }

            if (!isValid) {
                $elem.parents('.input-group__field-container').addClass('input-group__field-container--error');
                $elem.parents('.input-group__field-container').next('.field-error-label').show();
            } else {
                $elem.parents('.input-group__field-container').removeClass('input-group__field-container--error');
                $elem.parents('.input-group__field-container').next('.field-error-label').hide();
                $elem.find('.input-group__field-container--error').css('box-shadow', 'none');
            }
        });

        $('.validate-form').submit(function () {
            var $this = $(this);
            var scrollVal;
            if ($this.find('.input-group__field-container--error').length) {
                $this
                    .find('.input-group__field-container--error')
                    .css('box-shadow', 'rgba(238, 72, 40, 0.66) 0px 0px 20px');
                setTimeout(function () {
                    $this.find('.input-group__field-container--error').css('box-shadow', 'none');
                }, 2000);
                scrollVal = $this.find('.input-group__field-container--error').first().offset().top;
                $(document).scrollTop(scrollVal - 150);
                return false;
            }
        });

        $('.submit-profile').click(function () {
            var delay = 0;
            var offset = 150;
            $('html, body').animate({scrollTop: $($('.invalid')[0]).offset().top - offset}, delay);
        });
        if ($(window).width() > 767) {
            $(document).scroll(function () {
                if ($('.autoComplete').length && !$('.autoComplete').attr('hidden')) {
                    $('.autoComplete').attr('hidden', 'true');
                }
            });
        }

        $('.quick-search--button').click(function () {
            $('.autoComplete').attr('hidden');
        });
        UX.fieldsCtrl.editableObstacle();
        UX.fieldsCtrl.ajaxHelper__oneField();
        UX.fieldsCtrl.form__fill__GETParams();
    };

    UX.fieldsCtrl.editableObstacle = function () {
        // Internal toggle function
        function toggleState($fields, $groups, $buttons) {
            $fields.attr('readonly', function (_, attr) {
                return !attr;
            });
            $buttons.attr('disabled', function (_, attr) {
                return !attr;
            });
            $groups.toggleClass('input-group--gray-bg');
        }

        $('[data-form-editable="obstacle"]').each(function () {
            var $form = $(this);

            // lists
            var $fields = $form.find(':input:not([readonly]):not([type=hidden])');
            var $groups = $fields.parents('.input-group');
            var $buttons = $form.find('button, [type=submit], a.cancel');

            // initial state skips exceptions with data-form-editable-start="enabled"
            toggleState($fields, $groups, $buttons.filter(':not([data-form-editable-start="enabled"])'));

            // toggle on click
            $form.find('[data-form-editable="disable"], [data-form-editable="enable"]').on('click', function () {
                // - timeout to run async with form reset, which would otherwise stop working
                setTimeout(function () {
                    toggleState($fields, $groups, $buttons);
                }, 10);
            });
        });
    };

    UX.fieldsCtrl.ajaxHelper__oneField = function () {
        $('[data-ajax="helper__oneField"]').each(function () {
            var $container = $(this);
            var $submit = $container.find('[data-ajax="submit"]');
            var $field = $container.find(':input:not([data-ajax="submit"])');
            var $messages = $('<div class="messages"></div>');
            var helper__target = $(`[name="${$field.data().ajaxUpdate}"]`);
            $container.append($messages);

            $field.on('submit', function (event) {
                event.preventDefault();
                $submit.click();
            });
            $submit.on('click', function (event) {
                event.preventDefault();

                $.ajax({
                    url: $container.data().ajaxUrl + $field[0].value + '/',
                    context: document.body,
                })
                    .done(function (payload) {
                        if (typeof payload === 'string') {
                            payload = JSON.parse(payload);
                        }

                        if (payload.exist) {
                            // APPLY HELPER
                            helper__target.val($field.val());
                            $messages.html(
                                '<p class="alert-msg--success"><i class="icon-checkmark"></i>' +
                                    payload.message +
                                    '</p>'
                            );
                        } else {
                            $messages.html(
                                '<p class="alert-msg--warning"><i class="icon-warning-triangle"></i>' +
                                    payload.message +
                                    '</p>'
                            );
                        }
                    })
                    .error(function () {
                        $messages.html(
                            '<p class="alert-msg--error"><i class="icon-notice"></i>Request failed, please try again later.</p>'
                        );
                    });
            });
        });
    };

    UX.fieldsCtrl.form__fill__GETParams = function () {
        function __GET() {
            var params = {};
            var parts;
            var i;
            var nv;

            if (window.location.search) {
                parts = window.location.search.substring(1).split('&');

                for (i = 0; i < parts.length; i++) {
                    nv = parts[i].split('=');
                    if (!nv[0]) continue;
                    params[nv[0]] = nv[1] || true;
                }
            }

            return params;
        }

        $('[data-get-values="GET"]').each(function () {
            var $form = $(this);
            var params = __GET();
            $form.find('[data-value="GET"]').each(function () {
                if ({}.propertyIsEnumerable.call(this.name)) {
                    this.value = params[this.name];
                }
            });
        });
    };

    UX.fieldsCtrl.additionalClone = function ($this) {
        var $elem = $this.closest('.searchIn--field');
        var options;
        if ($elem.find('.ms-ctn').length) {
            $elem.find('.ms-ctn').remove();
            $elem.find('input').show();
        }

        if ($elem.closest('.advanced-search--filters-fields').length) {
            options = $elem.find('option');
            options.removeAttr('data-values');

            UX.searchFieldsCtrl.delaiedDoSuggest($elem.find('select'));
        }

        if ($this.find('.input-group__field-container--error, .input-group__field-container--success').length) {
            UX.meregProfile.removeValidationMessage($this.find('input'));
        }
    };
})();
