import 'ui-core/plugins/modal/js/modal';

(function () {
    var manageAlert = {
        $toggle: null,
        $target: null,
        $popUp: $('#manageAlert'),

        init: function () {
            manageAlert.control();
        },
        control: function () {
            $(document).on('click', '#manageAlert__crtl', function (e) {
                e.preventDefault();
                e.stopPropagation();
                manageAlert.$toggle = $(this);
                manageAlert.$target = manageAlert.$toggle.attr('href');

                if (manageAlert.$popUp.find('.modal__dialog--error').length) {
                    manageAlert.fetchAjax();
                } else {
                    manageAlert.$popUp.modal('show');
                }
            });
        },
        fetchAjax: function () {
            $.ajax({
                url: manageAlert.$target,
                type: 'GET',
                success: function (data) {
                    manageAlert.$popUp.find('.email').html(data.userEmail);
                    manageAlert.$popUp.find('.manageAlert__btn').attr('href', data.alertSettingsUrl);
                    manageAlert.$popUp.find('.modal__dialog--success').removeClass('hidden');
                    manageAlert.$popUp.find('.modal__dialog--error').remove();
                },
                error: function () {
                    manageAlert.$popUp.find('.modal__dialog--error').removeClass('hidden');
                },
                complete: function () {
                    manageAlert.$popUp.modal('show');
                },
            });
        },
    };
    UX.manageAlert = manageAlert; // add to global namespace
})();
