import 'ui-core/components/accordion/js/accordion.js';

UX.accordion.collapseAccordion = false;

UX.accordion.collapseTabsByDefault = function (elem) {
    UX.accordion.collapseTabs = true;
    if ($(elem).hasClass('accordion-tab-first')) {
        UX.accordion.collapseTabs = false;
    }
    return UX.accordion.collapseTabs;
};

UX.accordion.extraCollapseAccordionCheck = function () {
    return false;
};

if ($('.table-of-content--lazy').length) {
    UX.accordion.isStickyBody = true;
}

UX.accordion.additionalAjaxSuccess = function ($elem) {
    if ($elem.find('.truncate-list').length || $elem.find('.truncate-text').length) {
        UX.genericTruncate.init();
    }

    if ($elem.find('[class*=simple-tooltip__]').length) {
        UX.simpleTooltip.init();
    }

    UX.tableOfContent.toggleCheckBox();
    UX.charCount.init();
};

UX.accordion.getCustomAjaxUrl = function ($elem) {
    var tocHeading = '?tocHeading=' + $elem.attr('data-position');
    var widgetId = '&widgetId=' + $('.table-of-content-wrapper').attr('data-widgetId');
    var pbContext = '&pbContext=' + encodeURIComponent($('meta[name=pbContext]').attr('content'));
    var doi = '&doi=' + $elem.attr('data-doi');
    return $elem.attr('data-action') + tocHeading + widgetId + doi + pbContext;
};

UX.accordion.getAccordionContent = function ($elem) {
    var ajaxUrl = UX.accordion.buildAjaxUrl($elem);
    var $parentTab = $elem.parent();
    $.ajax({
        url: ajaxUrl,
        success: function (data) {
            $elem.closest('.accordion-tabbed__tab').attr('data-ajaxLoaded', 'true');
            $elem.replaceWith(data);
            if ($parentTab.find('.accordion-tabbed').length) {
                UX.accordion.logic($parentTab.find('.accordion-tabbed'));
            }
            $parentTab.find('.accordion__spinner').remove();
            UX.accordion.additionalAjaxSuccess($parentTab);
            $('a,button').removeClass('loading__link');
        },
        error: function () {},
    });
};

UX.accordion.on.toggle.single = function ($this) {
    var $wrapper = $this.closest('.expandable-accordion'),
        state = $this.attr('aria-expanded') !== 'true',
        contentTarget,
        $accordionContent = $this.next('.accordion__content'),
        accordionExpandedSingle = $.Event('accordion:expandedSingle');

    if (typeof $this.attr('data-content-target') !== 'undefined') {
        contentTarget = $this.attr('data-content-target');
        $accordionContent = $(contentTarget);
    }
    $accordionContent.slideToggle(200, function () {
        if ($this.parent().hasClass('article-accordion')) {
            $('.article-row-left').height('auto');
            UX.accordion.isStickyBody = state;
        }
    });

    $this.toggleClass('js--open');
    if ($this.hasClass('parentAccordion')) {
        setTimeout(function () {
            $(window).trigger('resize.scrollBox');
        }, 250);
    }

    $this.attr('aria-expanded', state);
    $(document).trigger(accordionExpandedSingle, [$this, $accordionContent]);
    if ($wrapper.length) UX.accordion.on.checkExpandAll($this, state, $wrapper);
};
