(function () {
    let pairingAff = {
        $affPopup: $('.expired__affiliations'),
        closeButton: document.querySelector('.expired__affiliations__close__button .close'),
        checkbox: document.querySelector('.expired__affiliations__footer input'),

        init() {
            pairingAff.control();
        },
        control() {
            // Clicking on the X button will save a cookie
            if (pairingAff.closeButton) {
                pairingAff.closeButton.addEventListener('click', function () {
                    pairingAff.$affPopup.fadeOut();
                    localStorage.setItem('closePopup', true);
                });
            }

            // Checking the Checkbox will save a different cookie
            $(pairingAff.checkbox).change(function () {
                if (this.checked) {
                    pairingAff.$affPopup.fadeOut();
                    localStorage.setItem('closePermanent', true);
                }
            });

            $(document).ready(function () {
                // Case 1 (user click on X button)
                // We check if the user isn't logged in in order to delete any (closePopup) cookie which gets deleted on every logout based on this logic.
                // We check for the user log out by checking the (isLogedin) property from the json that we attach in our jade and then pass it here.
                let deleteCookie = $('#deleteCookie').val();
                if (deleteCookie === 'true') {
                    localStorage.removeItem('closePopup');
                }
                //  while the user is logged in we constantly check for the (closePopup) in order to keep the popup hidden
                if (localStorage.getItem('closePopup')) {
                    pairingAff.$affPopup.hide();
                }
                // or show it
                else {
                    pairingAff.$affPopup.show();
                }

                // Case 2 (user checks the checkbox)
                // if (closePermanent) cookie exists, keep the popup hidden
                if (localStorage.getItem('closePermanent')) {
                    pairingAff.$affPopup.hide();
                }
            });
        },
    };

    UX.pairingAff = pairingAff;
})();
