(function () {
    var claimRequestList = {
        init: function () {
            claimRequestList.control();
        },
        control: function () {
            claimRequestList.setDates();
            claimRequestList.toggle();
        },
        toggle: function () {
            const datesList = document.querySelector('.dates-list');
            const listSelector = document.querySelector('.input-group-addon--right');

            listSelector.addEventListener('click', () => {
                if (datesList.classList.contains('js--show')) {
                    datesList.classList.remove('js--show');
                } else {
                    datesList.classList.add('js--show');
                }
            });

            document.addEventListener('click', function (event) {
                const target = event.target;
                if (target !== listSelector && !listSelector.contains(target)) {
                    datesList.classList.remove('js--show');
                }
            });
        },
        setDates: function () {
            const todayLink = document.getElementById('today');
            const yesterdayLink = document.getElementById('yesterday');
            const lastMonthLink = document.getElementById('lastMonth');

            const formatDate = date => {
                const day = ('0' + date.getDate()).slice(-2);
                const month = ('0' + (date.getMonth() + 1)).slice(-2); // Month is zero-indexed, so add 1
                const year = date.getFullYear();
                return `${day}-${month}-${year}`;
            };

            // Get yesterday's date
            const today = new Date();
            const yesterday = new Date(today);
            yesterday.setDate(today.getDate() - 1);

            // Get last month's date
            const lastMonth = new Date(today);
            lastMonth.setMonth(today.getMonth() - 1);

            todayLink.setAttribute(
                'href',
                `/action/showClaimRequestDashboard?fromDate=${formatDate(today)
                    .split('-')
                    .reverse()
                    .join('-')}&pageNumber=0`
            );
            yesterdayLink.setAttribute(
                'href',
                `/action/showClaimRequestDashboard?fromDate=${formatDate(yesterday)
                    .split('-')
                    .reverse()
                    .join('-')}&pageNumber=0`
            );
            lastMonthLink.setAttribute(
                'href',
                `/action/showClaimRequestDashboard?fromDate=${formatDate(lastMonth)
                    .split('-')
                    .reverse()
                    .join('-')}&pageNumber=0`
            );
        },
    };

    UX.claimRequestList = claimRequestList; // add to global namespace
})();
