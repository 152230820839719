(function () {
    var loadLazyImages = {
        init: function ($elem) {
            $elem.find('img.lazy:not(.image-lazy-loaded)').each(function () {
                var $this = $(this);
                $this.attr('src', $this.attr('data-src')).addClass('image-lazy-loaded');
            });
        },
    };
    UX.loadLazyImages = loadLazyImages; // add to global namespace
})();
