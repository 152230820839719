import 'ui-core/basic/base/js/pageBody.js';

(function () {
    var instWrapper = $('.institution-wrapper'),
        windowWidth = $(window).width(),
        $adMessageActive = $('.pb-ad'),
        adMessageNotClosed = !UX.cookies.hasItem('adMessageClosed') && UX.cookies.getItem('adMessageClosed') === null,
        $sidebarSection = $('.sidebar-section'),
        $sticky_prize = $('.award-winners .sticky_prize'),
        $serialName = $('.displaySerialName .counter-list .multi-search__item--serialName'),
        $stickyCheckboxes = $('.sticky_checkboxes'),
        sidebarPosition = 30,
        sidebarWidth = $sidebarSection.width(),
        $mediaPill = $('.pill-media'),
        $tablePill = $('.pill-tables'),
        formatsCount = parseInt($('#formatsCount').val()),
        $formatsPill = $('.pill-formats'),
        is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    UX.pageBody.$fixedElements = $('.header__fixed-items');
    UX.pageBody.header = '.header__fixed-items';
    UX.pageBody.contentSelectors = [''];

    UX.pageBody.getFixedElemHeight = function () {
        var totalHeight = 0;
        $('.fixed-element').each(function () {
            totalHeight = totalHeight + $(this).height();
        });
        return totalHeight;
    };
    UX.pageBody.fixedHeight = UX.pageBody.getFixedElemHeight();

    function handleFirstTab(e) {
        if (e.keyCode === 9) {
            // the "I am a keyboard user" key
            document.body.classList.add('js--keyboard-on');
            window.removeEventListener('keydown', handleFirstTab);
        }
    }

    window.addEventListener('keydown', handleFirstTab);

    if (instWrapper.length && instWrapper.html().replace(/^\s*/, '').replace(/\s*$/, '') === '') {
        instWrapper.html('');
    }

    window.addEventListener('load', function () {
        if (document.getElementsByClassName('login-page-wrapper').length > 0) {
            document.querySelector('.login-list').classList.add('on-login-page');
        }
    });

    function isIE() {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf('MSIE ');
        var trident = ua.indexOf('Trident/');
        var rv = ua.indexOf('rv:');
        var edge = ua.indexOf('Edge/');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        if (trident > 0) {
            // IE 11 => return version number
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    }

    UX.pageBody.isIE = isIE();

    function isEdge() {
        var ua = window.navigator.userAgent;
        var edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    }

    UX.pageBody.isEdge = isEdge();

    function isIEonly() {
        var ua = window.navigator.userAgent;
        var msie = ua.indexOf('MSIE ');
        var trident = ua.indexOf('Trident/');
        var rv = ua.indexOf('rv:');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        if (trident > 0) {
            // IE 11 => return version number
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        // other browser
        return false;
    }

    UX.pageBody.isIEonly = isIEonly();

    if (windowWidth < UX.grid.screenSm) {
        //Print applied facets count
        if ($('#appliedFacetsCount').length) {
            $('.search__bottom-left .applied-facets-count')
                .html($('#appliedFacetsCount').val())
                .css('display', 'inline-block');
        }
    }

    // Ad message control code
    if ($adMessageActive.length && adMessageNotClosed) {
        $adMessageActive.css('display', 'flex');

        //Close the ad message
        $('.pb-ad .icon-close_thin').on('click', function () {
            $(this).parent().remove();
            UX.cookies.setItem('adMessageClosed', true, '', '/');
        });
    }

    // to be removed after upgrade to core-1.12.0
    UX.pageBody.urlParam = {
        replace: function (url, paramName, paramValue) {
            var pattern = new RegExp('\\b(' + paramName + '=).*?(&|#|$)');

            if (paramValue === null) {
                paramValue = '';
            }
            if (url.search(pattern) >= 0) {
                return url.replace(pattern, '$1' + paramValue + '$2');
            }
            url = url.replace(/[?#]$/, '');
            return url + (url.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue;
        },

        test: function (url, paramName, paramValue) {
            var pattern;
            if (paramValue !== undefined) {
                pattern = new RegExp('\\b(' + paramName + '=' + paramValue + ').*?(&|#|$)');
            } else {
                pattern = new RegExp('\\b(' + paramName + ').*?(&|#|$)');
            }

            return pattern.test(url);
        },
        getParamVal: function (url, paramName) {
            let vars = {};
            url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (key, value) {
                //regex is vulnerable for ReDos attack
                vars[key] = value;
            });
            return vars[paramName];
        },
    };

    if (windowWidth >= UX.grid.screenSm) {
        if (UX.pageBody.isEdge && $('.full-text-wrapper').length) {
            if ($sidebarSection.length) {
                $(window).on('scroll', function () {
                    if ($(this).scrollTop() > sidebarPosition) {
                        $sidebarSection.css('position', 'fixed').css('width', sidebarWidth);
                    } else if ($(this).scrollTop() < sidebarPosition) {
                        $sidebarSection.css('position', 'static');
                    }
                });
            }
        }
    }

    if (UX.pageBody.isIEonly && $sticky_prize.length) {
        $sticky_prize.css('position', 'static');
    }

    if (UX.pageBody.isIEonly && $serialName.length) {
        $serialName.css('display', 'block');
    }

    if ($stickyCheckboxes.length) {
        UX.pageBody.stickyCheckboxesHeight = $stickyCheckboxes.outerHeight(true);
        UX.pageBody.stickyCheckboxesPosition = $stickyCheckboxes.offset().top;
    }

    if ($('.pill-list').length) {
        $('.pill-list > li > a').each(function () {
            var $thisLink = $(this);
            var $parentLi = $thisLink.parent();
            var $thisLinkTitle = $thisLink.attr('title');
            if ($thisLink.hasClass('disable')) {
                if (!$parentLi.hasClass('disable')) {
                    $parentLi.addClass('disable');
                }
                $parentLi.attr('title', $thisLinkTitle);
            }
        });

        if ($('.article__full').length === 0 && $('.article__abstractView').length === 0) {
            //if Abstract view (No Access)
            $mediaPill.addClass('disable');
            $mediaPill.parent().addClass('disable');

            $tablePill.addClass('disable');
            $tablePill.parent().addClass('disable');
        }

        if ($('#formatsCount').length) {
            if (formatsCount === 0) {
                $formatsPill.addClass('disable');
                $formatsPill.parent().addClass('disable');
            }
        }
    }

    if ($('.truncate-text-css').length && is_safari) {
        $('.truncate-text-css > *').css('overflow', 'hidden');
    }

    $('.sub-nav__item, .profile-menu__account a').each(function () {
        if (window.location.href.indexOf($(this).attr('href')) > -1) {
            $(this).attr('aria-current', 'page');
        }
    });
    //Search facet accordions when click on less slide up to the facet accordion top
    $('body').delegate('#filter .show-more.js--open', 'click', function () {
        var $thisAccordion = $(this).parent();
        $('html, body').animate(
            {
                scrollTop: $thisAccordion.offset().top - 250,
            },
            300
        );
    });
})();
