import 'ui-core/plugins/controller/js/controller.js';

UX.controller.checkToHide = function (e) {
    if (
        !(
            UX.controller.$container.is('.quick-search__dropBlock') &&
            ($(e.target).is('.ms-res-item') || $(e.target).is('.ms-close-btn'))
        ) &&
        !UX.controller.$container.is(e.target) && // if the target of the click isn't the container...
        UX.controller.$container.has(e.target).length === 0 &&
        !UX.controller.$target.is(e.target) && // if the target of the click isn't the controller...
        UX.controller.$target.has(e.target).length === 0 && // ... nor a descendant of the controller
        !$(e.target).closest('.ui-autocomplete').length &&
        !$(e.target).hasClass('jcf-option-inDropblock') &&
        !$(e.target).children('.shibboleth').length
    ) {
        UX.controller.$activeComponent.on.hide(e);
    }
};

UX.controller.check = function (e) {
    UX.controller.active = UX.controller.$body.attr('data-active');
    if (UX.controller.active != null && $('.figure-viewer__ctrl__return').hasClass('is-hidden')) {
        UX.controller.disable(e);
    }
};
