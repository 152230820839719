(function () {
    var loadLazyScroll = {
        $wrapper: null,
        $target: null,
        dataAjax: null,
        trigger: null,
        loaded: true,
        page: 1,

        init: function ($wrapper, dataAjax, $target, trigger) {
            var windowWidth = $(window).width();
            loadLazyScroll.trigger = trigger === undefined || windowWidth <= UX.grid.screenXs ? 'scroll' : trigger;
            loadLazyScroll.$wrapper = $wrapper;
            loadLazyScroll.$target = $target;
            loadLazyScroll.dataAjax = dataAjax;
            loadLazyScroll.page = 1;

            if (loadLazyScroll.trigger === 'scroll') {
                loadLazyScroll.makeSureToLoad();
            }
            if (document.readyState === 'complete' && loadLazyScroll.trigger === 'load') {
                loadLazyScroll.makeSureToLoad();
            } else {
                $(window).on(loadLazyScroll.trigger, function () {
                    loadLazyScroll.makeSureToLoad();
                });
            }
        },

        makeSureToLoad: function () {
            if (
                loadLazyScroll.dataAjax !== '' &&
                typeof loadLazyScroll.dataAjax !== 'undefined' &&
                loadLazyScroll.$target.length
            ) {
                loadLazyScroll.graduallyLoad();
            } else {
                setTimeout(loadLazyScroll.makeSureToLoad, 250);
            }
        },

        graduallyLoad: function () {
            var condition =
                loadLazyScroll.trigger === 'scroll'
                    ? $(window).scrollTop() + $(window).height() >
                          loadLazyScroll.$wrapper.height() + loadLazyScroll.$wrapper.offset().top &&
                      loadLazyScroll.dataAjax.length &&
                      loadLazyScroll.loaded
                    : loadLazyScroll.dataAjax.length;
            var link;
            if ($('html').is('#pb-editor')) {
                condition = loadLazyScroll.dataAjax.length;
            }

            if (condition) {
                loadLazyScroll.loaded = false;
                link = loadLazyScroll.dataAjax.shift();
                loadLazyScroll.loadItem(link);
            }
        },
        loadItem: function (link) {
            loadLazyScroll.$target.queue(function (next) {
                loadLazyScroll.$wrapper.find('.lazy-loader').fadeIn(500);
                $.ajax({
                    type: 'GET',
                    url: link,
                    success: function (response) {
                        var fadeItems,
                            $sticky_prize = $('.award-winners .sticky_prize');
                        if (loadLazyScroll.$wrapper.is('.search-result')) {
                            fadeItems = loadLazyScroll.page * 10;
                            $.parseHTML(response).forEach(function (item) {
                                loadLazyScroll.$target.delay(50).queue(function (next) {
                                    loadLazyScroll.$target.append(item);
                                    $('.journalBrowse--lazyLoad > li:nth-child(n + ' + (fadeItems + 1) + ')').addClass(
                                        'fadeInUp clearfix'
                                    );
                                    next();
                                    setTimeout(function () {
                                        UX.loadLazyImages.init(loadLazyScroll.$wrapper);
                                    }, 150);
                                });
                            });
                        } else {
                            loadLazyScroll.$target.append(response);
                        }

                        if (loadLazyScroll.$wrapper.is('.award-winners')) {
                            $(
                                '.award-winners__wrapper-list > .accordion-tabbed__tab:nth-child(' +
                                    (loadLazyScroll.page + 1) +
                                    ')'
                            ).addClass('fadeInUp clearfix');
                            if (UX.pageBody.isIEonly && $sticky_prize.length) {
                                $sticky_prize.css('position', 'static');
                            }
                        }

                        loadLazyScroll.$wrapper.find('.lazy-loader').fadeOut(100);
                        loadLazyScroll.page++;
                        loadLazyScroll.graduallyLoad();
                        next();
                        loadLazyScroll.loaded = true;

                        loadLazyScroll.callBack();

                        setTimeout(function () {
                            UX.loadLazyImages.init(loadLazyScroll.$wrapper);
                        }, 150);
                    },
                });
            });
        },

        callBack: function () {
            if (loadLazyScroll.$wrapper.is('.split-request-form')) {
                if (loadLazyScroll.dataAjax.length === 0) {
                    const hasError = document.querySelector('.input-group__field-container--error');
                    const newId = document.querySelector('.input-group__field-container--success');
                    if (!hasError && newId) {
                        $('.split-request-action button').removeClass('disabled');
                    }
                }
            }
        },
    };
    UX.loadLazyScroll = loadLazyScroll; // add to global namespace
})();
