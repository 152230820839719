import * as ShareThisViaTwitter from '../../../plugins/share-text/js/twitter.external';
import * as ShareThisViaFacebook from '../../../plugins/share-text/js/facebook.external';
import * as ShareThisViaLinkedIn from '../../../plugins/share-text/js/linked-in.external';
import ShareThis from '../../../plugins/share-text/js/share-text.external';

(function () {
    var textSharing = {
        selector:
            '.article__body, article[data-design="pill"] #abstracts, article[data-design="pill"] #bodymatter, article[data-design="pill"] #backmatter',
        sharers: [ShareThisViaTwitter, ShareThisViaFacebook, ShareThisViaLinkedIn],
        init: function () {
            ShareThis({
                sharers: textSharing.sharers,
                selector: textSharing.selector,
            }).init();
        },
    };
    UX.textSharing = textSharing;
})();
