(function () {
    var references = {
        $target: null,
        numberOfLi: null,
        $targetItems: null,
        init: function () {
            references.$target = $('.article__references ol');
            references.$targetItems = $('.article__references .references__item');

            references.numberOfLi = references.$targetItems.length;
            references.on.getInsideTab();
        },
        on: {
            getInsideTab: function () {
                var refNum, articlesRefCopy;
                if (references.numberOfLi === 0) {
                    $('.pill-list [data-slide-target="#pill-references"]')
                        .addClass('disable')
                        .closest('li')
                        .addClass('disable')
                        .attr('title', 'References');
                    return;
                }

                refNum = references.numberOfLi > 150 ? '150+' : references.numberOfLi;
                $('.pill-list .refNum').html(refNum + '<span class="sr-only">References</span>');

                articlesRefCopy = references.$target.clone();
                articlesRefCopy.appendTo('.pill-references__content');

                articlesRefCopy.find('[id]').each(function () {
                    //to remove duplicate Ids
                    this.id = this.id + '_copy';
                });

                if (articlesRefCopy.find('.references__link-dropdown').length) {
                    articlesRefCopy.find('.references__link-dropdown').each(function () {
                        //to remove duplicate target for dropdowns
                        $(this).attr('data-db-parent-of', $(this).attr('data-db-parent-of') + '_copy');
                        $(this)
                            .find('.references-drop-btn')
                            .attr(
                                'data-db-target-for',
                                $(this).find('.references-drop-btn').attr('data-db-target-for') + '_copy'
                            );
                        $(this)
                            .find('.reference-dropdownList')
                            .attr(
                                'data-db-target-of',
                                $(this).find('.reference-dropdownList').attr('data-db-target-of') + '_copy'
                            );
                    });
                }

                //close references dropdown when a reference inside it clicked
                $(document).on('click', '.pill-references__content .reference-dropdownList a', function () {
                    setTimeout(function () {
                        UX.dropBlock.on.hide();
                    }, 1000);
                });
            },
        },
    };

    UX.references = references; // add to global namespace
})();
