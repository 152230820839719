(function () {
    var d3 = require('d3-latest');
    var d3Cloud = require('d3-cloud');
    var tagCloud = {
        selector: '.tag-cloud',
        $target: null,
        fill: d3.scaleOrdinal(d3.schemeCategory10),
        words: {},
        width: 500,
        height: 500,
        wordsLength: 0,
        dynamicPercent: 1,
        labelLength: 70,
        fontScale: 1.8,
        reduceFontScale: 0,
        orientation: 'mixed',
        acceptSameFontSize: false,

        init: function () {
            tagCloud.$target = $(tagCloud.selector);

            if (!UX.pageBody.isIE) {
                tagCloud.$target.append(
                    '<div class="loader"><img src="/specs/products/acm/releasedAssets/images/loader.gif" alt="loading"></div>'
                );
                document.fonts.ready.then(function () {
                    tagCloud.acceptSameFontSize = true;
                    tagCloud.$target.find('.loader').remove();
                    tagCloud.reDraw();
                });
            } else {
                tagCloud.reDraw();
            }

            tagCloud.control();
        },
        control: function () {
            $(window).on('resized', function () {
                tagCloud.acceptSameFontSize = false;
                tagCloud.reDraw();
            });
        },
        reDraw: function () {
            tagCloud.selector = '.tag-cloud';
            tagCloud.$target = $(tagCloud.selector);
            tagCloud.$target.each(function () {
                tagCloud.selector = this;
                tagCloud.$target = $(tagCloud.selector);
                tagCloud.orientation = 'mixed';
                tagCloud.fontScale = 1.8;
                tagCloud.reduceFontScale = 0;
                tagCloud.initDraw();
            });
        },
        setRange: function () {
            const defaultRange = [12 * tagCloud.fontScale, 24 * tagCloud.fontScale];
            const smallWidthRange = [20 * tagCloud.fontScale, 40 * tagCloud.fontScale];
            return tagCloud.width < 300 ? smallWidthRange : defaultRange;
        },
        initDraw: function () {
            tagCloud.reduceFontScale++;
            tagCloud.dynamicPercent = 1;
            tagCloud.labelLength = 70;

            if (tagCloud.$target.find('.tag-cloud__svg').length) {
                tagCloud.$target.find('.tag-cloud__svg').remove();
            }

            if (typeof tagCloud.$target.attr('data-tags') !== 'undefined') {
                tagCloud.words = tagCloud.get.words();
            }

            tagCloud.wordsLength = tagCloud.words.length;

            if (tagCloud.$target.parent().attr('data-tagcloud-width')) {
                tagCloud.width = tagCloud.$target.parent().attr('data-tagcloud-width');
            } else {
                tagCloud.width = tagCloud.$target.parent().width();
            }

            if (tagCloud.$target.parent().attr('data-tagcloud-height')) {
                tagCloud.height = tagCloud.$target.parent().attr('data-tagcloud-height');
            }

            tagCloud.wordScale = d3
                .scaleLinear()
                .domain([
                    d3.min(tagCloud.words, function (d) {
                        return Number(d.count);
                    }),
                    d3.max(tagCloud.words, function (d) {
                        return Number(d.count);
                    }),
                ])
                .range(tagCloud.setRange())
                .clamp(true);

            if (typeof tagCloud.$target.attr('data-orientation') !== 'undefined') {
                tagCloud.orientation = tagCloud.$target.attr('data-orientation');
            }

            /*eslint-disable*/
            d3Cloud()
                .size([tagCloud.width, tagCloud.height])
                .words(
                    tagCloud.words.map(function (d) {
                        var label;
                        var count;
                        var target = '';
                        if (d.label) {
                            label = d.label;
                        }
                        if (d.count) {
                            count = d.count;
                        }
                        if (d.tag && d.tag.link) {
                            target = d.tag.link;
                        }
                        if (d.link) {
                            target = d.link;
                        }

                        return {text: label, size: count, link: target};
                    })
                )
                .rotate(function (d) {
                    var rotate = 90, // The original position of any element inside the cloud would be a 90 degree angle unless it's small enough to (Based on the below if statement)
                        weight = d.size,
                        text = d.text,
                        containerWidth = tagCloud.width,
                        windowWidth = $(window).width(),
                        newFontSize = tagCloud.wordScale(weight), // We reduce the font size
                        testElement = `<span style="font-size: ${newFontSize}px" class="measureElement">${text}</span>`; // We append the text with the new font size to see if it fits inside the container


                    $('body').append(testElement); //we append the new test element with the new font size that was calculated by tagCloud.wordScale(weight)

                    /**
                     * if the element is small enough to fit in a horizontal position, we give it rotate "0"
                     * **/
                    if($('.measureElement').width() < containerWidth && $('.measureElement').text().length < 20) {
                        rotate = 0;
                    }


                    if (windowWidth >= UX.grid.screenMd) {
                        switch (tagCloud.orientation) {
                            case 'vertical':
                                rotate = 90;
                                break;
                            case 'horizontal':
                                rotate = 0;
                                break;
                        }
                    }

                    return rotate;
                })
                .font('Merriweather Sans,sans-serif')
                .fontSize(function (d) {
                    $('.measureElement').remove();
                    return tagCloud.wordScale(d.size);
                })
                .on('end', tagCloud.drawWords)
                .start();
            /*eslint-enable*/
        },
        drawWords: function (words) {
            d3.select(tagCloud.selector)
                .append('svg')
                .attr('width', tagCloud.width)
                .attr('height', tagCloud.height)
                .attr('class', 'tag-cloud__svg')
                .append('g')
                .attr('transform', 'translate(' + tagCloud.width / 2 + ',' + tagCloud.height / 2 + ')')
                .selectAll('text')
                .data(words)
                .enter()
                .append('text')
                .attr('font-size', function (d) {
                    return d.size + 'px';
                })
                .style('font-family', 'Merriweather Sans,sans-serif')
                .style('fill', function (d, i) {
                    return tagCloud.fill(i);
                })
                .attr('text-anchor', 'middle')
                .attr('transform', function (d) {
                    return 'translate(' + [d.x, d.y] + ')rotate(' + d.rotate + ')';
                })
                .text(function (d) {
                    return d.text;
                })
                .on('click', function (d, i) {
                    window.open(d.link, '_self');
                });

            tagCloud.setFont();
        },
        get: {
            words: function () {
                return JSON.parse(tagCloud.$target.attr('data-tags'));
            },
        },
        setFont: function () {
            var tagsPrinted = tagCloud.$target.find('.tag-cloud__svg g').children().length,
                condition = tagCloud.wordsLength !== tagsPrinted && tagCloud.reduceFontScale <= 10;

            if (tagCloud.acceptSameFontSize) {
                condition = tagsPrinted <= tagCloud.wordsLength && tagCloud.reduceFontScale <= 10;
                tagCloud.acceptSameFontSize = false;
            }

            if (condition) {
                tagCloud.$target.find('.tag-cloud__svg').remove();
                tagCloud.fontScale = tagCloud.fontScale / 1.2;
                tagCloud.initDraw();
            }
        },
    };
    UX.tagCloud = tagCloud;
})();
