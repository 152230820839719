(function () {
    var binders = UX.binders;

    binders._share = {
        $sharedEmail: $('.sharedEmail'),
        $sharedEmailSubmit: $('.sharedEmailSubmit'),
        $unshareEmail: null,

        init: function () {
            binders._share.control();
        },
        control: function () {
            binders._share.$sharedEmail.on('input', function () {
                var $elem = $(this);
                binders._share.$sharedEmailSubmit = $elem.closest('.shared-email-section').find('.sharedEmailSubmit');

                if ($elem.val().trim() === '' || !/(.+)@(.+){2,}\.(.+){2,}/.test($elem.val().trim())) {
                    //regex is vulnerable for ReDos attack
                    binders._share.$sharedEmailSubmit.attr('disabled', 'disabled');
                } else {
                    binders._share.$sharedEmailSubmit.removeAttr('disabled');
                }
            });

            $(document).on('click', '.shareList', function () {
                var $this = $(this);
                var link = $this.attr('data-share-popup'); // real link
                // var link = '/specs/products/acm/widgets/binder-list/templates/demo/shareList.json'; // test link

                $('.shareBinder__alerts__msg').empty();

                $.ajax({
                    url: link,
                    type: 'GET',
                    success: function (data) {
                        var $trElem, code, name, descr;
                        if (data) {
                            $('.shared-list-section .count').html(data.length);
                            $('.shared-list-section tbody').html('');
                            if ($this.closest('.binder__issues__info').length) {
                                $trElem = $this.closest('.binder__issues');
                                code = $trElem.attr('data-binder-code');
                                name = $trElem.find('.binder__issues__name').text();
                                descr = $trElem.find('.binder__issues__description').text();
                            } else {
                                $trElem = $this.closest('tr');
                                code = $trElem.attr('data-binder-code');
                                name = $trElem.find('.binder__list__title h6').text();
                                descr = $trElem.find('.binder__list__description').text();
                            }

                            $('.shared-binder-info-section h6').html(name);
                            $('.shared-binder-info-section p').html(descr);
                            $('#shareBinder').find('form').attr('data-binder-code', code);
                            $('#shareBinder').find('.sharedEmail').val('');
                            binders._share.$sharedEmailSubmit.attr('disabled', 'disabled');
                            data.forEach(function (element) {
                                var statusIcon = 'icon-checkmark';
                                if (
                                    element.status.toLowerCase() === 'rejected' ||
                                    element.status.toLowerCase() === 'canceled'
                                ) {
                                    statusIcon = 'icon-cancel-bold';
                                }
                                $('.shared-list-section tbody').append(
                                    '<tr><td>' +
                                        element.email +
                                        '</td><td class="invite-date">' +
                                        element.invitedDate +
                                        '</td><td class="replied-date">' +
                                        element.repliedDate +
                                        '</td><td class="status"><i class="' +
                                        statusIcon +
                                        '"></i><span>' +
                                        element.status +
                                        '</span></td><td><a href="#" data-toggle="modal" data-target="#unshareBinder"><i aria-hidden="true" class="icon-cancel-bold"></i></a></td></tr>'
                                );
                            });
                        }

                        $('.shared-list-section .table-striped').scrollBox();
                    },
                });
            });

            binders._share.$sharedEmailSubmit.on('click', function (e) {
                var code = encodeURIComponent($(this).closest('form').attr('data-binder-code'));
                var link = '/action/shareBinder?binderCode=' + code + '&email='; // real link
                // var link = '/specs/products/acm/widgets/binder-list/templates/demo/share.json?email='; // test link
                var email = encodeURIComponent(binders._share.$sharedEmail.val());

                //share with new user
                e.preventDefault();
                $.ajax({
                    url: link + email,
                    type: 'GET',
                    success: function (data) {
                        if (data.sharedBinder) {
                            $('.shareBinder__alerts__msg').html(
                                '<p class="alert-msg--success"><i class="icon-checkmark"></i>' + data.message + '</p>'
                            );
                            $('.shared-list-section tbody').prepend(
                                '<tr><td>' +
                                    data.sharedBinder.email +
                                    '</td><td class="invite-date">' +
                                    data.sharedBinder.invitedDate +
                                    '</td><td class="replied-date"> - </td><td class="status"><i class="icon-pending"></i><span>Pending</span></td></tr>'
                            );
                        } else if (data.message) {
                            $('.shareBinder__alerts__msg').html(
                                '<p class="alert-msg--success"><i class="icon-checkmark"></i>' + data.message + '</p>'
                            );
                        }
                    },
                    error: function (data) {
                        if (data.responseJSON.message) {
                            $('.shareBinder__alerts__msg').html(
                                '<p class="alert-msg--warning"><i class="icon-warning-triangle"></i>' +
                                    data.responseJSON.message +
                                    '</p>'
                            );
                        }
                    },
                });

                setTimeout(function () {
                    $('.shareBinder__alerts__msg').empty();
                }, 3000);
            });

            $(document).on('click', '[data-target="#unshareBinder"]', function () {
                // unshare
                binders._share.$unshareEmail = $(this).closest('tr');
            });

            $(document).on('click', '.unshare', function (e) {
                var $this = binders._share.$unshareEmail;
                var code = $this.closest('form').attr('data-binder-code');
                var link = '/action/unShareBinder?binderCode=' + encodeURIComponent(code) + '&email='; // real link
                // var link = '/specs/products/acm/widgets/binder-list/templates/demo/unshare.json?email='; // test link
                var email = encodeURIComponent($this.find('td:first-child').text());

                // confirm unshare
                e.preventDefault();
                $.ajax({
                    url: link + email,
                    type: 'GET',
                    success: function (data) {
                        if (data.message) {
                            $('.shareBinder__alerts__msg').html(
                                '<p class="alert-msg--success"><i class="icon-checkmark"></i>' + data.message + '</p>'
                            );
                            $this.remove();
                        }
                    },
                    error: function (data) {
                        if (data.responseJSON.message) {
                            $('.shareBinder__alerts__msg').html(
                                '<p class="alert-msg--warning"><i class="icon-warning-triangle"></i>' +
                                    data.responseJSON.message +
                                    '</p>'
                            );
                        }
                    },
                });

                $('#unshareBinder').modal('toggle');

                setTimeout(function () {
                    $('.shareBinder__alerts__msg').empty();
                }, 3000);
            });

            $('#shareBinder').on('hidden.bs.modal', function () {
                $(
                    '.shared-list-section tbody, .shared-binder-info-section h6, .shared-binder-info-section p, .shared-list-section .count'
                ).html('');
            });
        },
    };
})();
