(function () {
    var linkResolver = {
        $fileInputField: $('.imagebutton-input'),
        $imagebutton_radio: $('#imagebutton_radio'),
        $urlInputField: $('.urlText-input'),
        $urlText_radio: $('#urlText_radio'),

        init: function () {
            linkResolver.$fileInputField.click(function () {
                linkResolver.$imagebutton_radio.prop('checked', true);
                linkResolver.$urlText_radio.prop('checked', false);
            });

            linkResolver.$urlInputField.click(function () {
                linkResolver.$urlText_radio.prop('checked', true);
                linkResolver.$imagebutton_radio.prop('checked', false);
            });
        },
    };

    UX.linkResolver = linkResolver;
})();
