(function () {
    var gmap = {
        locations: [],
        markers: [],
        map: null,
        oms: null,
        infowindow: null,

        init: function () {
            gmap.makeSureToLoad();
            gmap.control();
        },
        control: function () {
            $('.events-browse__list').on('click', '.info.map', function (e) {
                var $elem = $(this).closest('.search__item--event'),
                    lat = $elem.find('[name="Latitude"]').val(),
                    lng = $elem.find('[name="Longitude"]').val(),
                    searchItemDoi = $elem.find('[name="Doi"]').val(),
                    bounds = new window.google.maps.LatLngBounds(),
                    j,
                    markerLat,
                    markerDoi,
                    markerLng;
                e.preventDefault();
                bounds.extend(new window.google.maps.LatLng(lat, lng));
                gmap.map.fitBounds(bounds);
                gmap.map.panToBounds(bounds);

                for (j = 0; j < gmap.markers.length; j++) {
                    markerLat = gmap.getLatLngFloat(gmap.markers[j].getPosition().lat());
                    markerLng = gmap.getLatLngFloat(gmap.markers[j].getPosition().lng());
                    markerDoi = gmap.locations[j].doi;

                    if (markerLat === lat && markerLng === lng && markerDoi === searchItemDoi) {
                        gmap.markers[j].setAnimation(null);
                        window.google.maps.event.trigger(gmap.markers[j], 'click');

                        setTimeout(function () {
                            var spideredMarkers = gmap.oms.markersNearMarker(gmap.markers[j], false);

                            if (spideredMarkers.length) {
                                gmap.markers[j].setAnimation(null);
                                gmap.infowindow.close(gmap.map, gmap.markers);
                                window.google.maps.event.trigger(gmap.markers[j], 'click');
                            }
                        }, 200);

                        break;
                    }
                }
            });
        },
        makeSureToLoad: function () {
            if (window.gmapResultsArray !== '' && window.google && window.google.maps && window.markerClusterer) {
                gmap.defineLocations(window.gmapResultsArray);
            } else {
                setTimeout(gmap.makeSureToLoad, 250);
            }
        },
        defineLocations: function (gmapResultsArray) {
            var counter = 0,
                $doEventHTML,
                latVal,
                lngVal,
                sDateVal,
                eDateVal,
                doi,
                stateVal;
            $.parseHTML(gmapResultsArray).forEach(function (event) {
                if (event) {
                    ($doEventHTML = $(event)),
                        (latVal = $doEventHTML.find('[name="Latitude"]').val()),
                        (lngVal = $doEventHTML.find('[name="Longitude"]').val()),
                        (sDateVal = $doEventHTML.find('[name="StartDate"]').val()),
                        (eDateVal = $doEventHTML.find('[name="EndDate"]').val()),
                        (doi = $doEventHTML.find('[name="Doi"]').val()),
                        (stateVal = true);

                    if (Date.parse(Date()) < Date.parse(sDateVal)) {
                        stateVal = true;
                    } else if (Date.parse(Date()) < Date.parse(eDateVal)) {
                        stateVal = true;
                    } else {
                        stateVal = false;
                    }

                    if (latVal && lngVal) {
                        gmap.locations[counter] = {
                            lat: latVal,
                            lng: lngVal,
                            info: event,
                            doi: doi,
                            state: stateVal ? 'active' : 'inactive',
                        };
                        counter++;
                    }
                }
            });
            gmap.draw();
        },
        draw: function () {
            var markerIcon = {
                inactive: {
                    icon: '/specs/products/acm/releasedAssets/images/marker.png',
                },
                active: {
                    icon: '/specs/products/acm/releasedAssets/images/marker-active.png',
                },
            };
            var clusterStyles = [
                {
                    textColor: 'white',
                    textSize: 20,
                    url: '/specs/products/acm/releasedAssets/images/small-cluster.png',
                    height: 58,
                    width: 62,
                },
                {
                    textColor: 'white',
                    textSize: 30,
                    url: '/specs/products/acm/releasedAssets/images/cluster.png',
                    height: 82,
                    width: 82,
                },
            ];

            gmap.infowindow = new window.google.maps.InfoWindow();
            gmap.map = new window.google.maps.Map(document.getElementById('map'), {
                zoom: 2,
                gestureHandling: 'greedy',
                styles: [
                    {
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#f5f5f5',
                            },
                        ],
                    },
                    {
                        elementType: 'labels.icon',
                        stylers: [
                            {
                                visibility: 'off',
                            },
                        ],
                    },
                    {
                        elementType: 'labels.text.fill',
                        stylers: [
                            {
                                color: '#616161',
                            },
                        ],
                    },
                    {
                        elementType: 'labels.text.stroke',
                        stylers: [
                            {
                                color: '#f5f5f5',
                            },
                        ],
                    },
                    {
                        featureType: 'administrative.land_parcel',
                        elementType: 'labels.text.fill',
                        stylers: [
                            {
                                color: '#bdbdbd',
                            },
                        ],
                    },
                    {
                        featureType: 'poi',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#eeeeee',
                            },
                        ],
                    },
                    {
                        featureType: 'poi',
                        elementType: 'labels.text.fill',
                        stylers: [
                            {
                                color: '#757575',
                            },
                        ],
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#e5e5e5',
                            },
                        ],
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'labels.text.fill',
                        stylers: [
                            {
                                color: '#9e9e9e',
                            },
                        ],
                    },
                    {
                        featureType: 'road',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#ffffff',
                            },
                        ],
                    },
                    {
                        featureType: 'road.arterial',
                        elementType: 'labels.text.fill',
                        stylers: [
                            {
                                color: '#757575',
                            },
                        ],
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#dadada',
                            },
                        ],
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'labels.text.fill',
                        stylers: [
                            {
                                color: '#616161',
                            },
                        ],
                    },
                    {
                        featureType: 'road.local',
                        elementType: 'labels.text.fill',
                        stylers: [
                            {
                                color: '#9e9e9e',
                            },
                        ],
                    },
                    {
                        featureType: 'transit.line',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#e5e5e5',
                            },
                        ],
                    },
                    {
                        featureType: 'transit.station',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#eeeeee',
                            },
                        ],
                    },
                    {
                        featureType: 'water',
                        elementType: 'geometry',
                        stylers: [
                            {
                                color: '#c9c9c9',
                            },
                        ],
                    },
                    {
                        featureType: 'water',
                        elementType: 'geometry.fill',
                        stylers: [
                            {
                                color: '#afdbed',
                            },
                        ],
                    },
                    {
                        featureType: 'water',
                        elementType: 'labels.text.fill',
                        stylers: [
                            {
                                color: '#9e9e9e',
                            },
                        ],
                    },
                ],
                center: new window.google.maps.LatLng(2.8, -187.3),
            });

            window.google.maps.event.addListenerOnce(gmap.map, 'bounds_changed', function () {
                if ($('.search__item').length === 1) {
                    gmap.map.setZoom(19);
                } else {
                    gmap.map.setZoom(2);
                }
            });

            gmap.oms = new window.OverlappingMarkerSpiderfier(gmap.map, {
                markersWontMove: true,
                markersWontHide: true,
                basicFormatEvents: true,
                keepSpiderfied: true,
                ignoreMapClick: true,
                circleFootSeparation: 40,
                nearbyDistance: 100,
            });

            gmap.markers = gmap.locations.map(function (location) {
                var mark = new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(location.lat, location.lng),
                    icon: markerIcon[location.state].icon,
                    info: location.info,
                    animation: window.google.maps.Animation.DROP,
                });

                function toggleBounce() {
                    var j;
                    if (mark.getAnimation() !== null) {
                        // end toggle
                        mark.setAnimation(null);
                        gmap.infowindow.close(gmap.map, mark);
                    } else {
                        // start toggle
                        for (j = 0; j < gmap.markers.length; j++) {
                            gmap.markers[j].setAnimation(null);
                            gmap.infowindow.close(gmap.map, gmap.markers);
                        }
                        mark.setAnimation(window.google.maps.Animation.BOUNCE);
                        gmap.infowindow.open(gmap.map, mark);
                    }
                }

                window.google.maps.event.addListener(mark, 'click', function () {
                    gmap.infowindow.setContent(this.info);
                    gmap.infowindow.setOptions({maxWidth: 300});
                    toggleBounce();
                    setTimeout(function () {
                        UX.searchResult.checkEventStatus($('.map-popup'));
                    }, 100);
                });

                window.google.maps.event.addListener(gmap.infowindow, 'closeclick', function () {
                    mark.setAnimation(null);
                    gmap.infowindow.close(gmap.map, mark);
                });
                gmap.oms.addMarker(mark);
                return mark;
            });

            const markerCluster = new window.markerClusterer.MarkerClusterer({
                map: gmap.map,
                markers: gmap.markers,
                renderer: {
                    render: ({count, position}) => {
                        const icon = clusterStyles[count < 100 ? 0 : 1];
                        return new window.google.maps.Marker({
                            position,
                            icon: icon.url,
                            label: {
                                text: String(count),
                                color: icon.textColor,
                                fontSize: icon.textSize + 'px',
                            },
                            zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
                        });
                    },
                },
                algorithm: new window.markerClusterer.SuperClusterAlgorithm({
                    maxZoom: 21,
                    radius: 40,
                    extent: 180,
                }),
            });

            window.google.maps.event.addListener(markerCluster, 'clusterclick', function (cluster) {
                var marker,
                    markers = cluster.getMarkers(),
                    i;

                if (gmap.map.getZoom() >= 15) {
                    for (i in markers) {
                        markers[i].setMap(cluster.getMap());
                        marker = markers[i];
                    }
                    cluster.clusterIcon_.hide();
                    setTimeout(
                        function () {
                            window.google.maps.event.trigger(marker, 'click');
                            marker.setAnimation(null);
                            gmap.infowindow.close(gmap.map, marker);
                        },
                        100,
                        function () {
                            setTimeout(function () {
                                window.google.maps.event.trigger(marker, 'click');
                                marker.setAnimation(null);
                                gmap.infowindow.close(gmap.map, marker);
                            }, 100);
                        }
                    );
                }
            });

            if ($('.search-result').length) {
                gmap.setBounds();
            } else {
                gmap.centerMap(gmap.markers);
            }
        },
        centerMap: function (boundsMarkers) {
            var bounds = new window.google.maps.LatLngBounds(),
                i;
            for (i = 0; i < boundsMarkers.length; i++) {
                bounds.extend(boundsMarkers[i].getPosition());
            }

            gmap.map.fitBounds(bounds);
        },
        setBounds: function () {
            var boundsSelectedMarkers = [];
            var SelectedLocations = [];
            $('.search__item--event').each(function (index) {
                var $this = $(this);

                SelectedLocations[index] = {
                    lat: $this.find('[name="Latitude"]').val(),
                    lng: $this.find('[name="Longitude"]').val(),
                };
            });

            boundsSelectedMarkers = SelectedLocations.map(function (location) {
                return new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(location.lat, location.lng),
                });
            });
            gmap.centerMap(boundsSelectedMarkers);
            setTimeout(function () {
                gmap.setMapHeight();
            }, 500);
        },
        setMapHeight: function () {
            $('#map').height($('.search-result').closest('.col-md-7').height());
        },
        getLatLngFloat: function (x) {
            x = Math.floor(x * 10000000) / 10000000;
            return x.toFixed(6);
        },
    };
    UX.gmap = gmap;
})();
