(function () {
    var contribMetrics = {
        getAjaxContent: function ($toggle, $target, ajaxURl) {
            $.ajax({
                url: ajaxURl,
                type: 'GET',
                success: function (response) {
                    var resultData = response;

                    if (UX.ajaxControl.mustParseJson(resultData)) {
                        resultData = JSON.parse(resultData);
                    }

                    if ($toggle.hasClass('loadAjax-auto')) {
                        $toggle.removeClass('loadAjax-auto');
                    }
                    if ($toggle.hasClass('contrib-metrics__multi-items')) {
                        if (resultData) {
                            contribMetrics.drawContribMetricsMultiItems(resultData, $target);
                        }
                    }
                    if ($toggle.hasClass('contrib-metrics__item')) {
                        if (resultData) {
                            contribMetrics.drawContribMetricsItem(resultData, $target);
                        }
                    }
                    $target.find('.loader').remove();
                    UX.genericTruncate.truncate.text($(".truncate-text:not('.trunc-done')"));

                    $toggle.addClass('ajax-done');
                },
            });
        },
        drawContribMetricsMultiItems: function (resultData, $target) {
            var viewMoreLink = '',
                viewMoreLable = 'View More',
                contentData,
                countData,
                appendData,
                linkData,
                countLabel,
                allData = '',
                i;

            for (i = 0; i < resultData.data.length; i++) {
                contentData = '';
                countData = '';
                appendData = '';
                linkData = '';
                countLabel = '';

                if (resultData.data[i].link) {
                    viewMoreLink = resultData.data[i].link;
                }
                if ($target.attr('data-more-link') !== '') {
                    viewMoreLink = $target.attr('data-more-link');
                }
                if ($target.attr('data-more-lable') !== '') {
                    viewMoreLable = $target.attr('data-more-lable');
                }
                if (resultData.data[i].label) {
                    countLabel = resultData.data[i].label;
                }
                if (resultData.data[i]) {
                    if (resultData.data[i].content) {
                        contentData = resultData.data[i].content;
                    }
                    if (resultData.data[i].count) {
                        countData =
                            '<div class="box-item__count">' +
                            resultData.data[i].count +
                            ' <span class="contrib__count-label">' +
                            countLabel +
                            '</span></div>';
                    }

                    linkData = '';
                    if (resultData.data[i].link || viewMoreLink !== '') {
                        linkData =
                            '<div class="more-link more-link--without-border"><a tabindex="-1" aria-hidden="true" href="' +
                            viewMoreLink +
                            '" title="' +
                            viewMoreLable +
                            '">' +
                            viewMoreLable +
                            '<span class="right-arrow"><i aria-hidden="true" class="icon-Arrow-Stroke"></i></span></a></div>';
                    }

                    if (i === resultData.data.length - 1) {
                        appendData = '<div class="box-item__item">' + contentData + countData + '</div>' + linkData;
                    } else {
                        appendData = '<div class="box-item__item">' + contentData + countData + '</div>';
                    }
                    allData += appendData;
                }
            }
            if (allData !== '') {
                $target.replaceWith(allData);
            }
            // Empty message
            if (resultData.data.length === 0) {
                contribMetrics.drawEmptyContent(resultData, $target);
            }
        },
        drawContribMetricsItem: function (resultData, $target) {
            $target.append('<ul class="rlist--inline list-of-institutions truncate-list" data-lines="3"></ul>');
            resultData.data.forEach(function (element) {
                $target.find('ul').append(`<li>${element.content}</li>`);
            });

            // Empty message
            if (resultData.data.length === 0) {
                contribMetrics.drawEmptyContent(resultData, $target);
            }
        },

        /**
         * Empty message
         * @param resultData
         * @param $target
         */
        drawEmptyContent: function (resultData, $target) {
            // -- Condition if there should be anything drawn
            if (!resultData.showEmptyData) return;

            // -- Text only
            if (resultData.emptyText) {
                $target.append(`
                        <div class="box-item__item borderless">
                            <div class="content--empty">
                                <p>${resultData.emptyText}</p>
                            </div>
                        </div>
                    `);

                return;
            }
            // -- ICON
            // Default icon
            resultData.emptyIcon = resultData.emptyIcon || '/specs/products/acm/releasedAssets/images/dummy-user.svg';
            // Append empty message into block $target with icon
            $target.replaceWith(`
                    <div class="box-item__item borderless">
                        <div class="content--empty">
                            <img src="${resultData.emptyIcon}" alt="Currently Not Available icon" aria-hidden="true">
                            <p>Currently Not Available</p>
                        </div>
                    </div>
                `);
        },
    };

    UX.contribMetrics = contribMetrics; // add to global namespace
})();
