(function () {
    $(document).ready(function () {
        function lazyLoad() {
            $('.lazy-load').remove();
            $('.delayLoad').removeClass('delayLoad').addClass('delayedLoad');
        }

        setTimeout(lazyLoad, 10);
    });
})();
