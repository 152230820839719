import 'ui-core/widgets/export-citations/js/export-citations.js';

UX.exportCitation.init = function () {
    var gpt = document.createElement('script'),
        node;
    gpt.async = true;
    gpt.type = 'text/javascript';
    gpt.src = '//' + document.location.host + '/templates/jsp/_ux3/_acm/citeproc.min.js'; //prod site
    // gpt.src = `https://dl.acm.org/templates/jsp/_ux3/_acm/citeproc.min.js`;   // for local testing
    gpt.setAttribute('rel', 'preload');
    node = document.getElementsByTagName('script')[0];
    node.parentNode.insertBefore(gpt, node);

    UX.exportCitation.control();
};

UX.exportCitation.resetPopup = function ($el) {
    var $modal;
    UX.exportCitation.$target.html('');
    UX.exportCitation.responseFormat = {};
    UX.exportCitation.$toggle.prop('selectedIndex', 0);
    $modal = $($el.attr('data-target'));
    $modal.find('.copy__btn, .download__btn').addClass('disabled').parent('li').css('cursor', '');
};

UX.exportCitation.loadCiteProc = function ($el) {
    var selectedFormat, loadingText, action_server, params, targetCustomCSLFIle;
    UX.exportCitation.selectCiteStyle = $el.val();
    selectedFormat = $('[value=' + UX.exportCitation.selectCiteStyle + ']').data('format');
    targetCustomCSLFIle = 'custom-' + UX.exportCitation.selectCiteStyle;
    $('#export-warning').empty();
    UX.exportCitation.$wrapper = $el.closest('.csl-wrapper');
    UX.exportCitation.$target = UX.exportCitation.$wrapper.find('.csl-response');

    if (UX.exportCitation.selectCiteStyle) {
        UX.exportCitation.$wrapper
            .find('.copy__btn, .download__btn')
            .addClass('disabled')
            .parent('li')
            .css('cursor', '');
        //add spinner
        loadingText = 'Loading ' + UX.exportCitation.loadingAdditionalInfo + '... ';
        UX.exportCitation.$target.html('<span>' + loadingText + '</span>');

        action_server = '/action/exportCiteProcCitation';
        // var action_server = '/specs/ux3/widgets/export-citations/js/dummy-csl-metaData.json'; // dummy for UX3 testing
        //var action_server = '/specs/products/acm/widgets/export-citations/js/dummy-csl-metaData.json'; // dummy for ACM testing
        params = UX.exportCitation.setParams(targetCustomCSLFIle, selectedFormat);

        if (typeof UX.exportCitation.responseFormat[UX.exportCitation.selectCiteStyle] !== 'undefined') {
            UX.exportCitation.setContent(UX.exportCitation.responseFormat[UX.exportCitation.selectCiteStyle].content);
        } else {
            UX.exportCitation.performServerSideAjax(action_server, params, UX.exportCitation.selectCiteStyle);
        }

        UX.exportCitation.$wrapper.find('[name="dois"]').val(UX.exportCitation.dois);
        UX.exportCitation.$wrapper.find('[name="format"]').val(selectedFormat);
    }
};

UX.exportCitation.control = function () {
    $(document).on('click', '[data-target="#exportCitation"]', function () {
        UX.exportCitation.$trigger = $(this);
        UX.exportCitation.resetPopup(UX.exportCitation.$trigger);
        UX.exportCitation.dois = UX.exportCitation.collectDois(UX.exportCitation.$trigger).toString();
        UX.exportCitation.loadCiteProc(UX.exportCitation.$toggle);
    });

    $(document).on('change', '#citation-format', function () {
        UX.exportCitation.loadCiteProc($(this));
    });

    $(document).on('click', '.download__btn', function () {
        UX.exportCitation.downloadCiteProc($(this));
    });

    $('body').on('keyup', function (e) {
        let $closeElem = $('.icon-close_thin').not('.fv__close .icon-close_thin');
        if (e.keyCode === 27) {
            // if the clicked key is ESC
            $closeElem.click(); // click on the close modal element
        }
    });
};

UX.exportCitation.performServerSideAjax = function (url, params, selectCiteStyle) {
    $.ajax({
        // url: '/specs/products/acm/widgets/export-citations/js/dummy-csl-metaData.json', // dummy for ACM testing
        // type: "GET", // for testing only
        url: url,
        type: 'POST',
        data: params,
        async: true,
        success: function (response) {
            var itemIDs = [],
                citeproc,
                charMap,
                regex,
                html,
                result;
            UX.exportCitation.response = response;
            localStorage.setItem('exportResponse', JSON.stringify(response));
            UX.exportCitation.response.items.forEach(function (item) {
                for (let key in item) {
                    itemIDs.push(key);
                }
            });

            UX.exportCitation.responseFormat[selectCiteStyle] = UX.exportCitation.response;
            /*eslint-disable*/
            let citeprocSys = {
                retrieveLocale: function () {
                    return UX.exportCitation.response.locale;
                },
                retrieveItem: function (key) {
                    var index = itemIDs.indexOf(key);
                    return UX.exportCitation.response.items[index][key];
                },
            };

            citeproc = new UX.CSL.Engine(citeprocSys, UX.exportCitation.response.style);
            /*eslint-enable*/
            citeproc.opt.development_extensions.wrap_url_and_doi = false;
            citeproc.opt.development_extensions.field_hack = false;
            citeproc.fun.flipflopper.processTags = function () {
                return;
            };
            citeproc.opt.mode = 'text';
            citeproc.updateItems(itemIDs);
            result = citeproc.makeBibliography();

            if (selectCiteStyle === 'bibtex' && result.length > 0) {
                charMap = UX.exportCitation.normalize('/pb/widgets/citations/getChars').responseText;
                // let charMap = UX.exportCitation.normalize("/specs/products/acm/widgets/export-citations/js/getChars.json").responseText; // for local testing
                charMap = JSON.parse(charMap);
                regex = new RegExp('[\u00C0-\u024F\u1E00-\u1EFF]', 'g');

                html = '';
                result[1].forEach(function (item) {
                    var decoded;
                    item = item.replaceAll('&lt;', '&lessthan;');
                    item = item.replaceAll('&gt;', '&greaterthan;');

                    decoded = $('<textarea/>').html(item).text();
                    decoded = decoded.replaceAll('&lessthan;', '&lt;');
                    decoded = decoded.replaceAll('&greaterthan;', '&gt;');
                    html += decoded.replace(regex, function (c) {
                        return charMap[c];
                    });
                });
                UX.exportCitation.responseFormat[selectCiteStyle].content = html;
            } else {
                UX.exportCitation.responseFormat[selectCiteStyle].content = result[1];
            }
            UX.exportCitation.setContent(UX.exportCitation.responseFormat[selectCiteStyle].content);
        },
    });
};

UX.exportCitation.normalize = function (url) {
    return $.ajax({
        url: url,
        async: false,
    });
};

UX.exportCitation.collectDois = function ($el) {
    var doisArray = [];
    var multiDois = false;
    var isAjaxTocsAndSelectedAll = false;
    var isAjaxTocsAndNotSelectedAll = false;
    var isLimited = false;
    var AllDois;
    var addComma;
    var binderCitationCount;

    if ($el.hasClass('export-citation')) {
        multiDois = true;
    }

    if (
        $('.section-checkbox .section--dois').length &&
        $('.item-results__checkbox input[name="markall"]').prop('checked')
    ) {
        isAjaxTocsAndSelectedAll = true;
    }

    if (
        $('.section-checkbox .section--dois').length &&
        $('.item-results__checkbox input[name="markall"]').prop('checked') === false
    ) {
        isAjaxTocsAndNotSelectedAll = true;
    }

    //Check if there is a limit
    if ($('#exportCitation #doisLimitNumber').length && parseInt($('#exportCitation #doisLimitNumber').val()) !== -1) {
        UX.exportCitation.citationLimitNumber = parseInt($('#exportCitation #doisLimitNumber').val());
        isLimited = true;
    }

    if (multiDois) {
        if (isAjaxTocsAndSelectedAll) {
            AllDois = '';
            addComma = false;
            UX.exportCitation.$multiToggle = $('.section-checkbox .section--dois');
            UX.exportCitation.$multiToggle.each(function () {
                if (addComma) {
                    AllDois += ',' + $(this).val();
                } else {
                    AllDois += $(this).val();
                    addComma = true;
                }
            });

            doisArray = AllDois.split(',');
        } else if (isAjaxTocsAndNotSelectedAll) {
            AllDois = '';
            addComma = false;

            UX.exportCitation.$multiToggle = $('.section-checkbox .section--dois');
            UX.exportCitation.$multiToggle.each(function () {
                var $tocSection = $(this).closest('.toc__section'),
                    $insideSectionsCheckBoxes;

                if ($tocSection.find('.section-checkbox .issue-Item__checkbox').prop('checked') === true) {
                    if (addComma) {
                        AllDois += ',' + $(this).val();
                    } else {
                        AllDois += $(this).val();
                        addComma = true;
                    }
                } else {
                    $insideSectionsCheckBoxes = $tocSection.find('.issue-item-container .issue-Item__checkbox:checked');
                    $insideSectionsCheckBoxes.each(function () {
                        if (addComma) {
                            AllDois += ',' + $(this).attr('name');
                        } else {
                            AllDois += $(this).attr('name');
                            addComma = true;
                        }
                    });
                }
            });

            doisArray = AllDois.split(',');
        } else {
            UX.exportCitation.$multiToggle = $('.issue-item-container .issue-Item__checkbox:checked');
            UX.exportCitation.$multiToggle.each(function () {
                doisArray.push($(this).attr('name'));
            });
        }

        //Limit the number of dois if there is a limit
        if (isLimited && doisArray.length > UX.exportCitation.citationLimitNumber) {
            doisArray = doisArray.slice(0, UX.exportCitation.citationLimitNumber);
            UX.exportCitation.citationLimited = true;
        } else {
            UX.exportCitation.citationLimited = false;
        }

        //Add the additional loading message
        UX.exportCitation.loadingAdditionalInfo = doisArray.length + ' Citations ';
    } else {
        if ($el.find('[name="doiVal"]').length) {
            $el.find('[name="doiVal"]').each(function () {
                doisArray.push($(this).val());
            });
        } else if ($el.find('[name="binderCode"]').length) {
            $el.find('[name="binderCode"]').each(function () {
                doisArray.push($(this).val());
            });
        }
        if ($el.find('[name="binderCitationCount"]').length && $el.find('[name="binderCitationCount"]').val() > 1) {
            binderCitationCount = $el.find('[name="binderCitationCount"]').val();
            UX.exportCitation.loadingAdditionalInfo = binderCitationCount + ' Citations ';
        } else {
            UX.exportCitation.loadingAdditionalInfo = ' Citation ';
        }
    }
    return doisArray;
};

UX.exportCitation.setParams = function (targetCustomCSLFIle, selectedFormat) {
    if (
        UX.exportCitation.$trigger.find('[name="doiVal"]').length ||
        UX.exportCitation.$trigger.is('.export-citation')
    ) {
        return {dois: UX.exportCitation.dois, targetFile: targetCustomCSLFIle, format: selectedFormat};
    } else if (UX.exportCitation.$trigger.find('[name="binderCode"]').length) {
        return {binderCode: UX.exportCitation.dois, targetFile: targetCustomCSLFIle, format: selectedFormat};
    }
};

UX.exportCitation.getWarningMessage = function (message) {
    return '<p class="alert-msg--warning"><i class="icon-warning-triangle" aria-hidden="true"></i>' + message + '</p>';
};

UX.exportCitation.setContent = function (content) {
    var citationLimitMsg;
    if (typeof content === 'string') {
        content = content.replaceAll('& ', '\\& ');
        content = content.replaceAll('%', '\\%');
    }
    UX.exportCitation.$target.html(content);
    UX.exportCitation.$wrapper.find('[name="content"]').val(UX.exportCitation.$target.text());
    UX.exportCitation.$wrapper
        .find('.copy__btn, .download__btn')
        .removeClass('disabled')
        .parent('li')
        .css('cursor', 'pointer');
    if (UX.exportCitation.responseFormat[UX.exportCitation.selectCiteStyle].warning) {
        UX.exportCitation.$wrapper
            .find('#export-warning')
            .html(
                UX.exportCitation.getWarningMessage(
                    UX.exportCitation.responseFormat[UX.exportCitation.selectCiteStyle].warning
                )
            );
    }
    if (UX.exportCitation.citationLimited) {
        citationLimitMsg =
            'Citation export limit exceeded... the first ' +
            UX.exportCitation.citationLimitNumber +
            ' selection will be exported.';
        UX.exportCitation.$wrapper.find('#export-warning').html(UX.exportCitation.getWarningMessage(citationLimitMsg));
    }
};

UX.exportCitation.downloadCiteProc = function ($el) {
    let downloadContainer = UX.exportCitation.$target;
    var downloadData = '';
    var downloadMultiText;

    if (downloadContainer.is('.csl-response')) {
        downloadContainer = UX.exportCitation.$target.find('.csl-right-inline');
        downloadData = downloadContainer.html().replaceAll('&amp;', '&');
        downloadData = downloadData.replaceAll('&lt;', '<');
        downloadData = downloadData.replaceAll('&gt;', '>');

        if (downloadContainer.length > 1) {
            downloadMultiText = '';
            downloadContainer.each(function () {
                downloadMultiText += $(this).html() + '\n\n';
            });
            downloadData = downloadMultiText;
        }
    }

    $el.attr(
        'href',
        'data:' +
            UX.exportCitation.responseFormat[UX.exportCitation.selectCiteStyle].contentType +
            ';charset=utf-8,' +
            encodeURIComponent(downloadData)
    );
    $el.attr(
        'download',
        UX.exportCitation.responseFormat[UX.exportCitation.selectCiteStyle].fileName +
            '.' +
            UX.exportCitation.responseFormat[UX.exportCitation.selectCiteStyle].suffix
    );
};
