import './export-citations';

(function () {
    var exportCitationPopup = {
        exportCitationDownload: localStorage.getItem('exportCitationDownload'),
        exportDownloadReady: document.getElementById('exportDownloadReady'),
        exportDownloadNotReady: document.getElementById('exportDownloadNotReady'),
        exportDownloadExpired: document.getElementById('exportDownloadExpired'),
        popup: document.querySelectorAll('.searchCiteExport-popup'),
        downloadButton: document.querySelector('#exportDownloadReady .searchCiteExport-popup__close'),
        isClosed: false,

        init: function () {
            exportCitationPopup.insertCSL();
            exportCitationPopup.popupToggle();
            exportCitationPopup.addCloseEvents();
        },

        popupToggle: function (id) {
            if (exportCitationPopup.exportCitationDownload || id) {
                exportCitationPopup.isDataReady(id);
            }
        },

        // Clean up this function as this mandatory solution for ACM MR
        isDataReady: function (id) {
            let currentResponse = {...JSON.parse(localStorage.getItem('exportResponse'))};

            $.ajax({
                url: '/pb/acm/citations/checkById',
                type: 'POST',
                data: {id: exportCitationPopup.exportCitationDownload || id},
                success: function (response) {
                    if (UX.CSL) {
                        const jsonResponse = $.parseJSON(response);
                        if (!jsonResponse.inProgress) {
                            if (document.querySelector('.downloadBtn')) {
                                document.querySelector('.warning-message').classList.remove('hidden');
                            }

                            currentResponse.items = jsonResponse.items;
                            exportCitationPopup.citeProcess(currentResponse);
                            exportCitationPopup.setContent(currentResponse);
                            exportCitationPopup.showDownloadReadyPopup();
                            exportCitationPopup.downloadButton.focus();
                            clearInterval(intervalChecker);/*eslint-disable-line*/
                        } else if (jsonResponse.existed) {
                            exportCitationPopup.exportDownloadNotReady.classList.remove('hidden');
                            exportCitationPopup.popup[0].classList.add('fadeInUp');
                            exportCitationPopup.exportDownloadNotReady.querySelector('.icon-cancel-bold').focus();
                        }
                        if (!jsonResponse.existed) {
                            if (document.querySelector('.downloadBtn')) {
                                document.querySelector('.warning-message').classList.remove('hidden');
                            }
                            exportCitationPopup.exportCitationDownload = null;
                            exportCitationPopup.exportDownloadNotReady.classList.add('hidden');
                            exportCitationPopup.exportDownloadNotReady.classList.remove('fadeInUp');
                            exportCitationPopup.exportDownloadExpired.classList.add('fadeInUp');
                            exportCitationPopup.exportDownloadExpired.classList.remove('hidden');
                            clearInterval(intervalChecker);/*eslint-disable-line*/
                        }
                    }
                },
            });

            const intervalChecker = setInterval(function () {
                $.ajax({
                    url: '/pb/acm/citations/checkById',
                    type: 'POST',
                    data: {id: exportCitationPopup.exportCitationDownload || id},
                    success: function (response) {
                        const jsonResponse = $.parseJSON(response);
                        if (!jsonResponse.inProgress) {
                            if (document.querySelector('.downloadBtn')) {
                                document.querySelector('.warning-message').classList.remove('hidden');
                            }

                            currentResponse.items = jsonResponse.items;
                            exportCitationPopup.citeProcess(currentResponse);
                            exportCitationPopup.setContent(currentResponse);
                            exportCitationPopup.showDownloadReadyPopup();
                            exportCitationPopup.downloadButton.focus();
                            clearInterval(intervalChecker);
                        }
                        if (
                            exportCitationPopup.exportDownloadNotReady.classList.contains('hidden') &&
                            exportCitationPopup.exportDownloadReady.classList.contains('hidden') &&
                            jsonResponse.existed &&
                            !exportCitationPopup.isClosed
                        ) {
                            exportCitationPopup.exportDownloadNotReady.classList.remove('hidden');
                            exportCitationPopup.popup[0].classList.add('fadeInUp');
                            exportCitationPopup.exportDownloadNotReady.querySelector('.icon-cancel-bold').focus();
                        }
                        if (!jsonResponse.existed) {
                            if (document.querySelector('.downloadBtn')) {
                                document.querySelector('.warning-message').classList.remove('hidden');
                            }
                            exportCitationPopup.exportCitationDownload = null;
                            exportCitationPopup.exportDownloadNotReady.classList.add('hidden');
                            exportCitationPopup.exportDownloadNotReady.classList.remove('fadeInUp');
                            exportCitationPopup.exportDownloadExpired.classList.add('fadeInUp');
                            exportCitationPopup.exportDownloadExpired.classList.remove('hidden');
                            clearInterval(intervalChecker);
                        }
                    },
                });
            }, 5000);
        },

        insertCSL: function () {
            let gpt = document.createElement('script'),
                node;
            gpt.async = true;
            gpt.type = 'text/javascript';
            gpt.src = '//' + document.location.host + '/templates/jsp/_ux3/_acm/citeproc.min.js'; //prod site
            // gpt.src = `https://dl.acm.org/templates/jsp/_ux3/_acm/citeproc.min.js`;   // for local testing
            gpt.setAttribute('rel', 'preload');
            node = document.getElementsByTagName('script')[0];
            node.parentNode.insertBefore(gpt, node);
        },

        closePopup: function () {
            if (exportCitationPopup.exportDownloadNotReady.classList.contains('hidden')) {
                if (document.querySelector('.downloadBtn')) {
                    UX.exportCitationAll.downloadBtn.classList.remove('disabled');
                    document.querySelector('.warning-message').classList.add('hidden');
                }
                localStorage.removeItem('exportCitationDownload');
            }
            exportCitationPopup.isClosed = true;
            exportCitationPopup.exportDownloadReady.classList.add('hidden');
            exportCitationPopup.exportDownloadNotReady.classList.add('hidden');
            exportCitationPopup.exportDownloadExpired.classList.add('hidden');
            exportCitationPopup.exportDownloadReady.classList.remove('fadeInUp');
            exportCitationPopup.exportDownloadNotReady.classList.remove('fadeInUp');
            exportCitationPopup.exportDownloadExpired.classList.remove('fadeInUp');
        },

        addCloseEvents: function () {
            document.querySelector('.searchCiteExport-popup__close').addEventListener('click', () => {
                this.closePopup();
            });

            UX.exportCitationPopup.popup.forEach(elm => {
                let closeIcon = elm.querySelector('.icon-cancel-bold');

                closeIcon.addEventListener('click', () => {
                    this.closePopup();
                });

                // Close popup from keyboard
                closeIcon.addEventListener('keyup', e => {
                    if (e.keyCode === 13 || e.keyCode === 27) {
                        this.closePopup();
                    }
                });
            });
        },

        showDownloadReadyPopup: function () {
            exportCitationPopup.exportDownloadNotReady.classList.add('hidden');
            exportCitationPopup.exportDownloadNotReady.classList.remove('fadeInUp');
            exportCitationPopup.exportDownloadReady.classList.add('fadeInUp');
            exportCitationPopup.exportDownloadReady.classList.remove('hidden');
        },

        addDownloadLink: function (currentResponse, downloadData) {
            exportCitationPopup.downloadButton.setAttribute(
                'href',
                'data:' + currentResponse.contentType + ';charset=utf-8,' + encodeURIComponent(downloadData)
            );

            exportCitationPopup.downloadButton.setAttribute(
                'download',
                currentResponse.fileName + '.' + currentResponse.suffix
            );
        },

        setContent: function (currentResponse) {
            $('.csl-all-response').html(currentResponse.content);

            let downloadContainer = $('.csl-all-response');
            let downloadData = '';
            let downloadMultiText;

            if (downloadContainer.is('.csl-all-response')) {
                downloadContainer = downloadContainer.find('.csl-right-inline');
                downloadData = downloadContainer.html().replaceAll('&amp;', '&');

                if ($('[name="format"]').val() === 'bibTex') {
                    downloadData.replaceAll('%', '\\%');
                }

                if (downloadContainer.length > 1) {
                    downloadMultiText = '';
                    downloadContainer.each(function () {
                        downloadMultiText += $(this).html() + '\n\n';
                    });
                    downloadData = downloadMultiText;
                }
            }
            exportCitationPopup.addDownloadLink(currentResponse, downloadData);
        },

        citeProcess: function (currentResponse) {
            let itemIDs = [],
                citeproc,
                charMap,
                regex,
                html,
                result;

            currentResponse.items.forEach(function (item) {
                for (let key in item) {
                    itemIDs.push(key);
                }
            });
            /*eslint-disable*/
            let citeprocSys = {
                retrieveLocale: function () {
                    return currentResponse.locale;
                },
                retrieveItem: function (key) {
                    let index = itemIDs.indexOf(key);
                    return currentResponse.items[index][key];
                },
            };
            citeproc = new UX.CSL.Engine(citeprocSys, currentResponse.style);
            /*eslint-enable*/
            citeproc.opt.development_extensions.wrap_url_and_doi = false;
            citeproc.opt.development_extensions.field_hack = false;
            citeproc.fun.flipflopper.processTags = function () {
                return;
            };
            citeproc.opt.mode = 'text';
            // // -------------------------- remove this ---------------------/
            // const slicedArray = itemIDs.slice(0, 80);
            citeproc.updateItems(itemIDs);
            result = citeproc.makeBibliography();

            if (UX.exportCitation.selectCiteStyle === 'bibtex' && result.length > 0) {
                charMap = UX.exportCitation.normalize('/pb/widgets/citations/getChars').responseText;
                charMap = JSON.parse(charMap);
                regex = new RegExp('[\u00C0-\u024F\u1E00-\u1EFF]', 'g');

                html = '';
                result[1].forEach(function (item) {
                    let decoded;
                    item = item.replaceAll('&lt;', '&lessthan;');
                    item = item.replaceAll('&gt;', '&greaterthan;');

                    decoded = $('<textarea/>').html(item).text();
                    decoded = decoded.replaceAll('&lessthan;', '&lt;');
                    decoded = decoded.replaceAll('&greaterthan;', '&gt;');
                    html += decoded.replace(regex, function (c) {
                        return charMap[c];
                    });
                });
                currentResponse.content = html;
            } else {
                currentResponse.content = result[1];
            }
        },
    };

    UX.exportCitationPopup = exportCitationPopup;
})();
