(function () {
    var supplementalMaterials = {
        $mainSuppContainer: $('.article__supplemental-material'),
        $suppDataContainer: $('.supplFiles--data'),

        init: function () {
            supplementalMaterials.control();
        },

        control: function () {
            if (supplementalMaterials.$suppDataContainer.length) {
                supplementalMaterials.$suppDataContainer.find('.supplFile--data').each(function () {
                    var $this = $(this);
                    var thisID = $this.attr('id').toString();
                    var itemLabel = $this.find('.supplFile--label').text();
                    var itemCaption = $this.find('.supplFile--caption').text();
                    var $suppItem = supplementalMaterials.$mainSuppContainer.find(
                        '.issue-downloads__item__details[data-id="' + thisID + '"]'
                    );

                    if ($suppItem.length) {
                        $suppItem.find('.file-name').html(itemLabel);
                        $suppItem.find('.file-name-link').attr('title', itemLabel);
                        $suppItem.find('.info--text').html(itemCaption);
                    }
                });
            }
        },
    };

    UX.supplementalMaterials = supplementalMaterials; // add to global namespace
})();
