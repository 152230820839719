import createBinder from '../templates/binder-list/create-binder-client.jade';

(function () {
    var binders = UX.binders,
        $body = $('body');

    binders._create = {
        $binderName: $('.binder-create-form__name'),
        $binderDescription: $('#binderDescription'),
        $binderTable: $('.binder__list'),
        $toggle: $('.binder-create-form__create'),
        successMsg:
            '<p class="alert-msg--success"><i class="icon-checkmark"></i>You have <b>successfully</b> created a new binder.</p>',
        rowElem: '',
        action: null,

        init: function () {
            $(document).on('input', '.binder-create-form__name', function () {
                var $this = $(this);
                binders._create.$toggle = $this.closest('.binder-create-form').find('.binder-create-form__create');
                if ($this.val().trim() === '') {
                    binders._create.$toggle.attr('disabled', 'disabled');
                } else {
                    binders._create.$toggle.removeAttr('disabled');
                }
            });

            binders._create.control();
        },
        control: function () {
            $body.on('click', '.binder__create-btn', function () {
                binders._create.resetCreateForm();
            });

            //used to create a new binder inside save to binder list
            $body.on('click', '.binder__create-submit', function (e) {
                var $this = $(this);
                var $icon = $this.find('.icon-plus-light');

                e.preventDefault();
                if ($this.hasClass('action-processing')) {
                    return false;
                }
                $this.addClass('action-processing');
                $icon.removeClass('icon-plus-light');
                $icon.addClass('icon-spinner8 spin');

                binders._create.newBinder($this, 'create', true);
            });

            $body.on('click', '.binder__append__btn', function (e) {
                var $this = $(this);
                e.preventDefault();

                if ($this.closest('#createBinder').length) {
                    binders._create.action = 'create';
                } else {
                    binders._create.action = 'duplicate';
                }

                binders._create.newBinder($this, binders._create.action, false);

                if (binders._create.action === 'create') {
                    $('#createBinder').modal('hide').find('input').val('');
                    binders._create.$toggle.attr('disabled', 'disabled');
                }

                setTimeout(function () {
                    binders._create.$binderTable.find('.addedRow').removeClass('addedRow');
                    $('.binder__alerts__msg').html('');
                }, 3000);
            });

            $body.on('click', '.pdf--create', function (e) {
                e.preventDefault();
                if (!$('.pdf--create.disable').length) {
                    $(this).addClass('load');
                    binders._create.pdf($(this));
                }
            });
        },

        pdf: function ($elem) {
            var link = $elem.data('pdflink');

            $.ajax({
                url: link,
                type: 'GET',
                success: function () {
                    $('#createPDF .success--message').show();
                    $('#createPDF .fail--message  ').hide(function () {
                        $elem.removeClass('load');
                        $('#createPDF').modal('show');
                    });
                },
                error: function (data) {
                    if (typeof data.responseJSON.url !== 'undefined') {
                        window.location.href = data.responseJSON.url;
                    }
                    $('#createPDF .fail--message').show();
                    $('#createPDF .success--message  ').hide(function () {
                        $elem.removeClass('load');
                        $('#createPDF').modal('show');
                    });
                },
            });

            setTimeout(function () {
                $('.binder__alerts__msg').html('');
            }, 30000);
        },
        newBinder: function ($elem, action, appendToSaveList) {
            var params = '',
                $createForm,
                $binderNameInput,
                link,
                binderDescriptionText;

            if (action === 'create') {
                $createForm = $elem.parents('.binder-create-form');
                $binderNameInput = $createForm.find('.binder-create-form__name');
                binderDescriptionText =
                    binders._create.$binderDescription.val() !== ''
                        ? binders._create.$binderDescription.val()
                        : 'No description';

                params = {binderName: $binderNameInput.val(), binderDescription: binderDescriptionText};
                binders._create.successMsg =
                    '<p class="alert-msg--success"><i class="icon-checkmark"></i>You have <b>successfully</b> created a new binder.</p>';
            } else {
                binders._create.successMsg =
                    '<p class="alert-msg--success"><i class="icon-checkmark"></i>You have <b>successfully</b> Duplicate the binder.</p>';
            }

            link = $elem.attr('data-append-newBinder-link'); //?binderName={testName}&binderDescription={testDesc}
            // var link = '/specs/products/acm/widgets/binder-list/templates/demo/create.json'; //test link
            $.ajax({
                url: link,
                type: 'GET',
                data: params,
                success: function (data) {
                    if (data) {
                        binders._create.resetCreateForm($elem);
                        if (appendToSaveList) {
                            binders._create.appendToBinderList($elem, data);
                        } else {
                            binders.generateBinder(data);
                        }
                        $('.binder__alerts__msg').html(binders._create.successMsg);
                    }
                    if ($('.modal-backdrop.in').length) {
                        $('.modal-backdrop.in').remove();
                    }
                },
                error: function () {
                    binders.removeButtonAnimation($elem);
                    if ($('.modal-backdrop.in').length) {
                        $('.modal-backdrop.in').remove();
                    }
                },
            });
        },

        resetCreateForm: function ($elem = {}) {
            var $createForm;
            $('.binder-create-form__name, .binder-create-form__desc').val('');
            $('.binder-create-form__create').attr('disabled', 'disabled');
            if ($elem.length) {
                $createForm = $elem.closest('.binder-create-form');
                if ($createForm.hasClass('binder__create-mini-form')) {
                    $createForm.find('.binder__create-cancel').trigger('click');
                }
            }
            UX.charCount.init();
        },
        appendToBinderList: function ($elem, data) {
            var $message, view, $notificationTarget;
            $elem
                .parents('.saveToBinders-list')
                .find('.saveToBinders-list__items .sb-content')
                .prepend(
                    '<li class="input-group"><label class="checkbox--primary" ' +
                        'tabindex="0"><input type="checkbox" name="binderCode" ' +
                        'value="' +
                        data.binderCode +
                        '" ' +
                        '><span class="label-txt">' +
                        data.binderName +
                        '</span></label></li>'
                );

            $message = $(binders._create.successMsg);
            view = 'list';
            if ($elem.parents('#saveToBinders-popup').length) {
                view = 'modal';
            }
            $notificationTarget = $elem.parents('.saveToBinders-list').find('.binder-list-alerts');
            $elem.parents('.saveToBinders-list').find('.input-group input').first().trigger('click');
            binders.removeButtonAnimation($elem);
            UX.saveBinder.showNotification($message, view, $notificationTarget);
        },
    };
    binders.generateBinder = function (data, shared) {
        const binderLink = '/action/showBinder?binderCode=' + data.binderCode;
        const trs = binders._create.$binderTable.find('tbody tr').length;
        const removeAction = shared
            ? '/action/removeBinder?binderCode=' + data.binderCode
            : '/action/deleteBinder?binderCode=' + data.binderCode;
        const removeIcon = shared
            ? '<i class="icon-cancel-bold" aria-hidden="true"></i> <span>Remove Binder</span>'
            : '<i class="icon-delete" aria-hidden="true"></i> <span> Delete Binder</span>';
        const removeLabel = shared ? 'Remove this shared binder from your list.' : 'Delete Binder';
        const folderIcon = shared ? 'icon-share-folder' : 'icon-folder';
        const descText = data.binderDescription ? data.binderDescription : 'No description';
        const descClass = descText === 'No description' ? 'binder__list__no-description' : '';

        const fullData = {
            ...data,
            binderLink,
            trs,
            removeAction,
            removeIcon,
            removeLabel,
            folderIcon,
            descText,
            descClass,
        };

        binders._create.rowElem = createBinder({fullData});
        const $binderElem = $(binders._create.rowElem);

        if (shared) {
            $binderElem.find('.shareList').addClass('disable');
            $binderElem
                .find('.shareList')
                .attr('data-title', 'You cannot share an already shared binder created by someone else.');
        }
        binders._create.$binderTable.find('.empty--msg').remove();
        binders._create.$binderTable.find('tbody').prepend($binderElem);
        UX.simpleTooltip.init();
    };
    binders.removeButtonAnimation = function ($button) {
        $button.find('.icon-spinner8').addClass('icon-plus-light');
        $button.find('.icon-spinner8').removeClass('icon-spinner8 spin');
        $button.removeClass('action-processing');
    };
})();
