(function () {
    var $bannerTilte = $('.banner__holder .title '),
        titleLength = $bannerTilte.text().length,
        titleClass;
    if ($bannerTilte.length) {
        switch (true) {
            case titleLength > 34:
                titleClass = 'lines-3';
                break;
            case titleLength > 17:
                titleClass = 'lines-2';
                break;
            default:
                titleClass = 'lines-1';
        }
        $bannerTilte.addClass(titleClass);
    }
    $('.c-hero__form,.quick-search--form').submit(function () {
        if (!$(this).find('.quick-search__input').val().trim().length) {
            return false;
        }
    });
})();
