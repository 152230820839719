(function () {
    var bannerAccess = {
        init: function () {
            bannerAccess.control();
        },
        control: function () {
            $(document).ready(function () {
                $('a,button').removeClass('loading__link');
            });
        },
    };

    UX.bannerAccess = bannerAccess; // add to global namespace
})();
