(function () {
    var dropdownSearch = {
        searchTargetSelector: 'li',
        $searchInput: $('.search-input'),
        $searchTarget: $('.search-target'),
        $searchNoResults: $('.search-no-results'),

        init: function () {
            dropdownSearch.$searchInput.keyup(function () {
                dropdownSearch.Search();
            });
        },

        Search: function () {
            var searchInputVal = dropdownSearch.$searchInput.val().toUpperCase();
            var $searchTarget = dropdownSearch.$searchTarget.find(dropdownSearch.searchTargetSelector);
            var textMatch = 0;

            $searchTarget.each(function () {
                var $thisTarget = $(this);
                var thisTargetVal = $(this).text().toUpperCase();

                if (thisTargetVal.includes(searchInputVal)) {
                    $thisTarget.css('display', 'inline-block');
                    textMatch += 1;
                } else {
                    $thisTarget.css('display', 'none');
                }
            });

            if (dropdownSearch.$searchNoResults.length) {
                if (textMatch === 0) {
                    dropdownSearch.$searchNoResults.css('display', 'inline-block');
                } else {
                    dropdownSearch.$searchNoResults.css('display', 'none');
                }
            }
        },
    };

    UX.dropdownSearch = dropdownSearch;
})();
