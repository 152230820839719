export default class ConferenceProceedings {

    private proceedingsConf;
    private proceedingsConfWrapper = document.querySelectorAll('.conference__workshops ul');
    private proceedingsParentAccordion = document.querySelectorAll<HTMLElement>('.proceedings-browse__control > .accordion-tabbed__control-title');

    public initialize = (): void => {
        this.setProceeding();
        this.clickListeners();
        this.addSubtreeModification()
    };

    private openAccordion = (e, El): void => {
        e.preventDefault();
        if (!El.classList.contains('read-less')) {
            let confName = El.getAttribute('data-code'), // get the name of the workshop that got clicked
                confInitial = confName.charAt(0).toUpperCase(); // get the first letter of the workshop that got clicked

            this.proceedingsParentAccordion.forEach(element => {
                if (element.querySelector('.title').textContent === confInitial) {
                    let grandChildrenAccordion = element.closest('.accordion-tabbed__tab').querySelectorAll<HTMLElement>('.proceedings-browse__content .title');

                    // Open intended parent accordion
                    if (!element.closest('li').classList.contains('js--open')) {
                        element.click();
                    }

                    //Open intended child accordion
                    Array.from(grandChildrenAccordion, grandChild => {
                        const isAccordionClosed = grandChild.closest(".accordion-tabbed__control").getAttribute("aria-expanded")==="false";
                        if (grandChild.textContent.toLowerCase().includes(confName) && isAccordionClosed) {
                            grandChild.closest<HTMLElement>('.accordion-tabbed__control').click();
                        }
                    })

                    // Scroll into the opened accordion
                    element.scrollIntoView({behavior: 'smooth', block: 'center'});
                }
            });
        }
    }


    // event listeners
    // todo: use the ui-utils (domUtils) once we upgrade to 1.48
    private clickListeners = () => {
        this.proceedingsConf.forEach(element => {
            element.addEventListener('click', (e) => {
                if (this.proceedingsConf.length > 0 && e.target === element) {
                    this.openAccordion(e, element);
                }
                else {
                    this.setProceeding();
                    this.openAccordion(e, e.target);
                }
            });
        })
    };

    // move to ui-utils
    private addSubtreeModification() {
        // Observer config
        const config = {
            childList: true,
            subtree: true
        };

        // detects changes on the DOM in order to add events listeners to the newly added elements (the ones that were truncated).
        const observer = new MutationObserver((mutationsList) => {
            let mutateTarget = mutationsList[0].target; // we get the element that was truncated.
            this.setProceeding(mutateTarget); // we pass it to setProceeding to update the event listeners for the element that was truncated only
            this.clickListeners(); // we call the clickListeners which add the event listeners to the newly added elements.
        });

        // adds the observer to the elements that could have new elements added to them
        this.proceedingsConfWrapper.forEach(element => {
            observer.observe(element, config);
        })
    }

    // determines if the element we want to add the listener to is dynamically added (was truncated) or an element that was already on the page.
    // without this part the observer will be called on all (.conf-link) instances which causes some elements to have multiple event listeners that does the same job.
    setProceeding(target?) {
        if (typeof target !== 'undefined') {
            this.proceedingsConf = Array.from(target.querySelectorAll('.conf-link')); // case 1: The element was truncated. We get the truncated parent from the MutationObserver.
        }
        else {
            this.proceedingsConf = Array.from(document.querySelectorAll('.conf-link')); // case 2: The element is an original part of the page (wasn't truncated).
        }
    }
}
