(function () {
    var loader = {
        init: function () {
            loader.additionalController();

            if ($('.cloudflare-stream-container').length) {
                $.getScript('https://embed.cloudflarestream.com/embed/r4xu.fla9.latest.js');
            }
        },

        additionalController: function () {
            $(document).ready(function () {
                let appleDevices = ['iPad', 'iPhone'];
                let $pubNote = $('.publication-note.hidden'); // the element that we want to clone to the right side pill, we need this to check if the element exists before cloning
                let $publisher = $('.section__content.publisher'); // the element we want to append it after
                let pubContent = $('.publication-note.hidden p').html(); // the inside of the content we want to clone
                let pubNoteContianer = `    <div class="section__separator pub__note">
                            <h3 class="left-bordered-title">Publication Notes</h3>
                            <div class="section__content">
                                <span class="cloned">${pubContent}</span>
                            </div>
                        </div>
                    `; // the cloned structure
                if ($pubNote.length) {
                    $publisher.closest('.section__separator').after(pubNoteContianer);
                }
                if ($('.contrib-metrics-bibliometrics .accordion-tabbed__control').length < 1) {
                    $('.contrib-metrics-wrapper .expand-all').hide();
                }

                if ($('.citation .loa').find('li.count-list').length === 0) {
                    $('.citation .loa').addClass('noTruncation');
                }

                if (appleDevices.indexOf(navigator.platform) > -1) {
                    $('body').addClass('ios');
                }
            });

            $('.colored-block__overlay').hover(function () {
                $(this).toggleClass('relativeElement');
            });
        },

        get: {},
    };

    UX.loader = loader; // add to global namespace
})();
