(function () {
    var recentAwardWinners = {
        ajaXUrl: null,
        pbContext: encodeURIComponent($('meta[name=pbContext]').attr('content')),

        init: function () {
            recentAwardWinners.winnersAjax();
        },

        winnersAjax: function () {
            recentAwardWinners.ajaXUrl =
                $('.recentAwardAjax').attr('data-ajaxlink') + '&pbContext=' + recentAwardWinners.pbContext;
            $.ajax({
                url: recentAwardWinners.ajaXUrl,
                type: 'GET',
                success: function (data) {
                    let JSONData;
                    if (JSON.parse(data).data.results.length > 0) {
                        JSONData = JSON.parse(data).data.results[0].content;
                    } else {
                        JSONData =
                            '<div class="content--empty"><img src="/specs/products/acm/releasedAssets/images/dummy-user.svg" alt="Currently Not Available icon" aria-hidden="true"><p>Currently Not Available</p></div>';
                        $('.recentAwardAjaxContainer .more-link').hide();
                        $('.recentAwardAjaxContainer .box-item__item').addClass('no-border');
                    }

                    $('.recentAwardAjax').append(JSONData);
                    UX.genericTruncate.truncate.text($(".truncate-text:not('.trunc-done')"));
                    $('.recentAwardAjaxContainer .more-link a').each(function () {
                        let href = $(this).parents('.box-item').find('.creative-work__title a').attr('href');
                        $(this).attr('href', href);
                    });
                },
            });
        },
    };

    UX.recentAwardWinners = recentAwardWinners; // add to global namespace
})();
