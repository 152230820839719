(function () {
    var scrollIndicator = {
        init: function () {
            scrollIndicator.control();
        },
        control: function () {
            $('.c-hero__scroll-indicator').on('click', function (e) {
                var $parentSection = $('.c-hero-wrapper').length ? $('.c-hero-wrapper') : this.closest('.section'),
                    $nextSection = $parentSection.next('.section').length
                        ? $parentSection.next('.section')
                        : $parentSection.next(),
                    scrollToOffset = $parentSection !== null || $nextSection !== null ? $nextSection.offset().top : 0;
                e.preventDefault();
                e.stopPropagation();

                $('html, body').animate(
                    {
                        scrollTop: scrollToOffset,
                    },
                    800
                );
            });
        },
    };

    UX.scrollIndicator = scrollIndicator; // add to global namespace
})();
