(function () {
    var savedSearches = {
        $changeFrequencyBtn: '.saved-searches .frequency-change',
        $changeFrequencyPopupForm: $('#modal-changeFrequency #changeFrequencyForm'),

        init: function () {
            savedSearches.control();
        },

        control: function () {
            var $removeOption = $("a[data-action-type='saved-search--remove']"),
                $runOption = $("a[data-action-type='saved-search--run']");
            if ($('#isReadOnlyMode').length) {
                $removeOption
                    .attr('href', 'javascript:void(0)')
                    .attr('title', 'Delete temporarily unavailable - maintenance in progress')
                    .addClass('disabledBtn');

                $runOption
                    .attr('href', 'javascript:void(0)')
                    .attr('title', 'Run temporarily unavailable - maintenance in progress')
                    .addClass('disabledBtn');
            }

            savedSearches.changeFrequency();
        },

        changeFrequency: function () {
            $(document).on('click', savedSearches.$changeFrequencyBtn, function () {
                var $this = $(this),
                    frequency,
                    $frequencyInfo,
                    ssaID,
                    updateUrl,
                    action;

                if (typeof $this !== 'undefined' && $this.length) {
                    frequency = $this.attr('data-frequency');
                    $frequencyInfo = $this.parent().parent().find('.frequencyInfo-td');

                    if (typeof $frequencyInfo !== 'undefined' && $frequencyInfo.length) {
                        ssaID = $frequencyInfo.find('#ssaID').val();
                        updateUrl = $frequencyInfo.find('#updateUrl').val();
                        action = $frequencyInfo.find('#action').val();
                        savedSearches.updateChangeFrequencyPopup(frequency, ssaID, updateUrl, action);
                    }
                }
            });

            savedSearches.$changeFrequencyPopupForm.find('.input-group input[type="radio"]').on('change', function () {
                var frequency = $(this).val();
                savedSearches.$changeFrequencyPopupForm.find('#frequency').val(frequency);
            });
        },

        updateChangeFrequencyPopup: function (frequency, ssaID, updateUrl, action) {
            if (typeof frequency !== 'undefined' && frequency) {
                savedSearches.$changeFrequencyPopupForm.find('.input-group input[type="radio"]').each(function () {
                    var $this = $(this);
                    if ($this.val() === frequency) {
                        $this.prop('checked', true);
                    }
                });
                savedSearches.$changeFrequencyPopupForm.find('#frequency').val(frequency);
            }
            if (typeof ssaID !== 'undefined' && ssaID) {
                savedSearches.$changeFrequencyPopupForm.find('#ssaID').val(ssaID);
            }
            if (typeof action !== 'undefined' && action) {
                savedSearches.$changeFrequencyPopupForm.find('#action').val(action);
            }
            if (typeof updateUrl !== 'undefined' && updateUrl) {
                savedSearches.$changeFrequencyPopupForm.attr('action', updateUrl);
            }
        },
    };

    UX.savedSearches = savedSearches; // add to global namespace
})();
