import 'ui-core/components/modal/js/modal.js';

UX.modal.$body = $('body');
UX.modal.$window = $(window);
UX.modal.shift = 16;

UX.modal.control = function () {
    UX.modal.$body.on('click', '[data-toggle="modal"]', function (e) {
        e.preventDefault();
        UX.modal.additionalController(this);
    });

    UX.modal.$body.on('click', '.modal', function (e) {
        var target = $(e.target);
        if (!target.is('.modal__dialog') && !target.closest('.modal__dialog').length > 0) {
            UX.modal.on.hide();
        }
    });

    UX.modal.$body.on('click', '[data-dismiss="modal"]', function () {
        UX.modal.on.hide();
    });

    $('.modal').on('keydown', UX.modal.focusCycle.bind(UX.modal));

    UX.modal.$window.on('keyup', function (e) {
        if (e.keyCode === UX.modal.shift) {
            UX.modal.revers = false;
        }
    });

    $('.modal input:not(:radio)').on('change', function () {
        UX.modal.additionalController();
    });
};

UX.modal.additionalController = function ($that) {
    UX.modal.$toggle = $($that);
    UX.modal.$target = $(UX.modal.$toggle.data('target'));
    UX.modal.$close = UX.modal.$target.find('.close');
    UX.modal.items = UX.modal.$target.find('a, button, input');
    UX.modal.items.each(function (index) {
        if (index === UX.modal.items.length - 1) {
            if (!$(this).is('[disabled=disabled]')) {
                UX.modal.lastItem = $(this);
            } else {
                UX.modal.items.each(function (index) {
                    if (index === UX.modal.items.length - 2) {
                        UX.modal.lastItem = $(this);
                    }
                });
            }
        }
    });
    UX.modal.on.show();
};
