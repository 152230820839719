(function () {
    var splitProfile = {
        //Jquery elements
        $wrapper: $('.split-request-form'),
        $splitForm: $('.split-request-form form'),
        $splitDois: $('.split-request-form__dois'),

        //JS elements
        splitSearchInput: document.getElementById('find-input'),

        init() {
            splitProfile.control();

            if (splitProfile.$wrapper.length && typeof doisSplitAjax !== 'undefined') { /*eslint-disable-line*/
                UX.loadLazyScroll.init(splitProfile.$wrapper, doisSplitAjax, splitProfile.$splitDois); /*eslint-disable-line*/
            }
        },

        control() {
            if (splitProfile.$splitForm.length) {
                splitProfile.$splitForm.on('submit', function () {
                    var $this = $(this);
                    var $emptyInpts = $this.find(':input').filter(function () {
                        return !this.value;
                    });
                    $emptyInpts.each(function () {
                        $(this).closest('.split-doi').find('.doi input').attr('disabled', 'disabled');
                    });
                    $emptyInpts.attr('disabled', 'disabled');
                    return true; // make sure that the form is still submitted
                });
            }
            this.handleBlur();
            this.handleSubmit();
        },
        callAPI(value) {
            const inputField = document.querySelector('.split-request__search .input-group__field-container');
            const labelSuccess = document.querySelector('.split-request__search .field-success-label');
            const labelFail = document.querySelector('.split-request__search .field-error-label');
            const submitBtn = document.querySelector('.split-request__search .btn');
            const errorMessage = document.querySelector('.split-request .error-submit-msg');
            //local url: '/specs/products/acm/widgets/profile-merge/templates/data/validation.json', // or validation-fail.json
            //prod url : '/pb/widgets/profileMaintenance/validateId?profileId=' + idVal + '&action='
            fetch('/pb/widgets/profileMaintenance/validateId?profileId=' + value + '&action=split', {
                method: 'GET',
            })
                .then(response => response.json())
                .then(data => {
                    inputField.classList.toggle('input-group__field-container--error', !data.exist);
                    inputField.classList.toggle('input-group__field-container--success', data.exist);
                    labelSuccess.classList.toggle('hidden', !data.exist);
                    labelFail.classList.toggle('hidden', data.exist);
                    submitBtn.removeAttribute('disabled');

                    if (data.exist && !errorMessage.classList.contains('hidden')) {
                        errorMessage.classList.toggle('hidden');
                    }
                })
                .catch(error => {
                    console.error('Error calling the API:', error);
                });
        },
        handleBlur() {
            const inputField = document.querySelector('#find-input');
            inputField.addEventListener('blur', () => {
                const inputValue = inputField.value;
                this.callAPI(inputValue);
            });
        },
        handleSubmit() {
            const splitForm = document.querySelector('.split-request form');
            const errorMessage = document.querySelector('.split-request .error-submit-msg');
            splitForm.addEventListener('submit', event => {
                const isNotValid = splitForm.querySelector('.input-group__field-container--error');
                if (isNotValid) {
                    event.preventDefault();
                    errorMessage.classList.remove('hidden');
                }
            });
        },
    };

    UX.splitProfile = splitProfile; // add to global namespace
})();
