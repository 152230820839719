(function () {
    var $body = $('body');

    var issueItem = {
        init: function () {
            issueItem.control();
        },
        control: function () {
            var windowWidth = $(window).width();

            $body.on('change', '.issue-Item__checkbox', function () {
                var NumOfCheckBoxes = $('.issue-Item__checkbox').length;
                var NumOfCheckedCheckBoxes = $('.issue-Item__checkbox:checked').length;

                if (NumOfCheckedCheckBoxes) {
                    $('.item-results__msg').hide();
                    $('.item-results__buttons')
                        .addClass('visible')
                        .css('visibility', 'visible')
                        .removeAttr('aria-hidden');
                } else {
                    $('.item-results__msg').show();
                    $('.item-results__buttons')
                        .removeClass('visible')
                        .css('visibility', 'hidden')
                        .attr('aria-hidden', 'true');
                }

                if (NumOfCheckBoxes === NumOfCheckedCheckBoxes) {
                    $('.item-results__checkbox input').prop('checked', 'true');
                } else {
                    $('.item-results__checkbox input').removeAttr('checked');
                }
            });

            $body.on('change', '.item-results__checkbox input', function () {
                if ($('.item-results__checkbox input:checked').length) {
                    $('.issue-Item__checkbox').prop('checked', 'true');
                    $('.item-results__msg').hide();
                    $('.item-results__buttons')
                        .addClass('visible')
                        .css('visibility', 'visible')
                        .removeAttr('aria-hidden');
                } else {
                    $('.issue-Item__checkbox').removeAttr('checked');
                    $('.item-results__msg').show();
                    $('.item-results__buttons')
                        .removeClass('visible')
                        .css('visibility', 'hidden')
                        .attr('aria-hidden', 'true');
                }
            });

            $body.on('change', '.section-checkbox', function () {
                if ($(this).find('input:checked').length) {
                    $(this).parent().find('.issue-Item__checkbox').prop('checked', 'true');
                    $('.item-results__msg').hide();
                    $('.item-results__buttons')
                        .addClass('visible')
                        .css('visibility', 'visible')
                        .removeAttr('aria-hidden');
                } else {
                    $(this).parent().find('.issue-Item__checkbox').removeAttr('checked');
                    if (!$('.issue-Item__checkbox:checked').length) {
                        $('.item-results__msg').show();
                        $('.item-results__buttons')
                            .removeClass('visible')
                            .css('visibility', 'hidden')
                            .attr('aria-hidden', 'true');
                    }
                }
            });

            $body.on('change', '.toc__section .accordion-tabbed__content .issue-Item__checkbox', function () {
                var $tocContainer = $(this).closest('.toc__section');
                var numberOfAllCheckboxes = $tocContainer.find(
                    '.issue-item-container .issue-Item__checkbox:checked'
                ).length;
                var numberOfChecked = $tocContainer.find('.issue-item-container .issue-Item__checkbox').length;

                if (numberOfAllCheckboxes === numberOfChecked) {
                    $tocContainer.find('.section-checkbox .issue-Item__checkbox').prop('checked', true);
                } else {
                    $tocContainer.find('.section-checkbox .issue-Item__checkbox').prop('checked', false);
                }
            });

            if (windowWidth >= UX.grid.screenSm) {
                $('.issue-item .issue-item__content').each(function () {
                    var item = $(this);
                    var itemHeight = item.innerHeight();
                    var itemRightHeight = item.find('.issue-item__content-right').innerHeight();
                    var HeightDiff = 0;
                    if (itemRightHeight < itemHeight) {
                        HeightDiff = itemHeight - itemRightHeight;
                        item.find('.issue-item__footer').css('padding-top', HeightDiff);
                    }
                });
            }
            $(document).ready(function () {
                $('a,button').removeClass('loading__link');
            });
        },
    };

    UX.issueItem = issueItem; // add to global namespace
})();
